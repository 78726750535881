import styled from 'styled-components'

export const DashboardContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#FFF9FB')};
    margin-left: 280px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
    
    
    @media screen and (max-width: 780px) {
        margin-left: 0px;
    }
    
    @media screen and (max-width: 480px) {
        margin-left: 0px;
    }
`

export const DashboardWrapper = styled.div`
    display: flex;
    z-index: 1;
    height: 90vh;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow: scroll;
`

export const DashboardHeading = styled.div`
    padding: 0.5rem 2rem;
    margin-top: 1rem;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    width: 100%;


    @media screen and (max-width: 768px) {
        padding: 0.5rem 1.5rem;
        margin-top: 0.4rem;
    }
    
    @media screen and (max-width: 480px) {
        margin-top: 0.2rem;
        padding: 0.3rem 0rem;
    }

    @media screen and (max-width: 320px) {
        margin-top: 0rem;
        padding: 0rem 0rem;
    }
`

export const DashHeading = styled.div`
    font: normal normal bold 20px/56px Lato;
    letter-spacing: 0.4px;
    color: #000000;
    opacity: 1;
    font-size: 26px;
    text-align: left;


    @media screen and (max-width: 880px) {
        font-size: 18px;
    }

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
    

`

export const FirstRow = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    // background-color: lightpink;

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
export const SecondRow = styled.div`
        height: auto;
        width: 100%;
        display: flex;  
        justify-content: space-between;
        margin-top: 1rem;

@media screen and (max-width: 1024px) {
        justify-content: center;
        flex-direction: column;
    }
    
`



export const ThreeRow = styled.div`
    height: auto;
    width: 100%;
    display: grid;
    column-gap: 50px;
    padding: 1rem 0rem;
    grid-template-columns: auto auto;
    // border-bottom: 1px solid lightgrey;

    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const TextBox = styled.div`
    height: auto;
    width: 100%;
    padding: 8px 0;
    // background-color: lightpink;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgrey;

    @media screen and (max-width: 425px) {
        text-align: center
    }
`

export const FirstBox = styled.div`
    height: auto;
    width: 60%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
        justify-content: center;
        align-items: center;
    }
    // background-color: yellow;
`


export const SecondContainer = styled.div`
    width: 359px;
    height: 263px;
    margin-top: 2rem;
    // background-color: yellow;
    border-radius: 10px;
    box-shadow: 0px 0px 20px #0000000D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 425px) {
        width: 309px;
        height: 203px;
        // background-color: lightgrey;
    }
`

export const RevenueTitle = styled.h1`
text-align: left;
font-size: 1.4rem;
letter-spacing: 0.4px;
color: #000000;
opacity: 1;

@media screen and (max-width: 1024px) {
    text-align: center;
}

@media screen and (max-width: 375px) {
    font-size: 1.3rem;
}

@media screen and (max-width: 320px) {
    font-size: 1.1rem;
}
`

export const RevenueName = styled.p`
text-align: left;
font-size: 1.1rem;
letter-spacing: 0.4px;
color: #000000;
opacity: 1;

@media screen and (max-width: 375px) {
    font-size: 0.9rem;
}
`

export const RevenueDesc = styled.p`
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
text-align: left;
letter-spacing: 0px;
font-weight: 600;
color: #000000;
font-size: 1.1rem;
opacity: 1;

@media screen and (max-width: 320px) {
    font-size: 0.9rem;
}
`

export const StartLogo = styled.div`
     height: auto;
     width: auto;
     display: flex;
     align-items: center;
`



export const Rating = styled.p`
    color: #878997;
    font-size: 1.4rem;
    margin-left: 0.6rem;
`

export const DollarLogo = styled.div`
     height: auto;
     width: auto;
     display: flex;
     justify-content: center;
     border-radius: 50%;
     align-items: center;
     background: #e6e6ff;
     padding: 3rem 2rem;
    // opacity: 0.1;

    @media screen and (max-width: 425px) {
        height: 50px;
        width: 50px;
    } 
`

export const IconLogo = styled.span`
    font-size: 3rem;
`

export const SecondTotal = styled.h1`
    font-size: 4rem;

    @media screen and (max-width: 425px) {
        font-size: 3rem;
    } 
`

export const SecondTotalDesc = styled.p`
    font-size: 1.2rem;
    color: #666666;
`



export const GraphText = styled.div`
    transform: matrix(0, -1, 1, 0, 0, 0);
    height: 20px;
    width: 140px;
    margin-top: 24rem;
    margin-left: -3rem;
    // background-color: grey;
    // position: absolute;
`

export const FilterCon = styled.div`
    display: flex;
    align-items: center; 
    width: auto;
    height: auto;
    // background-color: grey;
    @media screen and (max-width: 1024px) {
        margin-bottom: 1rem;
    }

    @media screen and (max-width: 768px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    @media screen and (max-width: 425px) {
        flex-direction: column;
        text-align: center;
    }
`

export const TextLabel = styled.label`
    font-size: 1.1rem;

    @media screen and (max-width: 1024px) {
        margin: auto;
    }

    @media screen and (max-width: 425px) {
        text-align: center;
        margin-left: 10rem;
    }

    @media screen and (max-width: 375px) {
        margin-left: 8rem;
    }

    @media screen and (max-width: 320px) {
        margin-left: 6.2rem;
    }
`

export const SubButton = styled.button`
    border: none;
    background-color: none;
    border: 1px solid lightgrey;
    padding: 0.5rem 0.8rem;
    margin-bottom: 0.5rem;
    // background-color: blue;
    border-radius: 5px;
    color: black;
    

`