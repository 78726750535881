import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

// Redux
import { connect } from "react-redux";
import * as actionTypes from "./store/actions";

import { get } from "lodash";
// import firebase
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { app, fetchToken, onMessageListener } from "./utils/firebase";

// Components
import Navbar from "./components/Navbar";
import NavbarCarrot from "./pages/NavbarCarrot/Navbar";
import NavbarHomePage from "./pages/NavbarCarrot/NavbarHomePage";

import Sidebar from "./components/Sidebar/index";

import Blogs from "./pages/NavbarCarrot/Blogs/Blogs";
import Dashboard from "./components/Dashboard/Dashboard";
// import Profile from './components/Profile/Profile';
import OfferManagement from "./components/Offer_Management/Offer_Management";
import OrderManagement from "./components/Orders_Management/Orders_Management";
import MainDashboard from "./components/MainDashboard/MainDashboard";
import SupportChat from "./components/SupportChat/SupportChat";
import Revenue from "./components/Revenue/Revenue";
import SizeMaster from "./components/SizeMaster";
import { LanguageProvider } from "./utils/language/Language";
import Slider from "./pages/HomePageSlider/Slider";
import LoginPage from "./pages/LoginPage/LoginPage";
import SignupPage from "./pages/LoginPage/SignupPage";
import OtpScreen from "./pages/LoginPage/OtpScreen";
import ForgotPassword from "./pages/LoginPage/ForgotPassword";
import ForgotPswdOtp from "./pages/LoginPage/ForgotPswdOtp";
import ResetPassword from "./pages/LoginPage/ResetPassword";
import Profile from "./pages/LoginPage/Profile";
import SubscriptionPlans from "./pages/LoginPage/SubscriptionPlans";
import ChangePassword from "./pages/LoginPage/ChangePassword";
import HowItWorks from "./pages/NavbarCarrot/HowItWorks/HowItWorks";
import Footer from "./pages/NavbarCarrot/Footer/Footer";
import ShoppingExplore from "./pages/NavbarCarrot/ShoppingExplore/ShoppingExplore";
import TopStores from "./pages/NavbarCarrot/TopStores/TopStores";
import Learning from "./pages/NavbarCarrot/Learning/Learning";
import SaySomething from "./pages/NavbarCarrot/SaySomething/SaySomething";
import Associate from "./pages/NavbarCarrot/AssociateMember/Associate";
import SidebarHaydii from "./components/SidebarHaydii/Sidebar";
import AssociateHome from "./pages/WebsitePages/AssociateMember/AssociateHome";
import AssociateMemberBenefits from "./pages/WebsitePages/AssociateMember/AssociateMemberBenefits";
import AssociateMemberForm from "./pages/WebsitePages/AssociateMember/AssociateMemberForm";
import BusinessPartnerForm from "./pages/WebsitePages/PreferredBusinessPartner/BusinessPartnerForm";
import BusinessPartnerPreferred from "./pages/WebsitePages/PreferredBusinessPartner/BusinessPartnerPreferred";
import BusinessPartnerHome from "./pages/WebsitePages/PreferredBusinessPartner/BusinessPartnerHome";
import AllStores from "./pages/WebsitePages/AllStores/AllStores";
import StoreDetailsPage from "./pages/WebsitePages/AllStores/StoreDetailsPage";
import ReferEarn from "./pages/WebsitePages/ReferEarn/ReferEarn";
import ClientsSay from "./pages/NavbarCarrot/ClientsSay/ClientsSay";
import AllBlogs from "./pages/NavbarCarrot/Blogs/AllBlogs";
import BlogDetails from "./pages/NavbarCarrot/Blogs/BlogDetails";
import AssociatePurchase from "./pages/WebsitePages/AssociateMember/AssociatePurchase";
import BusinessPurchase from "./pages/WebsitePages/AssociateMember/BusinessPurchase";
import BusinessPartnerList from "./pages/WebsitePages/AssociateMember/BusinessPartnerList";
import PaymentSuccess from "./pages/WebsitePages/AssociateMember/PaymentSuccess";
import ContactUs from "./pages/WebsitePages/PreferredBusinessPartner/ContactUs";
import FormSuccess from "./pages/WebsitePages/AssociateMember/FormSuccess";
import CategoryStores from "./pages/WebsitePages/AllStores/CategoryStores";
import TermsAndConditions from "./pages/NavbarCarrot/Content/TermsAndConditions";
import NewBusinessPartnerList from "./pages/WebsitePages/AssociateMember/NewBusinessPartnerList";
import PrivacyPolicy from "./pages/NavbarCarrot/Content/PrivacyPolicy";
import Faq from "./pages/NavbarCarrot/Content/Faq";
import AboutUs from "./pages/NavbarCarrot/Content/AboutUs";


const AllRoutes = (props) => {
  const { defaultState, setDefaultState, userData, userToken, deviceToken, setDeviceToken } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRight, setIsOpenRight] = useState(false);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  useEffect(() => {
    fetchToken(setDeviceToken);
  }, [deviceToken]);


  onMessageListener()
    .then((payload) => {
      console.log(payload);
      setNotification({
        title: get(payload, "notification.title", ""),
        body: get(payload, "notification.body", ""),
      });
      toast(<Msg title={get(payload, "notification.title", "")} body={get(payload, "notification.body", "")} toastStyle={{ backgroundColor: "#41C658" }} />, {
        style: { background: '#28C54E' },
    });
    })
    .catch((err) => console.log("failed: ", err));
  const Msg = ({ title, body }) => (
    <>
      <p style={{ color: "#000000" }}>{title}</p>
      <p style={{ color: "#000000" }}> {body} </p>
    </>
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleRight = () => {
    setIsOpenRight(!isOpen);
  };

  return (
    <Routes>

      <Route
        path="/"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <Slider />
            <HowItWorks />
            <ShoppingExplore />
            <TopStores />
            <Learning />
            <SaySomething />
            <Associate />
            <Blogs />
            <ClientsSay />
            <Footer />
          </>
        }
      />
      <Route
        path="/login"
        exact
        element={
          <>
            <NavbarCarrot toggle={toggle} />
            <LoginPage />
          </>
        }
      />
      <Route
        path="/signup"
        exact
        element={
          <>
            <NavbarCarrot toggle={toggle} />
            <SignupPage />
          </>
        }
      />
      <Route
        path="/verifyOtp/:id"
        exact
        element={
          <>
            <NavbarCarrot toggle={toggle} />
            <OtpScreen />
          </>
        }
      />
      <Route
        path="/forgot"
        exact
        element={
          <>
            <NavbarCarrot toggle={toggle} />
            <ForgotPassword />
          </>
        }
      />
      <Route
        path="/forgototp"
        exact
        element={
          <>
            <NavbarCarrot toggle={toggle} />
            <ForgotPswdOtp />
          </>
        }
      />

      <Route
        path="/resetpassword"
        exact
        element={
          <>
            <NavbarCarrot toggle={toggle} />
            <ResetPassword />
          </>
        }
      />

      <Route
        path="/change-password"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <ChangePassword />
          </>
        }
      />

      <Route
        path="/subscription"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <SubscriptionPlans />
            <Footer />
          </>
        }
      />

      <Route
        path="/profile"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <Profile />
          </>
        }
      />

      <Route
        path="/associateMember"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <AssociateHome />
            <Footer />
          </>
        }
      />

      <Route
        path="/associateBenefits"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <AssociateMemberBenefits />
            <Footer />
          </>
        }
      />

      <Route
        path="/associateMemberForm"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <AssociateMemberForm />
            <Footer />
          </>
        }
      />
      <Route
        path="/associate-payment"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <AssociatePurchase />
            <Footer />
          </>
        }
      />
      <Route
        path="/preferred-payment"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <BusinessPurchase />
            <Footer />
          </>
        }
      />
      <Route
        path="/business-partner-list"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            {/* <BusinessPartnerList/> */}
            <NewBusinessPartnerList />
            <Footer />
          </>
        }
      />
      <Route
        path="/businessPartner"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <BusinessPartnerHome />
            <Footer />
          </>
        }
      />
      <Route
        path="/contact-us"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <ContactUs />
            <Footer />
          </>
        }
      />

      <Route
        path="/businessPreferred"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <BusinessPartnerPreferred />
            <Footer />
          </>
        }
      />
      {/*<PrivateOnlyRoute 
        exact 
        path="/businessPartnerForm" 
        component={BusinessPartnerForm} 
        userData={userData} 
        userToken={userToken} 
        toggle={toggle} 
        isOpen={isOpen}
      />*/}
      {/*{auth && (
        privateRoutes.map(route =>
          <Route
            path={route.path}
            key={route.path}
            element={auth.isAuthenticated ? <route.component /> : <Navigate to={ROUTE_WELCOME_PAGE} replace />}
          />
        )
        )}*/}
      <Route
        path="/businessPartnerForm"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
                <SidebarHaydii />
                <NavbarHomePage toggle={toggle} />
                <BusinessPartnerForm />
                <Footer />   
            {/*{userData && userToken ? (
              <>
              </>
            ) : (
              <>
              <Navigate
                to="/"
                replace
              />
              </>
            )}*/}
          </>
        }
      />


      <Route
        path="/payment-success"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <PaymentSuccess />
            <Footer />
          </>
        }
      />
      <Route
        path="/form-success"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <FormSuccess />
            <Footer />
          </>
        }
      />

      <Route
        path="/allStores"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <AllStores />
            <Footer />
          </>
        }
      />

      <Route
        path="/store/:id"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <StoreDetailsPage />
            <Footer />
          </>
        }
      />
      <Route
        path="/category-store/:id"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <CategoryStores />
            <Footer />
          </>
        }
      />

      <Route
        path="/referEarn"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <ReferEarn />
            <Footer />
          </>
        }
      />
      <Route
        path="/blogs"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <AllBlogs />
            <Footer />
          </>
        }
      />
      <Route
        path="/blog/:id"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <BlogDetails />
            <Footer />
          </>
        }
      />
      <Route
        path="/terms-and-conditions"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <TermsAndConditions />
            <Footer />
          </>
        }
      />
      <Route
        path="/privacy-policy"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <PrivacyPolicy />
            <Footer />
          </>
        }
      />
      <Route
        path="/faq"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <Faq />
            <Footer />
          </>
        }
      />
      <Route
        path="/about-us"
        exact
        element={
          <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <SidebarHaydii />
            <NavbarHomePage toggle={toggle} />
            <AboutUs />
            <Footer />
          </>
        }
      />

      <Route
        path="/dashboard"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <MainDashboard />
          </>
        }
      />
      <Route
        path="/menu"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <Dashboard />
          </>
        }
      />
      <Route
        path="/size-master"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <SizeMaster />
          </>
        }
      />
      <Route
        path="/orders"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <OrderManagement />
          </>
        }
      />
      <Route
        path="/offers"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <OfferManagement />
          </>
        }
      />
      <Route
        path="/payments"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <Dashboard />
          </>
        }
      />
      <Route
        path="/revenue"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <Revenue />
          </>
        }
      />
      <Route
        path="/profile"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <Profile />
          </>
        }
      />
      <Route
        path="/chat"
        exact
        element={
          <>
            <Navbar toggle={toggle} />
            <Sidebar />
            <SupportChat />
          </>
        }
      />
    </Routes>
  );
};

function App(props) {
  const { userData, userToken, deviceToken, setDeviceToken } = props;

  return (
    <LanguageProvider>
      <AllRoutes 
        userData={userData} 
        userToken={userToken} 
        deviceToken={deviceToken} 
        setDeviceToken={setDeviceToken}
      />
      <ToastContainer theme="colored" />
    </LanguageProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    locationData: state.locations,
    defaultState: state.defaultState,
    userData: state.userData,
    deviceToken: state.deviceToken,
    userToken: state.userToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setLocations: (updatedValue) => {
      dispatch({
        type: actionTypes.GET_LOCATIONS,
        locationData: updatedValue,
      });
    },
    setDeviceToken: (updatedValue) => {
      dispatch({
        type: actionTypes.DEVICE_TOKEN,
        updateDeviceToken: updatedValue,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
