import React, { useState, useEffect, useContext } from 'react'
import { DashboardContainer, DashboardWrapper, DashboardHeading, DashHeading, HeadingButton, SvgLogo, BackIcon, MenuAndBack, PreperationTime, LabelHeading } from './DashboardElements'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from "formik";
import { Modal } from '../Modal/Modal'
import Input from "../Input";
import Select from "../Select";
import FileInput from "../FileInput";
import { uploadImage } from "../../utils/functions";
import axios from "../../axios";
import Overlay from '../Overlay'
import { toast } from "react-toastify";
import EditIcon from "../../images/edit_profile_button_table.png"
import DeleteIcon from "../../images/delete_profile_button_table.png"
import AddIcon from "../../images/addIcon.png"
import * as IoIcons from 'react-icons/io';
import { get, isEmpty } from 'lodash';
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import Switch from '../Switch/Switch';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SelectBox from "@material-ui/core/Select";



import {
    SizeValidator,
    dishValidator,
} from "../../utils/validators";

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './Autocompletecss.css'
import { LanguageContext } from "../../utils/language/Language";

const useStyles = makeStyles((theme) => ({

    textMiddle: {
        verticalAlign: 'middle !important',
        textAlign: "center"
    },
    tablePadding: {
        padding: "0.5rem",
        textAlign: "center",
        fontSize: "1rem"
    },
    paperTableHeight: {
        height: "650px",
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    "@media (max-width: 780px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        }
    },
    "@media (max-width: 480px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        }
    },
    tablePaginationStyle: {
        border: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
    },
    tableFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    }
}));




const Dashboard = ({ history, userData }) => {
    const classes = useStyles();



    const [defaultState, setDefaultState] = useState({ isAddMenu: "", isDishAdd: "" })
    const [menuData, setMenuData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const [updateMenuFormValues, setMenuFormValues] = useState({
        size: "",
    });



    useEffect(() => {
        getMenu();
    }, []);



    // For Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const getMenu = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_all_sizes");
            setMenuData(data.data);
        } catch (error) {
            console.log(error);

            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
  
    const handleMenuProfile = async (values) => {

        var fromData = {
            size: values.size,
        };
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isAddMenu: false,
            };
        });

        try {
            if (values._id) {
                const { data } = await axios.post("/restaurant/update_size", {
                    size: values.size,
                    _id: values._id,
                });
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getMenu();
            } else {
                const { data } = await axios.post("/restaurant/add_size", fromData);
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getMenu();
            }

        } catch (error) {
            setIsLoading(false);
            setDefaultState((prevState) => {
                return {
                    ...prevState,
                    isAddMenu: false,
                };
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const handleDeleteItem = async (id) => {
        if (window.confirm('Are you sure you want to delete this field ?')) {
            try {
                const { data } = await axios.post("/restaurant/remove_size", {
                    _id: id,
                });
                getMenu();
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                console.log(error);
                toast.error(`${error.response.data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            getMenu();
        }
    };

    const { dictionary } = useContext(LanguageContext);



    return (
        <>
            <div>
                <DashboardContainer>
                    <DashboardWrapper>
                        <DashboardHeading>
                            <MenuAndBack>
                                <BackIcon>
                                </BackIcon>
                                <DashHeading>
                                {dictionary.size_master_commom.Size_Master}
                                </DashHeading>
                            </MenuAndBack>
                            <HeadingButton onClick={() => {
                                    setMenuFormValues({
                                        size: "",
                                        _id: "",
                                    })
                                    setDefaultState({
                                        isAddMenu: true
                                    });
                            }}>
                                Add Size
                            </HeadingButton>
                        </DashboardHeading>
                        <Paper className={classes.paperTableHeight}>
                                <>
                                    <TableContainer className={classes.tableContainerHeight}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tablePadding}>{dictionary.size_master_commom.SNO}</TableCell>
                                                    <TableCell className={classes.tablePadding}>
                                                    {dictionary.size_master_commom.Size}
                                                    </TableCell>
                                                    <TableCell className={classes.tablePadding}>  {dictionary.size_master_commom.Actions}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {menuData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category, index) => (
                                                    <TableRow key={category._id}>
                                                        <TableCell component="th" scope="row" className={classes.textMiddle}>
                                                            {index + 1 + (page) * rowsPerPage}
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.size}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div className={classes.tableFlex}>
                                                                <SvgLogo
                                                                    style={{ padding: "0rem 0.2rem" }}
                                                                    className="logoImage"
                                                                    src={EditIcon}
                                                                    onClick={() => {
                                                                        setMenuFormValues({
                                                                            size: category.size,
                                                                            _id: category._id
                                                                        })
                                                                        setDefaultState({
                                                                            isAddMenu: true
                                                                        });
                                                                    }} />
                                                                <SvgLogo
                                                                    style={{ padding: "0rem 0.2rem" }}
                                                                    className="logoImage"
                                                                    src={DeleteIcon}
                                                                    onClick={() => {
                                                                        handleDeleteItem(category._id)
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className={classes.tablePaginationStyle}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={menuData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                        </Paper>
                    </DashboardWrapper>
                </DashboardContainer>
            </div>


            {/* Menu add */}
            <Modal
                isOpen={defaultState.isAddMenu}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isAddMenu: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isAddMenu: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div>

                        <>
                            <h2>{dictionary.size_master_commom.Add_Size}</h2>
                        </>
                    </div>
                }
                content={

                    <Formik
                        enableReinitialize
                        initialValues={updateMenuFormValues}
                        validate={SizeValidator}
                        validateOnChange
                        onSubmit={handleMenuProfile}
                    >
                        {(formikBag) => {
                            return (
                                <Form>
                                    <div className="signup-cont">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <Field name="size">
                                                    {({ field }) => (
                                                        <div className="py-2">
                                                            <Input
                                                                {...field}
                                                                type="text"
                                                                value={formikBag.values.size}
                                                                onChange={(e) => {
                                                                    console.log(formikBag)
                                                                    formikBag.setFieldValue(
                                                                        "size",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                error={
                                                                    formikBag.touched.size &&
                                                                        formikBag.errors.size
                                                                        ? formikBag.errors.size
                                                                        : null
                                                                }
                                                                className="form-control"
                                                                placeholder="Size"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <HeadingButton type="submit">
                                            {dictionary.size_master_commom.Save_Updates}
                                            </HeadingButton>
                                        </div>

                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                }
            />

            {isLoading && <Overlay />}

        </>

    )
}


const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setSidebar: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_SIDEBAR,
                updateSidebar: updatedValue,
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
