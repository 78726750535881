import React, { useState, useEffect, useContext } from 'react'
import { DashboardContainer, DashboardWrapper, DashboardHeading, RevenueTitle, FirstBox, SecondBox, RevenueName, RevenueDesc, StartLogo, FirstRow, SecondRow, Rating, SecondContainer, DollarLogo, SecondTotal, SecondTotalDesc, TextBox, ThreeRow, GraphText, FilterCon, TextLabel,IconLogo, SubButton } from './RevenueElements'
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileInput from "../FileInput";
import { uploadImage } from "../../utils/functions";
import axios from "../../axios";
import Overlay from '../Overlay'
import { get } from 'lodash';
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "./Revenue.css"
import { toast } from "react-toastify";
import CanvasJSReact from './assets/canvasjs.react';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import {
    menuValidator,
    dishValidator,
} from "../../utils/validators";
import { LanguageContext } from "../../utils/language/Language";

const useStyles = makeStyles((theme) => ({

    textMiddle: {
        verticalAlign: 'middle !important',
        textAlign: "center"
    },
    tablePadding: {
        padding: "0.5rem",
        textAlign: "center",
        fontSize: "0.8rem"
    },
    paperHeight: {
        height: "60%",
        width: "97%",
        marginLeft: "2rem",
        padding: "2rem",
        display: "flex",
        // overflow: "scroll",
        flexDirection: "column"
    },
    "@media (max-width: 1024px)": {
        paperHeight: {
            height: "65%"
        }
    },
    "@media (max-width: 768px)": {
        paperHeight: {
            marginLeft: "0.75rem",
            height: "65%"
        }
    },
    "@media (max-width: 425px)": {
        paperHeight: {
            marginLeft: "0.75rem",
            width: "95%",
            height: "80%"
        }
    },
    "@media (max-width: 375px)": {
        paperHeight: {
            marginLeft: "0.75rem",
            width: "95%",
            height: "70%"
        }
    },
    paperHeights: {
        height: "auto",
        width: "97%",
        marginLeft: "2rem",
        padding: "2rem",
        marginTop: "2rem",
        display: "flex",
        // overflow: "scroll",
        // flexDirection: "column"
    },
    "@media (max-width: 780px)": {
        paperHeights: {
            marginLeft: "0.75rem"
        }
    },
    "@media (max-width: 480px)": {
        paperHeights: {
            marginLeft: "0.75rem"
        }
    },
    tablePaginationStyle: {
        border: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
    },
    tableFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    contentHeight: {
        fontSize: "1rem",
    },
    displayFlex: {
        height: 'auto',
        width: '95%',
        marginLeft: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0px'
    },
    dropdown: {
        border: "1px solid lightgrey",
        padding: "0 10px",
        marginLeft: "20px",
        borderRadius: "5px",
        outline: "none",
        fontSize: '1rem',
        marginBottom: "15px",
        marginTop: "0px",
        backgroundColor: "white",
        color: "black"

    },
    formDrop: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));




const Revenue = ({ history, userData }) => {
    const classes = useStyles();
    const { dictionary } = useContext(LanguageContext);

    const [isLoading, setIsLoading] = useState(false);


    const [revenueData, setRevenueData] = useState([]);
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    console.log(moment(startDate).format('L'));
    console.log(moment(endDate).format('L'));

    const SDate = moment(startDate).format('L');
    const EDate = moment(endDate).format('L');

    const getRevenue = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_revenue");
            // const { data } = await axios.get(`/restaurant/get_revenue?dateObject={"from":"1/2/20","to":"${moment(endDate).format('L')}"}`);                
            setRevenueData(data.data);
            console.log(data.data)
        } catch (error) {
            console.log(error);
        }
    };

 


    const [selected, setSelected] = useState("");


    function handleChanges(event) {
        setSelected(event.target.value);
    }

    console.log(selected);

    let dates = new Date();

    console.log(dates)

    let convetDate = moment(dates).utc().format('MM/DD/YYYY')

    let dailys = dates.setDate(dates.getDate() - 2);
    let converToDay = moment(dailys).utc().format('MM/DD/YYYY');

    let makeDate = dates.setMonth(dates.getMonth() - 1);
    let converToMonth = moment(makeDate).utc().format('MM/DD/YYYY');

    let makeYear = dates.setYear(dates.getFullYear() - 1);
    let converToYear = moment(makeYear).utc().format('MM/DD/YYYY');

    console.log(convetDate)
    console.log(converToDay)
    console.log(converToMonth)
    console.log(converToYear)

    const daily = async () => {
        const { data } = await axios.get(`/restaurant/get_revenue?dateObject={"from" : "${converToDay}" , "to":"${convetDate}"}`);
        setRevenueData(data.data);
        console.log(data.data)
        getRevenue();
    }
    const monthly = async () => {
        const { data } = await axios.get(`/restaurant/get_revenue?dateObject={"from" : "${converToMonth}" , "to":"${convetDate}"}`);
        setRevenueData(data.data);
        console.log(data.data)
        getRevenue();
    }
    const yearly = async () => {
        const { data } = await axios.get(`/restaurant/get_revenue?dateObject={"from" : "${converToYear}" , "to":"${convetDate}"}`);
        setRevenueData(data.data);
        console.log(data.data)
        getRevenue();
    }


    useEffect(() => {
        getRevenue()
    }, [])



    



    // Graph

    const data = [
        {
            name: "Jan",
            uv: 40,
            pv: 24,
            // amt: 2400
        },
        {
            name: "Feb",
            uv: 30,
            pv: 13,
            // amt: 2210
        },
        {
            name: "Mar",
            uv: 20,
            pv: 98,
            // amt: 2290
        },
        {
            name: "Apr",
            uv: 27,
            pv: 39,
            // amt: 2000
        },
        {
            name: "May",
            uv: 18,
            pv: 48,
            // amt: 2181
        },
        {
            name: "Jun",
            uv: 23,
            pv: 38,
            // amt: 2500
        },
        {
            name: "July",
            uv: 34,
            pv: 43,
            // amt: 2100
        },
        {
            name: "Aug",
            uv: 34,
            pv: 43,
            // amt: 2100
        },
        {
            name: "Sep",
            uv: 34,
            pv: 43,
            // amt: 2100
        },
        {
            name: "Oct",
            uv: 34,
            pv: 43,
            // amt: 2100
        },
        {
            name: "Nov",
            uv: 34,
            pv: 43,
            // amt: 2100
        },
        {
            name: "Dec",
            uv: 34,
            pv: 43,
            // amt: 2100
        },

    ];


    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    const { graphData } = revenueData;

    const options = {
        animationEnabled: true,
        title: {
            // text: "Monthly Sales - 2017"
        },
        axisX: {
            valueFormatString: "MMM"
        },
        axisY: {
            title: dictionary.revenue.Total_Revenue,
            prefix: "$",
            includeZero: false
        },
        data: [{
            yValueFormatString: "$#,###",
            xValueFormatString: "MMMM",
            type: "spline",
            dataPoints: [
                // { x: new Date(2022, 0), y: revenueData.graphData[0].total_sale },
                { x: new Date(2022, 0), y: get(revenueData, "graphData[0].total_sale", "")},
                { x: new Date(2022, 1), y: get(revenueData, "graphData[1].total_sale", "") },
                { x: new Date(2022, 2), y: get(revenueData, "graphData[2].total_sale", "") },
                { x: new Date(2022, 3), y: get(revenueData, "graphData[3].total_sale", "") },
                { x: new Date(2022, 4), y: get(revenueData, "graphData[4].total_sale", "") },
                { x: new Date(2022, 5), y: get(revenueData, "graphData[5].total_sale", "") },
                { x: new Date(2022, 6), y: get(revenueData, "graphData[6].total_sale", "") },
                { x: new Date(2022, 7), y: get(revenueData, "graphData[7].total_sale", "") },
                { x: new Date(2022, 8), y: get(revenueData, "graphData[8].total_sale", "") },
                { x: new Date(2022, 9), y: get(revenueData, "graphData[9].total_sale", "") },
                { x: new Date(2022, 10), y: get(revenueData, "graphData[10].total_sale", "") },
                { x: new Date(2022, 11), y: get(revenueData, "graphData[11].total_sale", "") }
            ]
        }]
    }



    console.log(revenueData.graphData);

    

    console.log(graphData);


    const SubmitDate = async () => {
        try {
            // const { data } = await axios.get("/restaurant/get_revenue");
            const { data } = await axios.get(`/restaurant/get_revenue?dateObject={"from":"1/2/20","to":"${moment(endDate).format('L')}"}`);                
            setRevenueData(data.data);
            console.log(data.data)
            getRevenue();
        } catch (error) {
            console.log(error);
            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }




    return (
        <>
            <div>
                <DashboardContainer>
                    <DashboardWrapper>
                        <DashboardHeading>
                        </DashboardHeading>
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", overflow: "flow" }}>
                            <Paper className={classes.paperHeight}>
                                <FirstRow>
                                    <FirstBox>
                                        <RevenueTitle>
                                        {dictionary.revenue.Revenue_Growth}
                                        </RevenueTitle>
                                        <RevenueName style={{ marginTop: '0.5rem' }}>
                                        {dictionary.revenue.Restaurant_Name}
                                        </RevenueName>
                                        <RevenueDesc style={{ marginBottom: '1.5rem' }}>
                                            {"restaurant_name"}
                                        </RevenueDesc>
                                        <RevenueName>
                                        {dictionary.revenue.Restaurant_Location}
                                        </RevenueName>
                                        <RevenueDesc>
                                        {"restaurant_location"}
                                        </RevenueDesc>
                                        <StartLogo style={{ marginTop: '1.1rem' }}>
                                            <i className="ph-star-fill" style={{ color: '#ED8A19', fontSize: '2rem' }}></i> <Rating>{revenueData.rating}</Rating>
                                        </StartLogo>
                                    </FirstBox>
                                    <SecondContainer>
                                        <DollarLogo>
                                        <IconLogo>₺{revenueData.total_revenue}</IconLogo>
                                        </DollarLogo>
                                        <SecondTotal></SecondTotal>
                                        <SecondTotalDesc>{dictionary.revenue.Total_Revenue_Till_Now}</SecondTotalDesc>
                                    </SecondContainer>
                                </FirstRow>
                                <SecondRow>
                                    <RevenueTitle>
                                    {dictionary.revenue.Performance_Status}
                                    </RevenueTitle>
                                    <FilterCon >
                                        <TextLabel> {dictionary.revenue.From}</TextLabel>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => {
                                                setStartDate(date);
                                                // getRevenue();
                                            }}
                                            selectsStart
                                            maxDate={new Date()}
                                            startDate={startDate}
                                            endDate={endDate}
                                            className="dateInput"
                                            placeholderText="Start Date"
                                        />
                                        <TextLabel> {dictionary.revenue.To}</TextLabel>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => {
                                                setEndDate(date);
                                                // getRevenue();
                                            }}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            maxDate={new Date()}
                                            className="dateInput"
                                            placeholderText="End Date"
                                        />

                                        <SubButton 
                                        onClick={() => {
                                            SubmitDate();
                                        }}
                                        >Submit</SubButton>
                                    </FilterCon>
                                    <FormControl className={classes.formDrop}>
                                        <Select
                                            displayEmpty
                                            value={selected}
                                            onChange={handleChanges}
                                            className={classes.dropdown}>
                                            <MenuItem value="" disabled>{dictionary.revenue.Sort}</MenuItem>
                                            <MenuItem value="Daily" onClick={daily}>{dictionary.revenue.Daily}</MenuItem>
                                            <MenuItem value="Monthly" onClick={monthly}>{dictionary.revenue.Monthly}</MenuItem>
                                            <MenuItem value="Yearly" onClick={yearly}>{dictionary.revenue.Yearly}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </SecondRow>
                                <ThreeRow>
                                    <TextBox>
                                        <p style={{ color: "#666666" }}>{dictionary.revenue.Total_Orders}</p>
                                        <p>{revenueData.total_order}</p>
                                    </TextBox>
                                    <TextBox>
                                        <p style={{ color: "#666666" }}>{dictionary.revenue.Total_Completed_Orders}</p>
                                        <p>{revenueData.delivered_order}</p>
                                    </TextBox>
                                    <TextBox>
                                        <p style={{ color: "#666666" }}>{dictionary.revenue.Total_Cancelled_Orders}</p>
                                        <p>{revenueData.cancelled_order}</p>
                                    </TextBox>
                                    <TextBox>
                                        <p style={{ color: "#666666" }}>{dictionary.revenue.Total_Reviews}</p>
                                        <p>{revenueData.total_review}</p>
                                    </TextBox>
                                    <TextBox style={{ border: "none" }}>
                                        <p style={{ color: "#666666" }}>{dictionary.revenue.Positive_Reviews}</p>
                                        <p>{revenueData.positive_review}</p>
                                    </TextBox>
                                    <TextBox style={{ border: "none" }}>
                                        <p style={{ color: "#666666" }}>{dictionary.revenue.Negative_Reviews}</p>
                                        <p>{revenueData.negetive_review}</p>
                                    </TextBox>
                                </ThreeRow>
                            </Paper>
                            <Paper className={classes.paperHeights}>
                                <CanvasJSChart options={options}
                                /* onRef={ref => this.chart = ref} */
                                />
                            </Paper>
                        </div>
                    </DashboardWrapper>
                </DashboardContainer>
            </div>
            {isLoading && <Overlay />}

        </>

    )
}


const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setSidebar: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_SIDEBAR,
                updateSidebar: updatedValue,
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Revenue);
