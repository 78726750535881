import React, { useState, useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, LoginTitle, LoginCenter, LoginAnchor, SvgLogo, NavIcon, SidebarMobileIcon } from './NavbarElements'
import logoLogin from '../../images/logo.svg'
import logoWlogin from '../../images/haydiilogo.png'
import 'react-phone-input-2/lib/style.css'
import Overlay from '../Overlay'
import SidebarOverlay from '../SidebarOverlay'
import Menu from '../Menu';
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import * as GrIcons from 'react-icons/gr';
import Switch from '../Switch/Switch';
import { get } from "lodash";
import axios from "../../axios";
import { toast } from "react-toastify";

import lgIcon from "../../images/languageWhite.png"
import notificationIcon from "../../images/notificationWhite.png"
import * as FaIcons from 'react-icons/fa';
import { LanguageContext } from "../../utils/language/Language";
import * as RiIcons from 'react-icons/ri';
import { loginObjOne, signUpObjOne, forgotObjOne, resetObjOne, restaurantDetailsObjOne, bankDetailsObjOne, verifyOtpObjOne, pendingApprovalObjOne } from '../LoginSection/Data'

const Navbar = (props) => {

  const {
    userData,
    toggle,
    history,
    sidebar,
    setSidebar,
    setUsers
  } = props;

    const navigate = useNavigate();

  // ******Localization****
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const handleLanguageChange = (value) => userLanguageChange(value);
  const defaultLanguage = window.localStorage.getItem("rcml-lang") || "en";
  const { dictionary } = useContext(LanguageContext);
   

  const [scrollNav, setScrollNav] = useState(false)
  const [bgLogin, setBgLogin] = useState(false);
  const [logoColor, setLogoColor] = useState(logoWlogin);
  const [defaultState, setDefaultState] = useState({ isLogin: '', isSignup: '', isForget: '', isOtp: '', isReset: '', isProfileUpdate: "", isApproved: "", isRejected: "", isProfileComplete: "" })
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSidebarBackground, setIsLoadingSidebarBackground] = useState(false);
  const [isToggled, setIsToggled] = useState(get(userData, 'restaurant_on', false))

  const showSidebar = () => setSidebar(!sidebar)
  const showBackOverlay = () => setIsLoadingSidebarBackground(!isLoadingSidebarBackground)

 console.log(userData)

  const handleConfirm = (async) => {
    // let modal = { ...modalData };
    if (window.confirm('Are you sure you want to Logout?')) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    // setUsers("");
    navigate('/webPanel');
    setDefaultState(loginObjOne)
    navigate(0);
    }
    else{
      
    }
    // setDailogType("");
    // modal.type = "success";

    // setUsers(null);
    // modal.isOpen = false;
    // setModalData(modal);
  };


  const handleRestaurantOnOff = async (values) => {
    console.log(isToggled)
    let formvalues = {
      restaurant_on: isToggled == false ? 1 : 0
    }

    // console.log(formvalues);
    try {
      const { data } = await axios.post("/restaurant/restaurant_on_off", formvalues);
      toast.success(`${data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      localStorage.setItem("accessToken", data.data.accessToken || data.data.access_token);
      localStorage.setItem("userDataCarrot", JSON.stringify(data.data));
      setUsers(data.data);
      console.log(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      toast.error(`${error.response.data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }



  // console.log(isToggled)


  const loginBox = (
    <>
      {!userData ? (
        <>

          <NavBtn>
            <NavBtnLink
              to="/webPanel"
              primary="true"
              onClick={() => {
                setDefaultState({
                  isSignup: false,
                  isLogin: true,
                });
              }}
            >
              Login
            </NavBtnLink>

            <NavBtnLink
              to="/webPanel"
              onClick={() => {
                setDefaultState({
                  isSignup: true,
                  isLogin: false,
                });
              }}
            >Create Account</NavBtnLink>
          </NavBtn>
        </>
      ) : (
        <>
          {(userData.is_profile_completed == true && userData.is_approved_by_admin == "1") ? (
            <>
              <Menu userIcon={logoLogin} name="qwerfd">
                <div className="user_content">
                  <ul>
                    <li>
                      <NavLink to="/profile-details">
                        my_account
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                      </NavLink>
                    </li>
                    <li>
                      <a href="javascript:void()"
                      // onClick={handleLogout}
                      >
                        logout
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Menu>
            </>
          ) : (
            <>
              <NavBtn>
                <NavBtnLink
                  to="/restaurant"
                  primary="true"
                  onClick={() => {
                    if (userData.is_profile_completed == false) {
                      setDefaultState({
                        isSignup: false,
                        isLogin: false,
                        isProfileComplete: true
                      });
                    } else {
                      if (userData.is_approved_by_admin == "0") {
                        setDefaultState({
                          isSignup: false,
                          isLogin: false,
                          isApproved: true
                        });
                      } else if (userData.is_approved_by_admin == "2") {
                        setDefaultState({
                          isSignup: false,
                          isLogin: false,
                          isRejected: true
                        });
                      }
                    }
                  }
                  }
                >
                  Check Status
                </NavBtnLink>
                <NavBtnLink
                // onClick={handleLogout}
                >Logout</NavBtnLink>
              </NavBtn>
            </>
          )}
        </>
      )}
    </>
  );


  const leftBox = (
    <>
      <NavMenu>

        <NavItem onClick={() => handleLanguageChange("en")}>
          <NavLinks
            to="works"
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            activeClass="active"
          >
            <RiIcons.RiEnglishInput style={{ fontSize: "2em" }} />
          </NavLinks>
          
        </NavItem>
        <NavItem onClick={() => handleLanguageChange("ar")}>
          <NavLinks
            to="works"
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            activeClass="active"
          >
            <SvgLogo className="logoImage" src={lgIcon} />
          </NavLinks>
          
        </NavItem>
        <NavItem>
          <NavLinks to="">
            <SvgLogo className="logoImage" src={notificationIcon} />
          </NavLinks>
        </NavItem>
        <NavItem>
          <NavLinks to="">
            <RiIcons.RiShutDownLine style={{ fontSize: "2em" }} onClick={handleConfirm} />
            {/*<SvgLogo className="logoImage" src={notificationIcon} onClick={handleConfirm} />*/}
          </NavLinks>
        </NavItem>
      </NavMenu>
    </>
  );

  const middleBox = (
    <>
      <NavMenu>

        <NavItem>
          <NavLinks
          >
            {dictionary.navbar.Currently_Not_Taking_Orders}
          </NavLinks>
        </NavItem>
        <NavItem style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Switch
            rounded={true}
            isToggled={isToggled}
            bgColorChecked="#ffffff"
            roundColorChecked="#A91F6E"
            border="1px solid #ffffff"
            bgColorUnChecked="#A91F6E"
            roundColorUnChecked="#ffffff"
            onToggle={() => {
              handleRestaurantOnOff()
              setIsToggled(!isToggled)
            }}
          />
          {/*<NavLinks to="" style={{width: "70px"}}>
            <input checked={false} className="switchbox" type="checkbox" id={"item"} />
              <label htmlFor={"item"}></label>

  </NavLinks>*/}
        </NavItem>
        <NavItem>
          <NavLinks to="">
          {dictionary.navbar.ON_Taking_Orders}
          </NavLinks>
        </NavItem>
      </NavMenu>
    </>
  );

  return (
    <>
      <IconContext.Provider value={{ color: "#ffffff" }}>
        <Nav scrollNav={scrollNav} bgLogin={bgLogin}>
          <NavbarContainer>
            <NavLogo to="/webPanel/dashboard">

              <SvgLogo className="logoImage" src={logoColor} />
              {/*<img className="logoImage" src={logo} alt="logo" />*/}
              <SidebarMobileIcon>
                <NavIcon to="#">
                  <FaIcons.FaBars
                    onClick={() => {
                      showBackOverlay();
                      showSidebar();
                    }
                    }
                  />
                </NavIcon>
              </SidebarMobileIcon>
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            {middleBox}
            {leftBox}

          </NavbarContainer>
        </Nav>


      </IconContext.Provider>
      {isLoading && <Overlay />}
      {!sidebar ? <SidebarOverlay /> : ""}

    </>

  )
}




const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
    sidebar: state.sidebar,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
    setSidebar: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_SIDEBAR,
        updateSidebar: updatedValue,
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
