import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { NavLink, useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from "formik";
import axios from "../../axios";
import get from "lodash/get";
import Lottie from 'react-lottie';
import Input from '../../components/Input';
import CarrotInput from '../../components/CarrotInput';
import { toast } from "react-toastify";

import ChairGuySvg from "../../images/CarrotSvg/ChairGuy.svg"
import LeafPlantlottie from "../../images/CarrotJSON/planta moviéndose.json";
import defaultOptionsThinkingGuyLottie from "../../images/CarrotJSON/16766-forget-password-animation.json";
import GreenBottomImage from "../../images/CarrotImages/GreenBottom.png";
import LottieCarrot from "../../images/CarrotJSON/carrot.json";
import DotCarrotSection from "../../images/CarrotSvg/DotCarrotSection.svg";
import DotCarrotInside from "../../images/CarrotSvg/DotCarrotInside.svg";

import { HeroContainer, HeroBg, ImgBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, NavBtnLink, ChairSvg, SignWelcome,
    WelcomeDesign,
    TrapeziumContent,
    TrapeziumLeft,
    TrapeziumRight,
    SignInSection,
    WelcomeRow,
    ButtonInside,
    EmailPswdSection,
    LabelInput,
    InputLabel,
    // CarrotInput,
    RightSectionInsideDot,
    RightSectionDotOutBig,
    LeftSectionUpperDot,
    LeftSectionOutsideDot,
    RightSectionOutsideDot,
    LeftSectionDot,
    ForgotRow,
    CarrotButtonRow,
    CarrotButton,
    DontHaveRow,
    ChairSvgSection,
    LottieGreenForgot,
    LottieManThink,
    ChairGuy,
    ChairBottom,
    DontHave,
    SignupText,
    WelcomeSection } from './LoginElements';
import Overlay from '../../components/Overlay';
import { forgetValidator } from '../../utils/validators';
var CryptoJS = require("crypto-js");




const HeroSection = ({ defaultState, setDefaultState }) => {
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    const sendOTP = async (values) => {
        console.log(values);
        setIsLoading(true);
        try {
            const { data } = await axios.post("/user/sendOtp", {
                email: values.email
            });
    
            toast.success(`${data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            // Encrypt
            var ciphertext = CryptoJS.AES.encrypt(values.email, 'secret_key_123').toString();
            console.log(ciphertext);

            navigate(`/forgototp?id=${ciphertext}`);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.data?.errors) {
                toast.error(`${error.response.data.errors[0].msg}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(`${error?.response?.data?.message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };


    const defaultOptionsCarrot = {
        loop: true,
        autoplay: true,
        animationData: LeafPlantlottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };
    const defaultOptionsThinkingGuy = {
        loop: true,
        autoplay: true,
        animationData: defaultOptionsThinkingGuyLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };
    

    return (
        <>
            <HeroContainer>
                <HeroBg>
                    <ImgBg src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                </HeroBg>
                <HeroContent className="container">
                    <SignWelcome>
                        <SignInSection>
                            <HeroH1>
                                <div style={{color: "#101011", fontFamily: "Titan One"}}>Forgot</div>&nbsp;<div style={{color: "#28C54E", fontFamily: "Titan One"}}>Password</div>
                            </HeroH1>
                        </SignInSection>
                        <WelcomeSection>
                            <WelcomeDesign>
                                <TrapeziumLeft>
                                    <LeftSectionDot src={DotCarrotSection}/>
                                    <LeftSectionOutsideDot src={DotCarrotInside}/>
                                    <LeftSectionUpperDot src={DotCarrotInside}/>
                                </TrapeziumLeft>
                                <TrapeziumRight>
                                    <RightSectionInsideDot src={DotCarrotInside}/>
                                    <RightSectionDotOutBig src={DotCarrotSection}/>
                                    <RightSectionOutsideDot src={DotCarrotInside}/>
                                </TrapeziumRight>
                                <TrapeziumContent>
                                    <WelcomeRow>
                                        We will send you mail to verify
                                    </WelcomeRow>
                                    <EmailPswdSection style={{justifyContent: "space-around"}}>
                                        <Formik
                                            enableReinitialize
                                            initialValues={{
                                                email: ""
                                            }}
                                            validate={(values) => forgetValidator(values)}
                                            validateOnChange
                                            onSubmit={(values) => sendOTP(values)}
                                        >
                                            {(formikBag) => {
                                                return (
                                                    <Form className="formStyle">
                                                        <Field name="phone">
                                                            {({ field }) => (
                                                                <LabelInput>
                                                                    <InputLabel>
                                                                        Email Address
                                                                    </InputLabel>
                                                                    <CarrotInput
                                                                        type="text"
                                                                        placeholder="Enter your Email Address"
                                                                        value={formikBag.values.email}
                                                                        onChange={(e) => {
                                                                            formikBag.setFieldValue(
                                                                                "email",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        error={
                                                                            formikBag.touched.email &&
                                                                                formikBag.errors.email
                                                                                ? formikBag.errors.email
                                                                                : null
                                                                        }
                                                                    />
                                                                </LabelInput>
                                                            )}
                                                        </Field>
                                                        
                                                        <CarrotButtonRow style={{padding: "2rem 0rem"}}>
                                                            <CarrotButton type="submit">
                                                                <ButtonInside>
                                                                    Done
                                                                </ButtonInside>
                                                            </CarrotButton>
                                                        </CarrotButtonRow>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                        
                                    </EmailPswdSection>
                                </TrapeziumContent>
                            </WelcomeDesign>
                        </WelcomeSection>
                    </SignWelcome>
                    <HeroBtnWrapper>
                        
                    </HeroBtnWrapper>
                </HeroContent>
                <ChairSvgSection>
                    <LottieGreenForgot>
                        <Lottie
                            options={defaultOptionsCarrot}
                            height={"100%"}
                            width={"100%"}
                        />
                    </LottieGreenForgot>
                    <LottieManThink>
                        <Lottie
                            options={defaultOptionsThinkingGuy}
                            height={"100%"}
                            width={"100%"}
                        />
                    </LottieManThink>
                    {/*<ChairBottom src={GreenBottomImage}/>*/}
                        
                </ChairSvgSection>
            </HeroContainer>
            {isLoading && <Overlay />}
        </>

    )
}




const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
