import React, { useState, useEffect, useContext } from 'react'
import { DashboardContainer, DashboardWrapper, DashboardHeading, DashHeading, HeadingButton, SvgLogo, BackIcon, MenuAndBack, PreperationTime, LabelHeading, DashCard, DashContent, DashContainerCard, DashContentCard, DashContainerCardIcon, DashContainerCardContent, DashContainerCardContentTotal, DashContainerCardContentNumber, DashContainerCardContentOrder, DashIcon, DashRow, DashBox, FilterCon, TextLabel, SubButton } from './MainDashboardElements'
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileInput from "../FileInput";
import { uploadImage } from "../../utils/functions";
import axios from "../../axios";
import Overlay from '../Overlay'
import { get } from 'lodash';
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import moment from 'moment';

import DatePicker from "react-datepicker";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";


// Dashboard Icon

import NewOrdersIcon from '../../images/HaydeDashboardIcon/pallet.png'
import ScheduledOrdersIcon from '../../images/HaydeDashboardIcon/876754.png'
import DishIcon from '../../images/HaydeDashboardIcon/3144839.png'
import SettledClaimsIcon from '../../images/HaydeDashboardIcon/download.png'
import CompletedIcon from '../../images/HaydeDashboardIcon/product.png'
import CancelledIcon from '../../images/HaydeDashboardIcon/Group100709.png'
import ProductActiveIcon from '../../images/HaydeDashboardIcon/Group100709.png'
import ProductReadyIcon from '../../images/HaydeDashboardIcon/truck-light1.png'
import ProductInactiveIcon from '../../images/HaydeDashboardIcon/fail.png'
import OngoingIcon from '../../images/HaydeDashboardIcon/truck-light1.png'
import ActiveNumberIcon from '../../images/HaydeDashboardIcon/Group101774.png'
import ClosedNumberIcon from '../../images/HaydeDashboardIcon/Group101776.png'
import { LanguageContext } from "../../utils/language/Language";

import {
    menuValidator,
    dishValidator,
} from "../../utils/validators";

const useStyles = makeStyles((theme) => ({

    textMiddle: {
        verticalAlign: 'middle !important',
        textAlign: "center"
    },
    tablePadding: {
        padding: "0.5rem",
        textAlign: "center",
        fontSize: "0.8rem"
    },
    // paperHeight: {
    //     height: "auto",
    //     width: "auto",
    //     marginLeft: "2rem",
    //     padding: "10px 15px",
    //     marginBottom: '30px'
    // },
    "@media (max-width: 780px)": {
        paperHeight: {
            marginLeft: "0.75rem"
        }
    },
    "@media (max-width: 480px)": {
        paperHeight: {
            marginLeft: "0.75rem"
        }
    },
    tablePaginationStyle: {
        border: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
    },
    tableFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    contentHeight: {
        fontSize: "1rem",
    },
    displayFlex: {
        height: 'auto',
        width: '95%',
        marginLeft: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0px'
    },
    dropdown: {
        border: "1px solid lightgrey",
        padding: "0 10px",
        marginLeft: "10px",
        borderRadius: "5px",
        outline: "none",
        fontSize: '1rem',
        marginBottom: "15px",
        marginTop: "0px",
        backgroundColor: "white",
        color: "black"

    },
    formDrop: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));




const MainDashboard = ({ history, userData }) => {
    const classes = useStyles();

    const { dictionary } = useContext(LanguageContext);

    const [isLoading, setIsLoading] = useState(false);

    const [tableData, setTableData] = useState([]);

    const [selected, setSelected] = useState("");



    function handleChanges(event) {
        setSelected(event.target.value);
    }

    console.log(selected);

    let dates = new Date();

    console.log(dates)

    let convetDate = moment(dates).utc().format('MM/DD/YYYY')

    let dailys = dates.setDate(dates.getDate() - 2);
    let converToDay = moment(dailys).utc().format('MM/DD/YYYY');

    let makeDate = dates.setMonth(dates.getMonth() - 1);
    let converToMonth = moment(makeDate).utc().format('MM/DD/YYYY');

    let makeYear = dates.setYear(dates.getFullYear() - 1);
    let converToYear = moment(makeYear).utc().format('MM/DD/YYYY');

    console.log(convetDate)
    console.log(converToDay)
    console.log(converToMonth)
    console.log(converToYear)

    const daily = async () => {
        const { data } = await axios.get(`/restaurant/get_Dashboard?dateObject={"from" : "${converToDay}" , "to":"${convetDate}"}`);
        setTableData(data.data);
        console.log(data.data)
        // getCategory();
    }
    const monthly = async () => {
        const { data } = await axios.get(`/restaurant/get_Dashboard?dateObject={"from" : "${converToMonth}" , "to":"${convetDate}"}`);
        setTableData(data.data);
        console.log(data.data)
        // getCategory();
    }
    const yearly = async () => {
        const { data } = await axios.get(`/restaurant/get_Dashboard?dateObject={"from" : "${converToYear}" , "to":"${convetDate}"}`);
        setTableData(data.data);
        console.log(data.data)
        // getCategory();
    }

    const reports = [
        { icon: DishIcon, title: dictionary.dashboard.Active_Dish, value: tableData.activeDish, color: "#1B950E" },
        { icon: ActiveNumberIcon, title: dictionary.dashboard.Active_Offer, value: tableData.activeOffer, color: "#00999E" },
        { icon: ProductInactiveIcon, title: dictionary.dashboard.Cancel_Order, value: tableData.cancelOrder, color: "#EC2027" },
        { icon: CompletedIcon, title: dictionary.dashboard.Completed_Order, value: tableData.completedOrder, color: "#F82063" },
        { icon: DishIcon, title: dictionary.dashboard.Inactive_Dish, value: tableData.inActiveDish, color: "#EC2027" },
        { icon: ClosedNumberIcon, title:  dictionary.dashboard.Inactive_Offer, value: tableData.inActiveOffer, color: "#EC2027" },
        { icon: OngoingIcon, title: dictionary.dashboard.Ongoing_Order, value: tableData.ongoingOrder, color: "#0E388E" },
        { icon: DishIcon, title: dictionary.dashboard.Total_Order, value: tableData.totalOrder, color: "#00999E" },
        { icon: SettledClaimsIcon, title: dictionary.dashboard.Total_Revenue, value: tableData.totalRevenue, color: "#00999E" },
    ]

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    console.log(moment(startDate).utc().format('MM/DD/YYYY'));
    console.log(moment(endDate).utc().format('MM/DD/YYYY'));


    const getCategory = async () => {
        try {
            if (startDate && endDate) {
                const { data } = await axios.get(`/restaurant/get_Dashboard?dateObject={"from":"${moment(startDate).utc().format('MM/DD/YYYY')}","to":"${moment(endDate).utc().format('MM/DD/YYYY')}"}`);
                setTableData(data.data);
                console.log(data.data)
            }
            else {
                const { data } = await axios.get("/restaurant/get_Dashboard");
                setTableData(data.data);
                console.log(data.data)
            }

        } catch (error) {
            console.log(error);
        }
    };


    const SubmitDate = async () => {
        try {
            // const { data } = await axios.get("/restaurant/get_revenue");
            const { data } = await axios.get(`/restaurant/get_Dashboard?dateObject={"from":"1/2/20","to":"${moment(endDate).utc().format('MM/DD/YYYY')}"}`);                
            setTableData(data.data);
            console.log(data.data)
            getCategory();
        } catch (error) {
            console.log(error);
        }
    }





    useEffect(() => {
      
        getCategory();
        // const { history } = 
    }, []);




    return (
        <>
            <div>
                <DashboardContainer>
                    <DashboardWrapper>
                        <DashboardHeading>
                        </DashboardHeading>
                        <div className={classes.displayFlex}>
                            <DashCard style={{ width: '400px' }}><i className="fa fa-bell-o" style={{ fontSize: '20px', color: '#7D7D7D' }}></i>{dictionary.dashboard.NEW_LIVE_ORDERS_HAS_ARRIVED}<i className="ph-caret-circle-right" style={{ fontSize: '33px', color: '#E32279' }}></i></DashCard>
                            {/* <DashCard style={{ width: '350px', backgroundColor: '#EC2027' }}><p style={{ color: '#FFFFFF', fontSize: '25px' }}>00 | 16</p><p style={{ color: '#FFFFFF', fontSize: '25px' }}>TARGET</p></DashCard> */}
                        </div>
                        <div className={classes.displayFlex}>
                            {/* <DashCard style={{ width: '200px' }}>Today <i className="ph-caret-down" style={{ fontSize: '20px', color: '#7D7D7D' }}></i></DashCard> */}
                            <FormControl className={classes.formDrop}>
                                <Select
                                    displayEmpty
                                    value={selected}
                                    onChange={handleChanges}
                                    className={classes.dropdown}>
                                    <MenuItem value="" disabled>{dictionary.dashboard.Sort_by}</MenuItem>
                                    <MenuItem value="Daily" onClick={daily}>{dictionary.dashboard.Daily}</MenuItem>
                                    <MenuItem value="Monthly" onClick={monthly}>{dictionary.dashboard.Monthly}</MenuItem>
                                    <MenuItem value="Yearly" onClick={yearly}>{dictionary.dashboard.Yearly}</MenuItem>
                                </Select>
                            </FormControl>
                            <FilterCon >
                                <TextLabel>{dictionary.dashboard.From}</TextLabel>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        // getRevenue();
                                    }}
                                    selectsStart
                                    maxDate={new Date()}
                                    startDate={startDate}
                                    endDate={endDate}
                                    className="dateInput"
                                    placeholderText="Start Date"
                                />
                                <TextLabel>{dictionary.dashboard.To}</TextLabel>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {
                                        setEndDate(date);
                                        // getRevenue();
                                    }}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    className="dateInput"
                                    placeholderText="End Date"
                                />

                                <SubButton onClick={SubmitDate}>{dictionary.dashboard.Submit}</SubButton>
                            </FilterCon>
                            {/* <DashCard style={{ width: '350px', backgroundColor: '#EC2027' }}><p style={{ color: '#FFFFFF', fontSize: '25px' }}>00 | 16</p><p style={{ color: '#FFFFFF', fontSize: '25px' }}>TARGET</p></DashCard> */}
                        </div>
                        <DashBox>
                            <DashRow>
                                <DashContainerCard>
                                    {
                                        reports.map((val, key) => {
                                            return (
                                                <DashContentCard ind={key}>
                                                    {/* <DashContainerCardIcon><i className="ph-record" style={{ fontSize: '76px' }}></i></DashContainerCardIcon> */}
                                                    <DashContainerCardIcon style={{ backgroundColor: val.color }}><DashIcon src={val.icon} /></DashContainerCardIcon>
                                                    <DashContainerCardContent>
                                                        <DashContainerCardContentTotal>{dictionary.dashboard.Total}</DashContainerCardContentTotal>
                                                        <DashContainerCardContentNumber>{val.value}</DashContainerCardContentNumber>
                                                        <DashContainerCardContentOrder>{val.title}</DashContainerCardContentOrder></DashContainerCardContent>
                                                </DashContentCard>
                                            )
                                        })
                                    }
                                </DashContainerCard>
                            </DashRow>
                        </DashBox>
                    </DashboardWrapper>
                </DashboardContainer>
            </div>
            {isLoading && <Overlay />}

        </>

    )
}


const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setSidebar: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_SIDEBAR,
                updateSidebar: updatedValue,
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
