import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import get from "lodash/get";
import Lottie from 'react-lottie';
import Input from '../../components/Input';
import CarrotInput from '../../components/CarrotInput';
import Overlay from '../../components/Overlay';
import axios from "../../axios";

import ChairGuySvg from "../../images/CarrotSvg/ChairGuy.svg"
import ChairGuyImage from "../../images/CarrotImages/ChairGuy.png";
import GreenBottomImage from "../../images/CarrotImages/GreenBottom.png";
import LottieCarrot from "../../images/CarrotJSON/carrot.json";
import CarrotMobileImage from "../../images/CarrotSvg/carrotMobile.svg";
import DotCarrotSection from "../../images/CarrotSvg/DotCarrotSection.svg";
import DotCarrotInside from "../../images/CarrotSvg/DotCarrotInside.svg";

import { loginValidator } from '../../utils/validators';

import { HeroContainer, HeroBg, ImgBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, NavBtnLink, ChairSvg, SignWelcome,
    WelcomeDesign,
    TrapeziumContent,
    TrapeziumLeft,
    TrapeziumRight,
    SignInSection,
    WelcomeRow,
    ButtonInside,
    EmailPswdSection,
    LabelInput,
    InputLabel,
    // CarrotInput,
    RightSectionInsideDot,
    RightSectionDotOutBig,
    LeftSectionUpperDot,
    LeftSectionOutsideDot,
    RightSectionOutsideDot,
    LeftSectionDot,
    ForgotRow,
    ForgotText,
    CarrotButtonRow,
    CarrotButton,
    DontHaveRow,
    ChairSvgSection,
    ChairGuy,
    LottieDivMobile,
    LottieDiv,
    DontHave,
    SignupText,
    WelcomeSection } from './LoginElements';




const HeroSection = ({ defaultState, setDefaultState, setUsers, setUserToken, deviceToken, 
    setDeviceToken, }) => {
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [loginValues, setLoginValues] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        window.scrollTo(0,0);
    },[])


    const defaultOptionsCarrot = {
        loop: true,
        autoplay: true,
        animationData: LottieCarrot,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };


      const handleLogin = async (values, isSocial = false) => {
        setLoginValues(values);
        setIsLoading(true);
        let url = "/user/login";
        let formvalues = {
            email: values.email,
            password: values.password,
            deviceToken: deviceToken || "uselessToken"
        }
        try {
            const { data } = await axios.post(url, formvalues);
            console.log(data);
            if (data.data.is_verified) {
                localStorage.setItem("access_token", data.access_token);
                localStorage.setItem("userDataCarrot", JSON.stringify(data.data));
                console.log(data.access_token,"access")
                setUsers(data.data);
                setUserToken(data.access_token);
                navigate("/");
            } else {
                sendOTP({
                    email: values.email,
                    _id: get(data,"data._id")
                });
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.data?.errors) {
                toast.error(`${error.response.data.errors[0].msg}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(`${error?.response?.data?.message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const sendOTP = async (values, forget = false) => {
        console.log(values);
        setIsLoading(true);
        try {
            const { data } = await axios.post("/user/sendOtp", {
                email: values.email
            });
            toast.success(`${data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            navigate(`/verifyOtp/${values._id}`);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.data?.errors) {
                toast.error(`${error.response.data.errors[0].msg}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(`${error?.response?.data?.message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    
    

    return (
        <>
            <HeroContainer>
                <HeroBg>
                    <ImgBg src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                </HeroBg>
                <HeroContent className="container">
                    <SignWelcome>
                        <SignInSection>
                            <HeroH1>
                                <div style={{color: "#101011", fontFamily: "Titan One"}}>Sign</div>&nbsp;<div style={{color: "#28C54E", fontFamily: "Titan One"}}>In</div>
                            </HeroH1>
                            <HeroP>We will send you mail to verify</HeroP>
                        </SignInSection>
                        <WelcomeSection>
                            <WelcomeDesign>
                                <TrapeziumLeft>
                                    <LeftSectionDot src={DotCarrotSection}/>
                                    <LeftSectionOutsideDot src={DotCarrotInside}/>
                                    <LeftSectionUpperDot src={DotCarrotInside}/>
                                </TrapeziumLeft>
                                <TrapeziumRight>
                                    <RightSectionInsideDot src={DotCarrotInside}/>
                                    <RightSectionDotOutBig src={DotCarrotSection}/>
                                    <RightSectionOutsideDot src={DotCarrotInside}/>
                                </TrapeziumRight>
                                <TrapeziumContent>
                                    <WelcomeRow>
                                        Welcome to Smart Carrots
                                    </WelcomeRow>
                                    <EmailPswdSection>
                                        <Formik
                                            enableReinitialize
                                            initialValues={loginValues}
                                            validate={(values) => loginValidator(values)}
                                            validateOnChange
                                            onSubmit={(values) => handleLogin(values, false)}
                                        >
                                            {(formikBag) => {
                                                return (
                                                    <Form className="formStyle">
                                                        <Field name="email">
                                                            {({ field }) => (
                                                                <LabelInput>
                                                                    <InputLabel>
                                                                        Email Address
                                                                    </InputLabel>
                                                                    <CarrotInput
                                                                        {...field}
                                                                        type="text"
                                                                        placeholder="Email Address"
                                                                        value={formikBag.values.email}
                                                                        onChange={(e) => {
                                                                            formikBag.setFieldValue(
                                                                                "email",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        error={
                                                                            formikBag.touched.email &&
                                                                                formikBag.errors.email
                                                                                ? formikBag.errors.email
                                                                                : null
                                                                        }
                                                                    />
                                                                </LabelInput>
                                                            )}
                                                        </Field>
                                                        <Field name="password">
                                                            {({ field }) => (
                                                                <LabelInput>
                                                                    <InputLabel>
                                                                        Password
                                                                    </InputLabel>
                                                                    <CarrotInput
                                                                        {...field}
                                                                        type="password"
                                                                        placeholder="Password"
                                                                        value={formikBag.values.password}
                                                                        onChange={(e) => {
                                                                            formikBag.setFieldValue(
                                                                                "password",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        autocomplete="off"
                                                                        // className="passwordDot"
                                                                        error={
                                                                            formikBag.touched.password &&
                                                                                formikBag.errors.password
                                                                                ? formikBag.errors.password
                                                                                : null
                                                                        }
                                                                    />
                                                                </LabelInput>
                                                            )}
                                                        </Field>
                                                        
                                                        <ForgotRow>
                                                            <ForgotText
                                                                onClick={() => {
                                                                    navigate("/forgot")
                                                                }}
                                                            >
                                                                Forgot Password?
                                                            </ForgotText>
                                                        </ForgotRow>
                                                        <CarrotButtonRow>
                                                            <CarrotButton
                                                                type="submit"
                                                            >
                                                                <ButtonInside>
                                                                Sign In
                                                                </ButtonInside>
                                                            </CarrotButton>
                                                        </CarrotButtonRow>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </EmailPswdSection>
                                    <DontHaveRow>
                                        <DontHave>
                                            Don’t have an account?
                                        </DontHave>
                                        <SignupText
                                            onClick={() => {
                                                navigate("/signup")
                                            }}
                                        >
                                            &nbsp;Sign Up Here
                                        </SignupText>
                                    </DontHaveRow>
                                </TrapeziumContent>
                                <LottieDiv>
                                    <Lottie
                                        options={defaultOptionsCarrot}
                                        height={"100%"}
                                        width={"100%"}
                                        style={{
                                            transform: "rotate(245deg)"
                                        }}
                                    />
                                </LottieDiv>
                                <LottieDivMobile src={CarrotMobileImage}>
                                    
                                </LottieDivMobile>
                            </WelcomeDesign>
                        </WelcomeSection>
                    </SignWelcome>
                </HeroContent>
                <ChairSvgSection>
                    <ChairGuy src={ChairGuyImage} />
                    {/*<ChairBottom src={GreenBottomImage}/>*/}
                        
                </ChairSvgSection>
            </HeroContainer>
            {isLoading && <Overlay />}
        </>
    )
}




const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        userToken: state.userToken,
        deviceToken: state.deviceToken,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setUserToken: (updatedValue) => {
            dispatch({
                type: actionTypes.USER_TOKEN,
                updatedToken: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setDeviceToken: (updatedValue) => {
            dispatch({
              type: actionTypes.DEVICE_TOKEN,
              updateDeviceToken: updatedValue,
            });
          },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
