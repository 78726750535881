import styled from 'styled-components'

export const DashboardContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#FFF9FB')};
    margin-left: 280px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
    
    
    @media screen and (max-width: 780px) {
        margin-left: 0px;
    }
    
    @media screen and (max-width: 480px) {
        margin-left: 0px;
    }
`

export const DashboardWrapper = styled.div`
    display: flex;
    z-index: 1;
    height: 90vh;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    overflow: overlay;
`

export const DashboardHeading = styled.div`
    padding: 0.5rem 2rem;
    margin-top: 1rem;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    width: 100%;


    @media screen and (max-width: 768px) {
        padding: 0.5rem 1.5rem;
        margin-top: 0.4rem;
    }
    
    @media screen and (max-width: 480px) {
        margin-top: 0.2rem;
        padding: 0.3rem 0rem;
    }

    @media screen and (max-width: 320px) {
        margin-top: 0rem;
        padding: 0rem 0rem;
    }
`

export const DashHeading = styled.div`
    font: normal normal bold 20px/56px Lato;
    letter-spacing: 0.4px;
    color: #000000;
    opacity: 1;
    font-size: 26px;
    text-align: left;


    @media screen and (max-width: 880px) {
        font-size: 18px;
    }

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 14px;
    }
    

`

export const DashBox = styled.div`
    height: auto;
    width:  96.5%;
    display: flex;
    flex-direction: column;
`

export const DashRow = styled.div`
    height: auto;
    // width:  100%;
    // background-color: lightpink;
    display: flex;
    flex-direction: column;
`


export const DashCard = styled.div`
height: auto;
// width: auto;
display: flex;
font-size: 17px;
padding: 10px 15px;
background-color: white;
justify-content: space-between;
align-items: center;
border-radius: 5px;
margin-bottom: 15px;
box-shadow: 0px 2px 2px #00000012;
color: #1A1A1A;
`

export const DashContainerCard = styled.div`
// background-color: pink;
height: auto;
width: auto;
padding: 10px 20px;
display: grid;
margin-left: 1.5rem;
grid-template-columns: auto auto auto;
grid-gap: 14px 25px;


@media screen and (max-width: 1024px) {
    grid-template-columns: auto auto;    
}

@media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;    
}
`

export const DashContentCard = styled.div`
height: auto;
width: auto;
display: flex;
font-size: 17px;
padding: 15px 36px;
background-color: #FFFFFF;
justify-content: space-between;
align-items: center;
border-radius: 5px;
margin-bottom: 15px;
box-shadow: 0px 2px 2px lightgrey;
color: #1A1A1A;


@media screen and (max-width: 1024px) {
    
}

`
export const DashContainerCardIcon = styled.div`
height: auto;
width: auto;
display: flex;
justify-content: center;
border-radius: 50%;
padding: 0.3rem 0.2rem;
color: white;
background-color: blue;
`

export const DashIcon = styled.img`
height: auto;
    width: 70px;
    padding: 14px;
`

export const DashContainerCardContent = styled.div`
height: auto;
width: 65%;
margin: auto;
padding: 1rem;
// background-color: lightcyan;
`

export const DashContainerCardContentTotal = styled.p`
color: #808080;
font: normal normal normal 11px/24px Lato;
letter-spacing: 0.11px;
`

export const DashContainerCardContentNumber = styled.p`
color: #00000;
font: normal normal bold 20px/24px Lato;
letter-spacing: 0.2px;
`
export const DashContainerCardContentOrder = styled.p`
color: #404040;
font: normal normal normal 14px/24px Lato;
letter-spacing: 0.42px;
`




export const DashContent = styled.p`
height: auto;
width: 97%;
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 10px;
color: #1A1A1A;
padding: 0 10px;
font-size: 1.3rem;
margin-left: 2rem;

@media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;    
    justify-content: center;
    width: 88%;
}
`


export const FilterCon = styled.div`
    display: flex;
    align-items: center; 
    width: auto;
    height: auto;
    // background-color: grey;
    @media screen and (max-width: 1024px) {
        margin-bottom: 1rem;
    }

    @media screen and (max-width: 768px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    @media screen and (max-width: 425px) {
        flex-direction: column;
        text-align: center;
    }
`


export const TextLabel = styled.label`
    font-size: 1.1rem;
    color: black;
    @media screen and (max-width: 1024px) {
        margin: auto;
    }

    @media screen and (max-width: 425px) {
        text-align: center;
        margin-left: 10rem;
    }

    @media screen and (max-width: 375px) {
        margin-left: 8rem;
    }

    @media screen and (max-width: 320px) {
        margin-left: 6.2rem;
    }
`


export const SubButton = styled.button`
    border: none;
    background-color: none;
    border: 1px solid lightgrey;
    padding: 0.5rem 0.8rem;
    margin-bottom: 0.5rem;
    // background-color: blue;
    border-radius: 5px;
    color: black;
    

`



