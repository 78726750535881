import React, { useState, useEffect, useContext } from 'react'
import { DashboardContainer, DashboardWrapper, DashboardHeading, DashHeading, HeadingButton, SvgLogo, BackIcon, MenuAndBack, PreperationTime, LabelHeading, AddTrashSizeButton, AddSizeButton, SelectSize, LabelCur } from './DashboardElements'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form, FieldArray } from "formik";
import { Modal } from '../Modal/Modal'
import Input from "../Input";
import Select from "../Select";
import FileInput from "../FileInput";
import { uploadImage } from "../../utils/functions";
import axios from "../../axios";
import Overlay from '../Overlay'
import { toast } from "react-toastify";
import EditIcon from "../../images/edit_profile_button_table.png"
import DeleteIcon from "../../images/delete_profile_button_table.png"
import AddIcon from "../../images/addIcon.png"
import * as IoIcons from 'react-icons/io';
import { get, isEmpty } from 'lodash';
import { NavLink, Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import Switch from '../Switch/Switch';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SelectBox from "@material-ui/core/Select";
import { SearchContainer, SearchBar, SearchIcon, SearchInput } from '../SearchBar/SearchElements';
import { FaSearch } from 'react-icons/fa';
import { LanguageContext } from "../../utils/language/Language";


import {
    menuValidator,
    dishValidator,
} from "../../utils/validators";

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './Autocompletecss.css'

const useStyles = makeStyles((theme) => ({

    textMiddle: {
        verticalAlign: 'middle !important',
        textAlign: "center"
    },
    tablePadding: {
        padding: "0.5rem",
        textAlign: "center",
        fontSize: "1rem"
    },
    paperTableHeight: {
        height: "650px",
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    "@media (max-width: 780px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        }
    },
    "@media (max-width: 480px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        }
    },
    tablePaginationStyle: {
        border: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
    },
    tableFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const Dashboard = ({ history, location, userData }) => {
    const classes = useStyles();

    const { dictionary } = useContext(LanguageContext);
    const [defaultState, setDefaultState] = useState({ isAddMenu: "", isDishAdd: "" })
    const [info, setInfo] = useState({ isMaster: "" })
    const [menuState, setMenuState] = useState({ isMenu: true, isDish: false })
    const [menuData, setMenuData] = useState([]);
    const [dishData, setDishData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cuisineList, setCuisineList] = useState([]);
    const [radioState, setRadioState] = useState({ isMulti: true, isFixed: false });
    const [sizeData, setSizeData] = useState([])
    const [searchKey, setSearchKey] = useState([])
    const [offerCheck, setOfferCheck] = useState(false);
    const [updateDishFormValues, setDishFormValues] = useState({
        type: '',
        menu_id: "",
        restaurant_id: "",
        dish_name: "",

        search_keywords: [],
        dish_type: {
            value: "",
            label: "",
        },
        dish_price: "",
        ingredient: "",
        minimum_preparation_time: "",
        description: "",
        dish_images: [],
        dish_status: {
            value: "",
            label: "",
        },
        cuisine_name: "",
        cuisine_id: "",
        base_price: "",
        radioJump: {
            isMulti: true,
            isFixed: false
        },
        price_data: [{
            serving_size: "",
            dish_price: ""
        }],
        is_best_seller: false,
        is_offer_campaign: false,
        discount_type: "",
        discount: "",

    });
    const [searchedData, setSearchedData] = useState([]);
    const [searched, setSearched] = useState("");

    let dataDiscountType = [{
        label: "Flat",
        value: "1"
    },
    {
        label: "Percentage",
        value: "2"
    }];


    const [updateMenuFormValues, setMenuFormValues] = useState({
        menu_title: "",
        menu_image: ""
    });

    // Add more Sizes
    // const [inputList, setInputList] = useState([{ serving_size: "", dish_price: "" }]);
    const [feildError, setFeildError] = useState("");

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...updateDishFormValues.price_data];
        list[index][name] = value;
        // setInputList(list);
        console.log(list);
        setDishFormValues({ ...updateDishFormValues, price_data: list });
    };

    // handle click event of the Remove button
    const handleRemoveClick = (index, formikBag) => {
        const list = [...formikBag.values.price_data];
        list.splice(index, 1);
        // setInputList(list);
        setDishFormValues({ ...updateDishFormValues, price_data: list });
        // setDishFormValues(vals => ({...vals, price_data: [...formik.values.price_data: ]}));
    };

    // handle click event of the Add button
    const handleAddClick = (formik) => {
        setDishFormValues(vals => ({ ...vals, price_data: [...formik.values.price_data, { serving_size: "", dish_price: "" }] }));
        // setInputList([...inputList, { serving_size: "", dish_price: "" }]);
        // setDishFormValues(vals => (console.log(vals), { ...vals, price_data: [...vals.price_data, { serving_size: "", dish_price: "" }] }));
    };


    // console.log([inputList[0].dish_price]);




    // console.log(localStorage);
    // console.log(JSON.parse(localStorage.getItem("myChartLayout")));


    useEffect(() => {
        getMenu();
        getDish();
        fetchSizes();
        fetchCuisineList();
    }, []);



    // For Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const getMenu = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_menu_list",
                // {
                //     headers: {
                //         access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGVfbnVtYmVyIjoiMTQ3ODUyMzU3MCIsImlhdCI6MTYzNzY4NDg4N30.iT1DDdVe7LOpqYoG5QqXWNdO6yHPtxO7SWJ1BL3fcTo"
                //     }
                // }
            );
            setMenuData(data.data);
        } catch (error) {
            console.log(error);

            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const getDish = async (id) => {
        if (id) {
            try {
                const { data } = await axios.get(`/restaurant/get_all_dish?menu_id=${id}`);
                console.log(data.data);
                setDishData(data.data);
                setSearchedData(data.data)
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const { data } = await axios.get(`/restaurant/get_all_dish?menu_id=${updateDishFormValues.menu_id}`);
                console.log(data);
                setDishData(data.data);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const requestSearch = (searchedVal) => {
        const filteredRows = searchedData.filter((row) => {
            return row.dish_name.toLowerCase().includes(searchedVal.target.value.toLowerCase());
        });
        setDishData(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        getMenu();
    };
    // const [isToggled, setIsToggled] = useState(checkDishStatusTF(get(dishData, 'dish_status', "")))


    // getDish();
    console.log(updateDishFormValues);

    const optionFoodType = [
        {
            value: "veg",
            label: "Veg",
        },
        {
            value: "nonveg",
            label: "Non-Veg",
        }
    ]

    const optionDishStatus = [
        {
            value: "1",
            label: "Available",
        },
        {
            value: "0",
            label: "Not Available",
        }
    ]

    const optionServingSizeStatus = [
        {
            value: "1",
            label: "Half",
        },
        {
            value: "0",
            label: "Full",
        },
        {
            value: "2",
            label: "Small",
        },
        {
            value: "3",
            label: "Medium",
        },
        {
            value: "4",
            label: "Large",
        },
    ]

    const checkDishStatus = (e) => {
        if (JSON.stringify(e) === "0") {
            return "Not Available"
        } else if (JSON.stringify(e) === "1") {
            return "Available"
        } else {
            return ""
        }
    }

    const checkDishStatusTF = (e) => {
        if (JSON.stringify(e) === "0") {
            return false
        } else if (JSON.stringify(e) === "1") {
            return true
        } else {
            return ""
        }
    }
    

    const checkDishStatusValue = (e) => {
        if (JSON.stringify(e) === "0") {
            return 1;
        } else if (JSON.stringify(e) === "1") {
            return 0;
        } else {
            return ""
        }
    }


    // console.log(isToggled);



    const handleMenuProfile = async (values) => {

        var fromData = {
            menu_title: values.menu_title,
            menu_image: "abc.jpg",
        };
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isAddMenu: false,
            };
        });

        try {
            if (values.menu_id) {
                const { data } = await axios.post("/restaurant/update_menu", {
                    menu_title: values.menu_title,
                    menu_id: values.menu_id,
                    menu_image: "abc.jpg",
                });
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getMenu();
            } else {
                const { data } = await axios.post("/restaurant/create_menu", fromData);
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getMenu();
            }

        } catch (error) {
            setIsLoading(false);
            setDefaultState((prevState) => {
                return {
                    ...prevState,
                    isAddMenu: false,
                };
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const handleDishProfile = async (values) => {
        console.log("values", values);

        var fromData = {
            menu_id: values.menu_id,
            restaurant_id: values.restaurant_id,
            dish_name: values.dish_name,
            search_keywords: searchKey,
            dish_type: values.dish_type.label,
            base_price: values.radioJump.isFixed == true ? values.base_price : 0,
            ingredient: ["onion", "tomato"],
            minimum_preparation_time: values.minimum_preparation_time,
            description: values.description,
            serving_size: values.serving_size.label,
            dish_images: values.dish_images,
            dish_status: values.dish_status.value,
            cuisine_id: values.cuisine_id,
            price_data: values.radioJump.isMulti == true ? values.price_data : [],
            is_best_seller: values.is_best_seller,
            is_offer_campaign: values.is_offer_campaign,
            discount: values.discount,
            discount_type: values.discount_type.value,
        };
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isDishAdd: false,
            };
        });

        try {
            if (values._id) {
                const { data } = await axios.post("/restaurant/update_dish", {
                    _id: values._id,
                    menu_id: values.menu_id,
                    restaurant_id: values.restaurant_id,
                    dish_name: values.dish_name,
                    search_keywords: searchKey,
                    dish_type: values.dish_type.label,
                    // base_price: inputList[0].dish_price > 0 ? 0 : values.base_price,
                    base_price: values.radioJump.isFixed == true ? values.base_price : 0,
                    ingredient: ["onion", "tomato"],
                    minimum_preparation_time: values.minimum_preparation_time,
                    description: values.description,
                    serving_size: values.serving_size.label,
                    dish_images: values.dish_images,
                    dish_status: values.dish_status.value,
                    cuisine_id: values.cuisine_id,
                    // price_data: inputList
                    // price_data: values.base_price > 0 ? [] : inputList
                    price_data: values.radioJump.isMulti == true ? values.price_data : [],
                    is_best_seller: values.is_best_seller,
                    is_offer_campaign: values.is_offer_campaign,
                    discount: values.discount,
                    discount_type: values.discount_type.value,
                });
                console.log({
                    _id: values._id,
                    menu_id: values.menu_id,
                    restaurant_id: values.restaurant_id,
                    dish_name: values.dish_name,
                    search_keywords: values.search_keywords,
                    dish_type: values.dish_type.label,
                    // base_price: inputList[0].dish_price > 0 ? 0 : values.base_price,
                    base_price: values.radioJump.isFixed == true ? values.base_price : 0,
                    ingredient: ["onion", "tomato"],
                    minimum_preparation_time: values.minimum_preparation_time,
                    description: values.description,
                    serving_size: values.serving_size.label,
                    dish_images: values.dish_images,
                    dish_status: values.dish_status.value,
                    cuisine_id: values.cuisine_id,
                    // price_data: inputList
                    // price_data: values.base_price > 0 ? [] : inputList
                    price_data: values.radioJump.isMulti == true ? values.price_data : [],
                    is_best_seller: values.is_best_seller,
                })
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getDish();
            } else {
                const { data } = await axios.post("/restaurant/add_dish", fromData);
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getDish();
            }

        } catch (error) {
            setIsLoading(false);
            setDefaultState((prevState) => {
                return {
                    ...prevState,
                    isDishAdd: false,
                };
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    console.log(feildError);


    const handleDeleteItem = async (id) => {
        if (window.confirm('Are you sure you want to delete this field ?')) {
            try {
                const { data } = await axios.post("/restaurant/remove_menu", {
                    menu_id: id,
                });
                getMenu();
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                console.log(error);
                toast.error(`${error.response.data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            getMenu();
        }
    };

    const handleDeleteDish = async (id) => {
        if (window.confirm('Are you sure you want to delete this field ?')) {
            try {
                const { data } = await axios.post("/restaurant/remove_dish", {
                    _id: id,
                });
                getDish();
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                console.log(error);
                toast.error(`${error.response.data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            getDish();
        }
    };


    const handleDishStatus = async (values) => {
        let formvalues = {
            _id: values._id,
            dish_status: checkDishStatusValue(values.dish_status)
        }

        console.log(formvalues);
        try {
            const { data } = await axios.post("/restaurant/dish_status", formvalues);
            toast.success(`${data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            getDish();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);

            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

    }

    // if(menuState.isDish) {
    //     getDish();
    // }


    // console.log(updateDishFormValues);

    const fetchCuisineList = async () => {
        try {
            let { data } = await axios.get("admin-business/get_cuisine");
            console.log(data);
            setCuisineList(
                data.data.map((item) => {
                    return { label: item.title, value: item._id };
                })
            );
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong.", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    console.log(cuisineList);


    // Sizes

    const fetchSizes = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_all_sizes");
            setSizeData(data.data);
            console.log(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const offerDishStatus = (e) => {
        if (JSON.stringify(e) === "0") {
            return false
        } else if (JSON.stringify(e) === "1") {
            return true
        } else {
            return ""
        }
    }

    const discountType = (e) => {

        console.log(e);
        if (e === "1") {
            return "Flat"
        } else if (e === "2") {
            return "Percentage"
        }
    }



    console.log(searchKey)

    console.log("updateDishFormValues",  updateDishFormValues);
    console.log("setOfferCheck", offerCheck);


    return (
        <>
            <div>
                <DashboardContainer>
                    <DashboardWrapper>
                        <DashboardHeading>
                            <MenuAndBack>
                                <BackIcon>
                                    {menuState.isDish ? (
                                        <>
                                            <HeadingButton
                                                style={{ fontSize: "1.5rem", padding: "0.2em 0.5em" }}
                                                onClick={() => {
                                                    setMenuState({
                                                        isMenu: true,
                                                        isDish: false
                                                    });
                                                }}
                                            >
                                                <IoIcons.IoIosArrowRoundBack />
                                            </HeadingButton>
                                        </>
                                    ) : ""}
                                </BackIcon>
                                <DashHeading>
                                    {menuState.isMenu ? dictionary.menu.Menu_Management : ""}
                                    {menuState.isDish ? dictionary.menu.Dish_Management : ""}
                                </DashHeading>
                            </MenuAndBack>
                            {
                                menuState.isDish ? <SearchContainer>
                                    <SearchBar>
                                        <SearchIcon>
                                            <FaSearch style={{ color: "#666666" }} />
                                        </SearchIcon>
                                        <SearchInput type="text"
                                            onChange={(searchVal) => requestSearch(searchVal)}
                                            placeholder={dictionary.menu.Search_by_dish_name}>
                                        </SearchInput>
                                    </SearchBar>
                                </SearchContainer> : ""
                            }
                            <HeadingButton onClick={() => {
                                if (menuState.isMenu) {
                                    setMenuFormValues({
                                        menu_title: "",
                                        menu_id: "",
                                        menu_image: ""
                                    })
                                    setDefaultState({
                                        isAddMenu: true
                                    });
                                }
                                if (menuState.isDish) {
                                    setDishFormValues({
                                        menu_id: updateDishFormValues.menu_id,
                                        restaurant_id: updateDishFormValues.restaurant_id,
                                        dish_name: "",
                                        search_keywords: [],
                                        dish_type: "",
                                        base_price: "",
                                        ingredient: "",
                                        minimum_preparation_time: "",
                                        description: "",
                                        serving_size: "",
                                        dish_price: "",
                                        dish_images: [],
                                        dish_status: "",
                                        cuisine_name: "",
                                        cuisine_id: "",
                                        radioJump: {
                                            isMulti: true,
                                            isFixed: false
                                        },
                                        price_data: [{
                                            serving_size: "",
                                            dish_price: ""
                                        }],
                                        is_best_seller: false,
                                        is_offer_campaign: false,
                                        discount_type: "",
                                        discount: "",
                                    })
                                    setDefaultState({
                                        isDishAdd: true
                                    });
                                    // setRadioState({
                                    //     isFixed: false,
                                    //     isMulti: true
                                    // })

                                }
                            }}>
                                {menuState.isMenu ? dictionary.menu.Menu_Management : ""}
                                {menuState.isDish ? dictionary.menu.Add_New_Dish : ""}
                            </HeadingButton>
                        </DashboardHeading>
                        <Paper className={classes.paperTableHeight}>
                            {menuState.isMenu ? (
                                <>
                                    <TableContainer className={classes.tableContainerHeight}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tablePadding}>S. No.</TableCell>
                                                    <TableCell className={classes.tablePadding}>
                                                        {/* <TableSortLabel
                                                            active={true}
                                                            direction={orderBy === "firstName" ? order : "asc"}
                                                            onClick={() => {
                                                                handleSortRequest("firstName");
                                                            }}
                                                        > */}
                                                       {dictionary.menu.Menu_Name}
                                                        {/* </TableSortLabel> */}
                                                    </TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.menu.Add_Dish}</TableCell>
                                                    <TableCell className={classes.tablePadding}>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {menuData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category, index) => (
                                                    <TableRow key={category._id}>
                                                        <TableCell component="th" scope="row" className={classes.textMiddle}>
                                                            {index + 1 + (page) * rowsPerPage}
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.menu_title}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <SvgLogo
                                                                style={{ backgroundColor: "#000000" }}
                                                                className="logoImage"
                                                                src={AddIcon}
                                                                onClick={() => {
                                                                    setDishFormValues({
                                                                        menu_id: category._id,
                                                                        restaurant_id: category.restaurant_id,
                                                                        dish_name: "",
                                                                        dish_type: "",
                                                                        dish_price: "",
                                                                        minimum_preparation_time: "",
                                                                        description: "",
                                                                        serving_size: "",
                                                                        dish_images: "",
                                                                        dish_status: "",
                                                                        cuisine_name: "",
                                                                        cuisine_id: "",
                                                                        price_data: [{
                                                                            serving_size: "",
                                                                            dish_price: ""
                                                                        }],
                                                                        is_best_seller: false

                                                                    })
                                                                    setMenuState({
                                                                        isMenu: false,
                                                                        isDish: true
                                                                    });
                                                                    getDish(category._id);
                                                                }} />
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div className={classes.tableFlex}>
                                                                <SvgLogo
                                                                    style={{ padding: "0rem 0.2rem" }}
                                                                    className="logoImage"
                                                                    src={EditIcon}
                                                                    onClick={() => {
                                                                        setMenuFormValues({
                                                                            menu_title: category.menu_title,
                                                                            menu_id: category._id
                                                                        })
                                                                        setDefaultState({
                                                                            isAddMenu: true
                                                                        });
                                                                    }} />
                                                                {/* <SvgLogo
                                                                    style={{ padding: "0rem 0.2rem" }}
                                                                    className="logoImage"
                                                                    src={DeleteIcon}
                                                                    onClick={() => {
                                                                        handleDeleteItem(category._id)
                                                                    }
                                                                    }
                                                                /> */}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className={classes.tablePaginationStyle}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={menuData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            ) : ""}
                            {menuState.isDish ? (
                                <>
                                    <TableContainer className={classes.tableContainerHeight}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tablePadding}>{dictionary.size_master_commom.SNO}</TableCell>
                                                    <TableCell className={classes.tablePadding}>
                                                        {/* <TableSortLabel
                                                            active={true}
                                                            direction={orderBy === "dish_name" ? order : "asc"}
                                                            onClick={() => {
                                                                handleSortRequest("dish_name");
                                                            }}
                                                        > */}
                                                        {dictionary.menu.Dish_Name}
                                                        {/* </TableSortLabel> */}
                                                    </TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.menu.Dish_Image}</TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.menu.Rating}</TableCell>
                                                    {/* <TableCell className={classes.tablePadding}>Serving&nbsp;Size</TableCell> */}
                                                    <TableCell className={classes.tablePadding}>{dictionary.menu.Preperation_Time}</TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.menu.Food_Type}</TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.menu.Description}</TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.menu.Price_size}</TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.size_master_commom.Actions}</TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.size_master_commom.Status}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dishData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category, index) => (
                                                    <TableRow key={category._id}>
                                                        <TableCell component="th" scope="row" className={classes.textMiddle}>
                                                            {index + 1 + (page) * rowsPerPage}
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>{category.dish_name}<br></br>{category.is_best_seller == true ? <p style={{ fontSize: "0.7rem", color: "red" }}>Best Seller</p> : ""}</TableCell>
                                                        <TableCell className={classes.textMiddle}>{<a href={category.dish_images[0]} target="_blank" rel="noreferrer"><img src={category.dish_images[0]} alt="" height="60px"></img></a>}</TableCell>
                                                        <TableCell className={classes.textMiddle}>{category.rating} <i className="ph-star-fill" style={{color: "rgb(237, 138, 25)"}}></i></TableCell>
                                                        {/* <TableCell className={classes.textMiddle}>{category.serving_size}</TableCell> */}
                                                        <TableCell className={classes.textMiddle}>{category.minimum_preparation_time} Min</TableCell>
                                                        <TableCell className={classes.textMiddle}>{category.dish_type}</TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.description}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.base_price ? `₺${category.base_price}` : category.price_data.map((val, ind) => `${val.serving_size} ₺${val.dish_price} ${ind < (category.price_data.length - 1) ? ' , ' : ''}`)}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div className={classes.tableFlex}>
                                                                <SvgLogo
                                                                    style={{ padding: "0rem 0.2rem" }}
                                                                    className="logoImage"
                                                                    src={EditIcon}
                                                                    onClick={() => {
                                                                        setDishFormValues({
                                                                            _id: category._id,
                                                                            menu_id: category.menu_id._id,
                                                                            restaurant_id: category.menu_id.restaurant_id,
                                                                            dish_name: category.dish_name,
                                                                            search_keywords: get(category, "search_keywords", []) !== []
                                                                                ? get(category, "search_keywords", []).map((item) => item)
                                                                                : "",
                                                                            dish_type: {
                                                                                value: category.dish_type,
                                                                                label: category.dish_type,
                                                                            },
                                                                            base_price: category.base_price == 0 ? "" : category.base_price,
                                                                            ingredient: "",
                                                                            minimum_preparation_time: category.minimum_preparation_time,
                                                                            description: category.description,
                                                                            serving_size: get(category, "price_data[0].serving_size", ""),
                                                                            dish_price: get(category, "price_data[0].dish_price", ""),
                                                                            dish_images: category.dish_images,
                                                                            dish_status: {
                                                                                value: category.dish_status,
                                                                                // label: {category.dish_status==0 ? "Not Available": "Available"},                                                                    
                                                                                label: checkDishStatus(category.dish_status),
                                                                            },
                                                                            cuisine_name: category.cuisine_id.title,
                                                                            cuisine_id: category.cuisine_id._id,
                                                                            radioJump: {
                                                                                isMulti: category.base_price > 0 ? false : true,
                                                                                isFixed: category.base_price > 0 ? true : false
                                                                            },
                                                                            price_data: category.price_data.length == 0 ? [{
                                                                                serving_size: "",
                                                                                dish_price: ""
                                                                            }] : category.price_data,
                                                                            is_best_seller: category.is_best_seller,
                                                                            is_offer_campaign: category.is_offer_campaign,
                                                                            discount_type: category.discount_type,
                                                                            discount: category.discount
                                                                        })
                                                                        setDefaultState({
                                                                            isDishAdd: true
                                                                        });
                                                                        setSearchKey(get(category, "search_keywords", []) !== []
                                                                            ? get(category, "search_keywords", []).map((item) => item)
                                                                            : "")
                                                                        // setInputList(category.price_data)
                                                                        // {
                                                                        //     category.base_price > 0 ? setRadioState({ isFixed: true }) : setRadioState({ isMulti: true })
                                                                        // }

                                                                    }}
                                                                />
                                                                <SvgLogo
                                                                    style={{ padding: "0rem 0.2rem" }}
                                                                    className="logoImage"
                                                                    src={DeleteIcon}
                                                                    onClick={() => {
                                                                        handleDeleteDish(category._id)
                                                                    }}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Switch
                                                                    rounded={true}
                                                                    isToggled={checkDishStatusTF(category.dish_status)}
                                                                    bgColorChecked="#07bc0c"

                                                                    roundColorChecked="#ffffff"

                                                                    border="1px solid #8D8D8D"

                                                                    bgColorUnChecked="red"

                                                                    roundColorUnChecked="#ffffff"
                                                                    onToggle={() => {
                                                                        handleDishStatus({
                                                                            _id: category._id,
                                                                            dish_status: category.dish_status
                                                                        })
                                                                        // setIsToggled(!isToggled)
                                                                    }}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className={classes.tablePaginationStyle}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={dishData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            ) : ""}
                        </Paper>
                    </DashboardWrapper>
                </DashboardContainer>
            </div>


            {/* Menu add */}
            <Modal
                isOpen={defaultState.isAddMenu}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isAddMenu: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isAddMenu: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div>

                        <>
                            <h2>Add Menu</h2>
                        </>
                    </div>
                }
                content={

                    <Formik
                        enableReinitialize
                        initialValues={updateMenuFormValues}
                        validate={menuValidator}
                        validateOnChange
                        onSubmit={handleMenuProfile}
                    >
                        {(formikBag) => {
                            return (
                                <Form>
                                    <div className="signup-cont">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <Field name="menu_title">
                                                    {({ field }) => (
                                                        <div className="py-2">
                                                            <Input
                                                                {...field}
                                                                type="text"
                                                                value={formikBag.values.menu_title}
                                                                onChange={(e) => {
                                                                    console.log(formikBag)
                                                                    formikBag.setFieldValue(
                                                                        "menu_title",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                error={
                                                                    formikBag.touched.menu_title &&
                                                                        formikBag.errors.menu_title
                                                                        ? formikBag.errors.menu_title
                                                                        : null
                                                                }
                                                                className="form-control"
                                                                placeholder="Menu"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-md-12">
                                            <HeadingButton type="submit">
                                                Save Updates
                                            </HeadingButton>
                                        </div>

                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                }
            />


            {/* Dish add */}
            <Modal
                isOpen={defaultState.isDishAdd}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isDishAdd: false
                    });
                }}
                maxWidth='md'
                title={
                    <div className="modalsign">
                        <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isDishAdd: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div>

                        <>
                        <h2>{dictionary.menu.Add_Dish}</h2>
                        </>
                    </div>
                }
                content={

                    <Formik
                        enableReinitialize
                        initialValues={updateDishFormValues}
                        validate={dishValidator}
                        validateOnChange
                        // onSubmit={console.log()}
                        onSubmit={handleDishProfile}
                    >
                        {(formikBag) => {
                            return (
                                <Form>
                                    <div className="signup-cont">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>{dictionary.menu.Enter_Dish_Name}</label>
                                                <Field name="dish_name">
                                                    {({ field }) => (
                                                        <div className="py-3">
                                                            <Input
                                                                {...field}
                                                                type="text"
                                                                value={formikBag.values.dish_name}
                                                                onChange={(e) => {
                                                                    console.log(formikBag)
                                                                    formikBag.setFieldValue(
                                                                        "dish_name",
                                                                        e.target.value
                                                                    );
                                                                    setDishFormValues({ ...updateDishFormValues, dish_name: e.target.value })

                                                                }}
                                                                error={
                                                                    formikBag.touched.dish_name &&
                                                                        formikBag.errors.dish_name
                                                                        ? formikBag.errors.dish_name
                                                                        : null
                                                                }
                                                                className="form-control"
                                                                placeholder="Enter Dish Name"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-6" style={{ overflow: "hidden" }}>
                                                <div className="form-group" style={{ overflow: "hidden" }}>
                                                    <label htmlFor="search_keywords">{dictionary.menu.Search_Keywords}</label>
                                                    {/* <Autocomplete
                                                        style={{ padding: "1.2rem 0rem" }}
                                                        multiple
                                                        
                                                        size="small"
                                                        className={isEmpty(formikBag.values.search_keywords) ? "mt-2" : ""}
                                                        id="tags-filled"
                                                        required
                                                        onChange={(event, input) => {
                                                            // console.log(input);
                                                            console.log(formikBag);

                                                            formikBag.setFieldValue(
                                                                "search_keywords", input
                                                            );
                                                            setDishFormValues({ ...updateDishFormValues, search_keywords: input })
                                                            // setCategory({ ...category, "search_keywords" : input });
                                                        }}
                                                        value={formikBag.values.search_keywords.map((option) => option)}
                                                        options={formikBag.values.search_keywords}
                                                        freeSolo
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                            ))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="outlined" label="" placeholder="Search Keywords" />
                                                        )}

                                                        // error={
                                                        //     formikBag.touched.search_keywords &&
                                                        //         formikBag.errors.search_keywords
                                                        //         ? formikBag.errors.search_keywords
                                                        //         : null
                                                        // }
                                                    /> */}
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-filled"
                                                        options={formikBag.values.search_keywords}
                                                        // className={isEmpty(formikBag.values.search_keywords) ? "mt-2" : ""}
                                                        style={{ padding: "0.8rem 0rem", backgroundColor: "white" }}
                                                        defaultValue={formikBag.values.search_keywords.map((option) => option)}
                                                        freeSolo
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                                            ))
                                                        }
                                                        onChange={(event, input) => {
                                                            // console.log(input);
                                                            console.log(input);
                                                            setSearchKey(input)

                                                            // setDishFormValues({ ...updateDishFormValues, search_keywords: input })
                                                        }}
                                                        error={
                                                            formikBag.touched.search_keywords &&
                                                                formikBag.errors.search_keywords
                                                                ? formikBag.errors.search_keywords
                                                                : null
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                style={{ overflow: "hidden" }}
                                                                label="" placeholder="Search Keywords"
                                                            />
                                                        )}
                                                    />
                                                    {formikBag.errors.search_keywords && (
                                                        <p
                                                            style={{
                                                                paddingTop: 5,
                                                                fontSize: 13,
                                                                color: "red",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            {formikBag.errors.search_keywords}
                                                        </p>
                                                    )}
                                                </div>
                                                {/*<label>Search Keywords</label>
                                                <Field name="search_keywords">
                                                    {({ field }) => (
                                                        <div className="py-2">
                                                            <Input
                                                                {...field}
                                                                type="text"
                                                                // value={formikBag.values.search_keywords}
                                                                onChange={(e) => {
                                                                    formikBag.setFieldValue(
                                                                        "search_keywords",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                error={
                                                                    formikBag.touched.search_keywords &&
                                                                        formikBag.errors.search_keywords
                                                                        ? formikBag.errors.search_keywords
                                                                        : null
                                                                }
                                                                className="form-control"
                                                                placeholder="Search Keywords"
                                                            />
                                                        </div>
                                                    )}
                                                            </Field>*/}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>{dictionary.menu.Minimum_Preparation_Time}</label>
                                                <Field name="minimum_preparation_time">
                                                    {({ field }) => (
                                                        <div className="py-2" style={{ width: "100%" }}>
                                                            <Input
                                                                {...field}
                                                                type="number"
                                                                value={formikBag.values.minimum_preparation_time}
                                                                min="1"
                                                                onChange={(e) => {
                                                                    // console.log(formikBag)
                                                                    formikBag.setFieldValue(
                                                                        "minimum_preparation_time",
                                                                        e.target.value
                                                                    );
                                                                    setDishFormValues({ ...updateDishFormValues, minimum_preparation_time: e.target.value })
                                                                    // console.log(formikBag.values.minimum_preparation_time)
                                                                    // console.log(e.target.value)

                                                                }}
                                                                error={
                                                                    formikBag.touched.minimum_preparation_time &&
                                                                        formikBag.errors.minimum_preparation_time
                                                                        ? formikBag.errors.minimum_preparation_time
                                                                        : null
                                                                }
                                                                className="form-control"
                                                                placeholder="Minimim Preparation Time (in minutes)"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                                {/*<PreperationTime>
                                                </PreperationTime>*/}
                                            </div>

                                            <div className="col-md-6">
                                                <label>{dictionary.menu.Best_Seller}</label>
                                                {/* <Field name="is_best_seller">
                                                    {({ field }) => (
                                                        
                                                    )}
                                                </Field> */}
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                                                    <Field
                                                        type="checkbox"
                                                        name='is_best_seller'
                                                        id='is_best_seller'
                                                        label='Check the mark'
                                                        checked={formikBag.values.is_best_seller}
                                                        onChange={(e) => {
                                                            formikBag.setFieldValue(
                                                                "is_best_seller",
                                                                e.target.checked
                                                            );
                                                            setDishFormValues({ ...updateDishFormValues, is_best_seller: e.target.checked })
                                                        }}
                                                    />
                                                    <label htmlFor="is_best_seller" style={{ fontSize: "0.9rem", marginLeft: "0.5rem" }}>Best Seller</label>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>{dictionary.menu.Select_Food_Type}</label>
                                                <Field name="dish_type">
                                                    {({ field }) => (
                                                        <div className="py-2" style={{ height: "100%" }}>
                                                            <Select
                                                                // isMulti
                                                                // className="cm-select"
                                                                value={formikBag.values.dish_type}
                                                                // value={formikBag.values.dish_type.map((option) => ({ label: option.title, value: option._id }))}
                                                                options={optionFoodType}
                                                                onChange={(option) => {
                                                                    formikBag.setFieldValue("dish_type", option);
                                                                    console.log(formikBag.values.dish_type)
                                                                    setDishFormValues({ ...updateDishFormValues, dish_type: option })
                                                                }}
                                                                error={
                                                                    formikBag.touched.dish_type &&
                                                                        formikBag.errors.dish_type
                                                                        ? formikBag.errors.dish_type
                                                                        : null
                                                                }
                                                                // className="form-control"
                                                                placeholder="Select Food Type"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-6">
                                                <label>{dictionary.menu.Select_Cuisine}</label>
                                                <Field name="cuisine_name">
                                                    {({ field }) => (
                                                        <div className="py-2" style={{ height: "100%" }}>
                                                            <Select
                                                                defaultValue={!isEmpty(formikBag.values.cuisine_name) ? { label: formikBag.values.cuisine_name, value: formikBag.values.cuisine_id } : ""}
                                                                options={cuisineList}
                                                                onChange={(option) => {
                                                                    formikBag.setFieldValue(
                                                                        "cuisine_id",
                                                                        option.value
                                                                    );
                                                                    setDishFormValues(vals => ({ ...vals, cuisine_id: option.value }))
                                                                    console.log(option)
                                                                    console.log(cuisineList)
                                                                    formikBag.setFieldValue(
                                                                        "cuisine_name",
                                                                        option.label
                                                                    );
                                                                    setDishFormValues(vals => ({ ...vals, cuisine_name: option.label }))
                                                                }}
                                                                error={
                                                                    formikBag.touched.cuisine_name &&
                                                                        formikBag.errors.cuisine_name
                                                                        ? formikBag.errors.cuisine_name
                                                                        : null
                                                                }
                                                                // className="form-control"
                                                                placeholder="Select Cuisine"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Description</label>
                                                <Field name="description">
                                                    {({ field }) => (
                                                        <div className="py-2">
                                                            <Input
                                                                {...field}
                                                                type="text"
                                                                value={formikBag.values.description}
                                                                onChange={(e) => {
                                                                    formikBag.setFieldValue(
                                                                        "description",
                                                                        e.target.value
                                                                    );
                                                                    setDishFormValues({ ...updateDishFormValues, description: e.target.value })
                                                                }}
                                                                error={
                                                                    formikBag.touched.description &&
                                                                        formikBag.errors.description
                                                                        ? formikBag.errors.description
                                                                        : null
                                                                }
                                                                className="form-control"
                                                                placeholder="Description"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                    </div>
                                    <LabelHeading>{dictionary.menu.Add_Dish_Images}</LabelHeading>
                                    <div className="col-md-12">
                                        <Field name="dish_images">
                                            {({ field }) => (
                                                <div className="py-2">

                                                    <FileInput
                                                        id="dish_images"
                                                        limit="5"
                                                        dictionary="dictionary"
                                                        images={formikBag.values.dish_images}
                                                        onDelete={(image) => {
                                                            var images = [...formikBag.values.dish_images];
                                                            images.splice(images.indexOf(image), 1);
                                                            formikBag.setFieldValue('dish_images', images)
                                                            setDishFormValues({ ...updateDishFormValues, dish_images: images })
                                                        }}
                                                        type="text"
                                                        label="dish_images"
                                                        info="eg_img"
                                                        onChange={async (e) => {
                                                            const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
                                                            if (fileSize > 2) {
                                                                alert("ex_2mb");
                                                                // $(file).val(''); //for clearing with Jquery
                                                            } else {
                                                                setIsLoading(true);
                                                                var image = await uploadImage(e.target.files[0]);
                                                                var images = [...formikBag.values.dish_images];
                                                                images.push(image.path);
                                                                formikBag.setFieldValue('dish_images', images)
                                                                setDishFormValues({ ...updateDishFormValues, dish_images: images })

                                                                setIsLoading(false);
                                                            }
                                                        }}
                                                        error={
                                                            formikBag.touched.dish_images &&
                                                                formikBag.errors.dish_images
                                                                ? formikBag.errors.dish_images
                                                                : null
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                    {/* <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                                       <LabelHeading className="mr-3">Offer Campaign</LabelHeading>
                                        <div>
                                            <Field name="is_offer_campaign">
                                                    {({ field }) => (
                                                        <Switch
                                                        rounded={true}
                                                        // isToggled={offerDishStatus(category.dish_status)}
                                                        bgColorChecked="#07bc0c"
                                                        roundColorChecked="#ffffff"
                                                        border="1px solid #8D8D8D"
                                                        bgColorUnChecked="red"
                                                        roundColorUnChecked="#ffffff"
                                                        onToggle={() => {
                                                            handleDishStatus({
                                                                // _id: category._id,
                                                                // dish_status: category.dish_status
                                                            })
                                                        }}
                            />
                                                    )}
                                                </Field>
                                        </div>
                                    </div> */}

                                    <div style={{display: 'flex', justifyContent: 'between', alignItems: 'center' }}>
                                      <LabelHeading style={{marginRight: '1rem'}}>{dictionary.menu.Offer_Campaign}</LabelHeading>
                                        <Field name="is_offer_campaign">
                                                {({ field }) => (
                                                    <Switch
                                                    rounded={true}
                                                    isToggled={formikBag.values.is_offer_campaign}
                                                    bgColorChecked="#07bc0c"
                                                    roundColorChecked="#ffffff"
                                                    border="1px solid #8D8D8D"
                                                    bgColorUnChecked="red"
                                                    roundColorUnChecked="#ffffff"
                                                    onToggle={() => {
                                                        setDishFormValues({ ...updateDishFormValues, is_offer_campaign: !updateDishFormValues.is_offer_campaign })
                                                    }}
                                                />
                                                )}
                                        </Field>
                                    </div>

                            {updateDishFormValues.is_offer_campaign &&
                                <div className="row">
                                            <div className="col-md-6">
                                                <label>{dictionary.menu.Select_Offer_Type}</label>
                                                <Field name="discount_type">
                                                    {({ field }) => (
                                                        <div className="py-2" style={{ height: "100%", marginTop: "1.2rem" }}>
                                                            <Select
                                                               
                                                                // isMulti
                                                                // className="cm-select"
                                                                defaultValue={!isEmpty(formikBag.values.discount_type) ? { label: discountType(formikBag.values.discount_type), value: formikBag.values.discount_type } : ""}
                                                                options={dataDiscountType}
                                                                onChange={(option) => {
                                                                          setDishFormValues({ ...updateDishFormValues, discount_type: option })
                                                                        }}
                                                                // error={
                                                                //     formikBag.touched.discount_type &&
                                                                //         formikBag.errors.discount_type
                                                                //         ? formikBag.errors.discount_type
                                                                //         : null
                                                                // }
                                                                // className="form-control"
                                                                placeholder="Select Offer Type"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                            <div className="col-md-6">
                                                <label>{dictionary.menu.Discount}</label>
                                                <Field name="discount">
                                                    {({ field }) => (
                                                        <div className="py-3">
                                                            <Input
                                                                {...field}
                                                                type="text"
                                                                value={formikBag.values.discount}
                                                                onChange={(e) => {
                                                                    console.log(formikBag)
                                                                    formikBag.setFieldValue(
                                                                        "discount",
                                                                        e.target.value
                                                                    );
                                                                    setDishFormValues({ ...updateDishFormValues, discount: e.target.value })

                                                                }}
                                                                // error={
                                                                //     formikBag.touched.discount &&
                                                                //         formikBag.errors.discount
                                                                //         ? formikBag.errors.discount
                                                                //         : null
                                                                // }
                                                                className="form-control"
                                                                placeholder="Enter Discount"
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                </div>
                            }

                                    

                                    <div className="row" style={{ marginTop: "1rem", display: "flex", flexDirection: "column" }}>
                                        <div className="col-md-3" style={{ fontSize: "1.4rem" }}>{dictionary.menu.Add_Sizes}</div>
                                        <div style={{ marginTop: "1rem", display: "flex", alignItems: "center" }}>
                                            <div className="col-md-2">
                                                <input type="radio" name="user" checked={updateDishFormValues.radioJump.isMulti} style={{ marginRight: '10px', marginLeft: '10px', }} onClick={() => {
                                                    // setDishFormValues(vals => ({
                                                    //     isFixed: false,
                                                    //     isMulti: true
                                                    // }))
                                                    // setDishFormValues(vals => ({ ...vals, radioJump: {isFixed: false, isMulti: true} }))
                                                    setDishFormValues({ ...formikBag.values, radioJump: { isFixed: false, isMulti: true } })
                                                    // formikBag.values.price_data[0].dish_price == '' && (formikBag.setFieldValue('price_data', [{ serving_size: '', dish_price: formikBag.values.base_price }]))
                                                    formikBag.setFieldValue("radioJump", {
                                                        isFixed: false,
                                                        isMulti: true
                                                    })
                                                    // formikBag.values.price_data[0].dish_price == '' && (formikBag.setFieldValue('price_data', [{ serving_size: '', dish_price: '' }]))
                                                    // setInputList([{ serving_size: "", dish_price: "" }])

                                                    // setInputList(updateDishFormValues.price_data.length == 0 ? [updateDishFormValues.price_data] : inputList)
                                                }}
                                                />
                                                <span style={{ fontSize: '1rem' }}> {dictionary.menu.Multiple_size}
                                                </span>
                                            </div>
                                            <div className="col-md-3">
                                                <input type="radio" name="user" checked={updateDishFormValues.radioJump.isFixed} style={{ marginRight: '10px', marginLeft: '10px', }} onClick={() => {
                                                    //    setDishFormValues(vals => ({
                                                    //     isFixed: true,
                                                    //     isMulti: false
                                                    // }))
                                                    // setDishFormValues(vals => ({ ...vals, radioJump: {isFixed: true, isMulti: false} }))
                                                    setDishFormValues({ ...formikBag.values, radioJump: { isFixed: true, isMulti: false } })

                                                    formikBag.setFieldValue("radioJump", {
                                                        isFixed: true,
                                                        isMulti: false
                                                    })



                                                    // formikBag.setFieldValue("base_price", "");

                                                }}
                                                />
                                                <span style={{ fontSize: '1rem' }}>{dictionary.menu.Fixed_Price}
                                                </span>
                                            </div>

                                        </div>
                                    </div>
                                    {updateDishFormValues.radioJump.isMulti ? (<>
                                        {/* <FieldArray name="price_data">
                                            {updateDishFormValues.price_data.map((x, i) => {
                                                const ticketErrors = errors.tickets?.length && errors.tickets[i] || {};
                                                const ticketTouched = touched.tickets?.length && touched.tickets[i] || {};
                                                return (
                                                    <>
                                                        <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                            <div className="col-md-4">
                                                                <Field name="serving_size">
                                                                    {({ field }) => (
                                                                        <div className="py-3">
                                                                            <FormControl style={{ minWidth: 240 }}>
                                                                                <InputLabel id="demo-simple-select-label">Select Size</InputLabel>
                                                                                <SelectBox
                                                                                    name="serving_size"
                                                                                    value={x.serving_size}
                                                                                    onChange={e => {
                                                                                        handleInputChange(e, i);
                                                                                        formikBag.setFieldValue("serving_size", e.target.value);
                                                                                    }}
                                                                                    error={
                                                                                        formikBag.touched.serving_size &&
                                                                                            formikBag.errors.serving_size
                                                                                            ? formikBag.errors.serving_size
                                                                                            : null
                                                                                    }

                                                                                >
                                                                                    {sizeData.map(val => <MenuItem value={val.size}>{val.size}</MenuItem>
                                                                                    )}
                                                                                </SelectBox>

                                                                                {formikBag.errors.serving_size && (
                                                                                    <p
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            fontSize: 13,
                                                                                            color: "red",
                                                                                        }}
                                                                                    >
                                                                                        {formikBag.errors.serving_size}
                                                                                    </p>
                                                                                )}
                                                                            </FormControl>
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group">
                                                                    <Field name="dish_price">
                                                                        {({ field }) => (
                                                                            <div className="py-3" style={{ marginBottom: "0.8rem" }}>
                                                                                <Input
                                                                                    {...field}
                                                                                    type="number"
                                                                                    min="0"
                                                                                    value={x.dish_price}
                                                                                    onChange={e => {
                                                                                        handleInputChange(e, i);
                                                                                        formikBag.setFieldValue(
                                                                                            "dish_price",
                                                                                            e.target.value
                                                                                        );
                                                                                    }}
                                                                                    error={
                                                                                        formikBag.touched.dish_price &&
                                                                                            formikBag.errors.dish_price
                                                                                            ? formikBag.errors.dish_price
                                                                                            : null
                                                                                    }
                                                                                    className="form-control"
                                                                                    placeholder="Enter Dish Price"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4" style={{ display: "flex", alignItems: "center" }}>
                                                                {updateDishFormValues.price_data.length !== 1 && <AddTrashSizeButton
                                                                    className="mr10"
                                                                    onClick={() => handleRemoveClick(i)}><i class="ph-trash"></i></AddTrashSizeButton>}
                                                                {updateDishFormValues.price_data.length - 1 === i && <AddSizeButton onClick={handleAddClick}>Add More</AddSizeButton>}
                                                            </div>
                                                        </div>

                                                    </>
                                                );
                                            })}
                                        </FieldArray> */}
                                        <FieldArray name="price_data">
                                            {() => (formikBag.values.price_data.map((p, i) => {
                                                // const ticketErrors = errors.tickets?.length && errors.tickets[i] || {};
                                                // const ticketTouched = touched.tickets?.length && touched.tickets[i] || {};
                                                return (
                                                    <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                                        {/* <div className="col-md-4"  >
                                                            <Field name={`price_data.${i}.serving_size`}>
                                                                {({ field }) => (
                                                                    <div className="py-3"  >
                                                                        <FormControl style={{ minWidth: 240 }}>
                                                                            <InputLabel id="demo-simple-select-label">Select Size</InputLabel>
                                                                            
                                                                            <SelectBox
                                                                                {...field}
                                                                                // value={p.serving_size}
                                                                                // onChange={e => {
                                                                                //     // handleInputChange(e, i);
                                                                                //     formikBag.setFieldValue(`price_data.${i}.serving_size`, e.target.value);
                                                                                // }}

                                                                               

                                                                                error={
                                                                                    formikBag.touched.price_data?.length
                                                                                    && formikBag.touched.price_data[i]?.serving_size
                                                                                    && formikBag.errors.price_data?.length
                                                                                    && formikBag.errors.price_data[i]?.serving_size
                                                                                }

                                                                            >
                                                                                {sizeData.map(val => <MenuItem value={val.size}>{val.size}</MenuItem>
                                                                                )}
                                                                            </SelectBox>

                                                                            {!!formikBag.touched.price_data?.length
                                                                                && !!formikBag.touched.price_data[i]?.serving_size
                                                                                && !!formikBag.errors.price_data?.length
                                                                                && !!formikBag.errors.price_data[i]?.serving_size && (
                                                                                    <p
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            fontSize: 13,
                                                                                            color: "red",
                                                                                        }}
                                                                                    >
                                                                                        {formikBag.errors.price_data[i]?.serving_size}
                                                                                    </p>
                                                                                )}
                                                                        </FormControl>
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div> */}

                                                        {
                                                            sizeData.length == 0 ? <div className="col-md-4"  >
                                                                <SelectSize>
                                                                    <p onClick={() => setInfo({
                                                                        isMaster: true
                                                                    })}>{dictionary.menu.Select_Size}</p>
                                                                </SelectSize>
                                                            </div> : <div className="col-md-4"  ><Field name={`price_data.${i}.serving_size`}>
                                                                {({ field }) => (
                                                                    <div className="py-3"  >
                                                                        <FormControl style={{ minWidth: 240 }}>
                                                                            <InputLabel id="demo-simple-select-label">{dictionary.menu.Select_Size}</InputLabel>

                                                                            <SelectBox
                                                                                {...field}
                                                                                // value={p.serving_size}
                                                                                // onChange={e => {
                                                                                //     // handleInputChange(e, i);
                                                                                //     formikBag.setFieldValue(`price_data.${i}.serving_size`, e.target.value);
                                                                                // }}



                                                                                error={
                                                                                    formikBag.touched.price_data?.length
                                                                                    && formikBag.touched.price_data[i]?.serving_size
                                                                                    && formikBag.errors.price_data?.length
                                                                                    && formikBag.errors.price_data[i]?.serving_size
                                                                                }

                                                                            >
                                                                                {sizeData.map(val => <MenuItem value={val.size}>{val.size}</MenuItem>
                                                                                )}
                                                                            </SelectBox>

                                                                            {!!formikBag.touched.price_data?.length
                                                                                && !!formikBag.touched.price_data[i]?.serving_size
                                                                                && !!formikBag.errors.price_data?.length
                                                                                && !!formikBag.errors.price_data[i]?.serving_size && (
                                                                                    <p
                                                                                        style={{
                                                                                            paddingTop: 5,
                                                                                            fontSize: 13,
                                                                                            color: "red",
                                                                                            // position: "absolute"
                                                                                        }}
                                                                                    >
                                                                                        {formikBag.errors.price_data[i]?.serving_size}
                                                                                    </p>
                                                                                )}
                                                                        </FormControl>
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            </div>
                                                        }



                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <Field name={`price_data.${i}.dish_price`}>
                                                                    {({ field }) => (
                                                                        <div className="py-3" >
                                                                            <div style={{ marginBottom: "0.8rem", display: "flex", alignItems: "center" }}>
                                                                            <LabelCur>₺</LabelCur>
                                                                            <Input
                                                                                {...field}
                                                                                type="number"
                                                                                min="0"
                                                                                // value={x.dish_price}
                                                                                onChange={e => {
                                                                                    handleInputChange(e, i);
                                                                                    formikBag.setFieldValue(
                                                                                        `price_data.${i}.dish_price`,
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                // error={
                                                                                //     formikBag.touched.price_data?.length
                                                                                //     && formikBag.touched.price_data[i]?.dish_price
                                                                                //     && formikBag.errors.price_data?.length
                                                                                //     && formikBag.errors.price_data[i]?.dish_price
                                                                                // }
                                                                                className="form-control"
                                                                                placeholder={dictionary.menu.Enter_Dish_Price}
                                                                            />
                                                                            </div>
                                                                            {formikBag.touched.price_data?.length
                                                                                    && formikBag.touched.price_data[i]?.dish_price
                                                                                    && formikBag.errors.price_data?.length
                                                                                    && formikBag.errors.price_data[i]?.dish_price && (
                                                                                    <p
                                                                                        style={{
                                                                                            // paddingTop: 5,
                                                                                            fontSize: 13,
                                                                                            color: "red",
                                                                                            // position: "absolute"
                                                                                        }}
                                                                                    >
                                                                                        {formikBag.errors.price_data[i]?.dish_price}
                                                                                    </p>
                                                                                )}
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4" style={{ display: "flex", alignItems: "center" }}>
                                                            {updateDishFormValues.price_data.length !== 1 && <AddTrashSizeButton type='button'
                                                                className="mr10"
                                                                onClick={() => handleRemoveClick(i, formikBag)}><i class="ph-trash"></i></AddTrashSizeButton>}
                                                            {updateDishFormValues.price_data.length - 1 === i && <AddSizeButton type='button' onClick={() => handleAddClick(formikBag)}>{dictionary.menu.Add_More}</AddSizeButton>}
                                                        </div>
                                                    </div>

                                                );
                                            }))}
                                        </FieldArray>
                                    </>) : ""}
                                    {updateDishFormValues.radioJump.isFixed ? (<>
                                        <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                            <div className="col-md-4">
                                                <Field name="base_price">
                                                    {({ field }) => (
                                                        <div className="py-2">
                                                            <Input
                                                                {...field}
                                                                type="number"
                                                                min="1"
                                                                value={formikBag.values.base_price}
                                                                onChange={(e) => {
                                                                    formikBag.setFieldValue(
                                                                        "base_price",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                error={
                                                                    formikBag.touched.base_price &&
                                                                        formikBag.errors.base_price
                                                                        ? formikBag.errors.base_price
                                                                        : null
                                                                }
                                                                className="form-control"
                                                                placeholder={dictionary.menu.Enter_Price}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </>) : ""}
                                    {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputList)}</div> */}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <HeadingButton type="submit" style={{ margin: " auto" }}>
                                                Add Dish
                                            </HeadingButton>
                                        </div>

                                    </div>

                                </Form>
                            );
                        }}
                    </Formik>
                }
            />

            {/* Menu add */}
            <Modal
                isOpen={info.isMaster}
                className="update_profile"
                onClose={() => {
                    setInfo({
                        isMaster: false
                    });
                }}
                maxWidth='350px'
                title={
                    <div className="modalsign">
                        <div
                            className="closeicon"
                            onClick={() => {
                                setInfo({
                                    isMaster: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div>
                    </div>
                }
                content={
                    <>
                        <div style={{ display: "flex", alignItems: "center", fontSize: "2rem", justifyContent: "center", flexDirection: "column" }}>
                            <div style={{ display: "flex" }}>
                                <i className="ph-plus-circle" color="#6610f2"></i> <h2> Add Size</h2>
                            </div>
                            <div style={{ marginTop: "1.3rem" }}>
                                <Link to="/size-master" style={{ fontSize: "1.3rem", color: "white", backgroundColor: "blue", padding: "0.5rem 0.8rem", borderRadius: "8px" }}>Add</Link>
                            </div>
                        </div>
                    </>
                }
            />

            {isLoading && <Overlay />}

        </>

    )
}


const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        sidebar: state.sidebar,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setSidebar: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_SIDEBAR,
                updateSidebar: updatedValue,
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
