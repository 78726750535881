import styled from 'styled-components'

export const DashboardContainer = styled.div`
    color: #fff;
    background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#FFF9FB')};
    margin-left: 280px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
    
    
    @media screen and (max-width: 780px) {
        margin-left: 0px;
    }
    
    @media screen and (max-width: 480px) {
        margin-left: 0px;
    }
`

export const DashboardWrapper = styled.div`
    display: flex;
    z-index: 1;
    height: 90vh;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
`

export const DashboardHeading = styled.div`
    padding: 0.5rem 2rem;
    margin-top: 1rem;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    width: 100%;


    @media screen and (max-width: 768px) {
        padding: 0.5rem 1.5rem;
        margin-top: 0.4rem;
    }
    
    @media screen and (max-width: 480px) {
        margin-top: 0.2rem;
        padding: 0.3rem 1rem;
    }

    @media screen and (max-width: 320px) {
        margin-top: 0rem;
        padding: 0rem 0rem;
    }
`

export const MenuAndBack = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const DashHeading = styled.div`
    font: normal normal bold 20px/56px Lato;
    letter-spacing: 0.4px;
    color: #000000;
    opacity: 1;
    font-size: 26px;
    text-align: left;


    @media screen and (max-width: 880px) {
        font-size: 22px;
    }

    @media screen and (max-width: 768px) {
        font-size: 20px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
    

`




export const HeadingButton = styled.button`
        box-shadow: 0px 0px 10px #00000030;
        border-radius: 5px;
        opacity: 1;
        margin: 0.4em;
        white-space: nowrap;
        border: none;
        font-weight: ${({ dropDown }) => (dropDown ? '600' : '500')};
        padding: ${({ dropDown }) => (dropDown ? '0.7em 1.6em' : '0.9em 1.3em')};
        background: ${({ dropDown }) => (dropDown ? '#FFFFFF' : 'transparent linear-gradient(90deg, #E42279 0%, #6C1D63 100%) 0% 0% no-repeat padding-box')};
        color: ${({ dropDown }) => (dropDown ? '#000000' : '#FFFFFF')};
        font-size: ${({ dropDown }) => (dropDown ? '1rem' : '1.2rem')};
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: ${({ primary }) => (primary ? 'center' : 'space-between')};
        align-items: center;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        margin-bottom: 1rem;
        width: 100%;


    
        /* &:hover {
            transition: all 0.2s ease-in-out;
            background: ${({ primary }) => (primary ? '#FF4001' : 'transparent')};
            border: 1px solid ${({ primary }) => (primary ? "#FF4001" : "#FFFFFF")};
            color: ${(dark) => (dark ? '#F1F1F1' : '#F1F1F1')};
        } */
    
    
        @media screen and (max-width: 768px) {
            margin: 0.5em;
            margin-left: 0;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.9em 1.3em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '1em')};
        }
    
        @media screen and (max-width: 480px) {
            margin: 0.5em;
            margin-left : 0;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.9em 1.3em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '1em')};
        }
    
    `

export const RejectButton = styled.button`
    box-shadow: 0px 0px 10px #00000030;
    width: 16rem;
    border-radius: 32px;
    opacity: 1;
    margin: 0.4em;
    white-space: nowrap;
    padding: ${({ big }) => (big ? '0.5em 2em' : '0.6em 1.3em')};
    border: none;
    font-weight: 500;
    background: ${({ primary }) => (primary ? '#F9ECFD' : 'transparent linear-gradient(90deg, #E42279 0%, #6C1D63 100%) 0% 0% no-repeat padding-box')};
    color: ${({ primary }) => (primary ? '#000000' : '#FFFFFF')};
    font-size: 1.3rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    /* &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#FF4001' : 'transparent')};
        border: 1px solid ${({ primary }) => (primary ? "#FF4001" : "#FFFFFF")};
        color: ${(dark) => (dark ? '#F1F1F1' : '#F1F1F1')};
    } */

    @media screen and (max-width: 1024px) {
        margin: 0.5em;
        margin-left: 0;
        padding: 5px 10px;
        font-size: 1rem;
    }

    @media screen and (max-width: 768px) {
        margin: 0.5em;
        margin-left: 0;
        padding: ${({ big }) => (big ? '0.5em 2em' : '0.6em 1.5em')};
        font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '0.9em')};
    }

    @media screen and (max-width: 480px) {
        margin: 0.5em;
        margin-left : 0;
        padding: ${({ big }) => (big ? '0.5em 2em' : '0.4em 1.2em')};
        font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '0.8em')};
    }
    
    `

export const AcceptButton = styled.button`
    box-shadow: 0px 0px 10px #00000030;
    width: 16rem;
    border-radius: 32px;
    opacity: 1;
    margin: 0.4em;
    white-space: nowrap;
    padding: ${({ big }) => (big ? '0.5em 2em' : '0.6em 1.3em')};
    border: none;
    font-weight: 500;
    background: ${({ primary }) => (primary ? '#F9ECFD' : 'transparent linear-gradient(90deg, #11BD17 0%, #1E8000 100%) 0% 0% no-repeat padding-box')};
    color: ${({ primary }) => (primary ? '#000000' : '#FFFFFF')};
    font-size: 1.3rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    /* &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#FF4001' : 'transparent')};
        border: 1px solid ${({ primary }) => (primary ? "#FF4001" : "#FFFFFF")};
        color: ${(dark) => (dark ? '#F1F1F1' : '#F1F1F1')};
    } */


    @media screen and (max-width: 768px) {
        margin: 0.5em;
        margin-left: 0;
        padding: ${({ big }) => (big ? '0.5em 2em' : '0.6em 1.5em')};
        font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '0.9em')};
    }

    @media screen and (max-width: 480px) {
        margin: 0.5em;
        margin-left : 0;
        padding: ${({ big }) => (big ? '0.5em 2em' : '0.4em 1.2em')};
        font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '0.8em')};
    }
    
    `

export const CloseButton = styled.button`
box-shadow: 0px 0px 10px #00000030;
width: 16rem;
border-radius: 32px;
opacity: 1;
margin: 0.4em;
white-space: nowrap;
padding: ${({ big }) => (big ? '0.5em 2em' : '0.6em 1.3em')};
border: none;
font-weight: 500;
        background: ${({ dropDown }) => (dropDown ? '#FFFFFF' : 'transparent linear-gradient(90deg, #666666 0%, #333333 100%) 0% 0% no-repeat padding-box')};
        color: ${({ primary }) => (primary ? '#000000' : '#FFFFFF')};
    // font-size: ${({ fontBig }) => (fontBig ? '1.2rem' : '0.95rem')};
    font-size: 1.3rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;


    
        /* &:hover {
            transition: all 0.2s ease-in-out;
            background: ${({ primary }) => (primary ? '#FF4001' : 'transparent')};
            border: 1px solid ${({ primary }) => (primary ? "#FF4001" : "#FFFFFF")};
            color: ${(dark) => (dark ? '#F1F1F1' : '#F1F1F1')};
        } */
    
        @media screen and (max-width: 1024px) {
            margin: 0.5em;
            margin-left: 0;
            padding: 5px 10px;
            font-size: 1rem;
        }
    
        @media screen and (max-width: 768px) {
            margin: 0.5em;
            margin-left: 0;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.9em 1.3em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '1em')};
        }
    
        @media screen and (max-width: 480px) {
            margin: 0.5em;
            margin-left : 0;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.9em 1.3em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '1em')};
        }

        @media screen and (max-width: 425px) {
            margin: 0.5em;
            margin-left: 0;
            padding: 5px 20px;
            font-size: 0.7rem;
        }

        @media screen and (max-width: 425px) {
            margin: 0.5em;
            margin-left: 0;
            padding: 0 10px;
            font-size: 0.7rem;
        }
    
        
    `


export const TelButton = styled.button`
box-shadow: 0px 0px 10px #00000030;
width: 16rem;
border-radius: 32px;
opacity: 1;
margin: 0.4em;
white-space: nowrap;
padding: ${({ big }) => (big ? '0.5em 2em' : '0.6em 1.3em')};
border: none;
font-weight: 500;
        background: ${({ dropDown }) => (dropDown ? '#00D563' : '#00D563')};
        color: ${({ primary }) => (primary ? '#000000' : '#FFFFFF')};
        font-size: 1.3rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;


    
        /* &:hover {
            transition: all 0.2s ease-in-out;
            background: ${({ primary }) => (primary ? '#FF4001' : 'transparent')};
            border: 1px solid ${({ primary }) => (primary ? "#FF4001" : "#FFFFFF")};
            color: ${(dark) => (dark ? '#F1F1F1' : '#F1F1F1')};
        } */
    
    
        @media screen and (max-width: 768px) {
            margin: 0.5em;
            margin-left: 0;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.9em 1.3em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '1em')};
        }
    
        @media screen and (max-width: 480px) {
            margin: 0.5em;
            margin-left : 0;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.9em 1.3em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '1em')};
        }
    
    `

export const SvgLogo = styled.img`
    color: #fff;
    cursor: pointer;
`

export const BackIcon = styled.div`
    color: #000000;
    cursor: pointer;
    padding-right: 5px;
    margin-right: 10px;
    
    @media screen and (max-width: 480px) {
        padding-left: 1rem;
        margin-right: 5px;
    }
`



export const PreperationTime = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
`
export const LabelHeading = styled.div`
    color: #000000;
    text-align: left;
    font-size: 22px;
    padding: 1rem 0rem;
`
export const RetaurantDetailsForm = styled.div`
    padding: 2rem;
    width: 100%;

    @media screen and (max-width: 1000px) {
        padding: 0rem;
        }
    
        @media screen and (max-width: 480px) {
            padding: 0rem;
        }
`
export const InputDivide = styled.div`
    display: flex;
    flex-direction: row;
    
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`
export const MiddleColumnProfile = styled.div`
    padding: 2rem 3rem;
    overflow: scroll;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    @media screen and (max-width: 480px) {
        padding: 2rem 1rem;
    }
`

export const ProfileTime = styled.div`
    display: flex;
    width: 59%;
    justify-content: flex-end;
    align-items: center;
`

export const ProfileDayTime = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const InputPic = styled.img`
    background-color: #00BFFF;
    width: 28px !important;
    height: 24px !important;
    object-fit: none !important;
    margin-left: 4.2rem;
    margin-top: -2rem;
    /* border-radius: 50%; */
    /* margin-bottom: 10px; */
`

export const HeadingBlock = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
`

export const HeadingProfile = styled.div`
    /* display: flex; */
    /* align-items: center; */
    /* flex-direction: column; */
    text-align: left;
    font: normal normal 600 20px/24px Lato;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 100%;
    margin-bottom: 1rem;
`

export const HeadingPara = styled.div`
    /* display: flex;
    align-items: center;
    flex-direction: column; */
    text-align: left;
    font: normal normal normal 17px/30px Lato;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
`

export const MultipleButtons = styled.div`
    padding: 0.5rem 2rem;
    padding-top: 0px;
    margin-top: 1rem;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    width: 100%;


    @media screen and (max-width: 768px) {
        padding: 0.5rem 1.5rem;
        margin-top: 0.4rem;
        width: 100%;
    }
    
    @media screen and (max-width: 480px) {
        margin-top: 0.2rem;
        padding: 0.5rem 1rem;
    }

    @media screen and (max-width: 320px) {
        margin-top: 0rem;
        padding: 0.5rem 1rem;
    }
`

export const TripleButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

`


export const MultipleButton = styled.button`
        box-shadow: 0px 0px 10px #00000030;
        border: 1px solid #D90066;
        border-radius: 2px;
        opacity: 1;
        /* margin: 0.4em; */
        white-space: nowrap;
        /* border: none; */
        font-weight: ${({ dropDown }) => (dropDown ? '600' : '500')};
        padding: ${({ dropDown }) => (dropDown ? '0.7em 1.6em' : '0.3em')};
        background: ${({ selected }) => (selected ? '#D90066' : '#FFF9FB')};
        color: ${({ selected }) => (selected ? '#FFF9FB' : '#D90066')};
        font-size: ${({ dropDown }) => (dropDown ? '1rem' : '1rem')};
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: ${({ primary }) => (primary ? 'center' : 'center')};
        align-items: center;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        margin-bottom: 1rem;
        width: 100%;


    
        /* &:hover {
            transition: all 0.2s ease-in-out;
            background: ${({ primary }) => (primary ? '#FF4001' : 'transparent')};
            border: 1px solid ${({ primary }) => (primary ? "#FF4001" : "#FFFFFF")};
            color: ${(dark) => (dark ? '#F1F1F1' : '#F1F1F1')};
        } */
    
    
        @media screen and (max-width: 768px) {
            /* margin: 0.5em; */
            margin-left: 0;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.3em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '0.9em')};
        }
    
        @media screen and (max-width: 480px) {
            /* margin: 0.5em; */
            margin-left : 0;
            margin-bottom: 0rem;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.3em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '0.7em')};
            margin-bottom: 0.5rem;
        }
        @media screen and (max-width: 320px) {
            /* margin: 0.5em; */
            margin-left : 0;
            margin-bottom: 0rem;
            padding: ${({ big }) => (big ? '0.5em 2em' : '0.2em')};
            font-size: ${({ fontBig }) => (fontBig ? '1.2em' : '0.6em')};
            margin-bottom: 0.3rem;
        }
    
    `


export const VoucherHeading = styled.div`
    padding: 0.5rem 2rem;
    /* margin-top: 1rem; */
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    width: 100%;


    @media screen and (max-width: 768px) {
        padding: 0.5rem 1.5rem;
        /* margin-top: 0.4rem; */
    }
    
    @media screen and (max-width: 480px) {
        /* margin-top: 0.2rem; */
        padding: 0.3rem 0rem;
    }

    @media screen and (max-width: 320px) {
        margin-top: 0rem;
        padding: 0rem 0rem;
    }
`

export const VoucherHeadingMain = styled.div`
    font: normal normal bold 20px/56px Lato;
    letter-spacing: 0.16px;
    color: #1A1A1A;
    opacity: 1;
    font-size: 18px;
    text-align: left;


    @media screen and (max-width: 1050px) {
        font-size: 16px;
    }
    @media screen and (max-width: 950px) {
        font-size: 14px;
    }

    @media screen and (max-width: 880px) {
        font-size: 12px;
    }

    @media screen and (max-width: 768px) {
        font-size: 11px;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 10px;
    }
    @media screen and (max-width: 410px) {
        display: none;
    }
    

`

export const FullWidthMobileInput = styled.div`
    padding: 1rem;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
    
    

`

export const MobileViewCalender = styled.div`
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
    
    

`

export const OfferRadioSection = styled.div`
    padding: 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;


    @media screen and (max-width: 768px) {
        padding: 0.5rem 0.5rem;
    }
    
    @media screen and (max-width: 480px) {
        /* margin-top: 0.2rem; */
        padding: 0.3rem 0rem;
    }

    @media screen and (max-width: 320px) {
        margin-top: 0rem;
        padding: 0rem 0rem;
    }
`

export const OfferSectionLabel = styled.div`
    padding-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: left;
    font: normal normal normal 14px/18px Lato;
    letter-spacing: 0px;
    color: #666666;
    opacity: 1;
    font-size: 18px;


    @media screen and (max-width: 880px) {
        font-size: 18px;
        padding: 0rem 1rem 0rem 0.5rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 18px;
        padding: 0rem 1rem 0rem 0.5rem;
    }
    
    @media screen and (max-width: 480px) {
        padding: 0rem 0rem 0rem 1rem;
        font-size: 16px;
    }
    

`


export const PendingOrder = styled.div`
width: auto;
height: auto;
padding: 15px 0;
margin: auto;
margin: 1rem 1.5rem;
// background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
// background: #FFFFFF 0% 0% no-repeat padding-box;
// background-color: pink;
box-shadow: 0px 0px 20px #0000001A;
border-radius: 5px;
opacity: 1;
    


    @media screen and (max-width: 880px) {
        font-size: 18px;
        padding: 0rem 1rem 0rem 0.5rem;
    }

    @media screen and (max-width: 768px) {
        font-size: 18px;
        padding: 0rem 1rem 0rem 0.5rem;
    }
    
    @media screen and (max-width: 480px) {
        padding: 0rem 0rem 0rem 1rem;
        font-size: 16px;
    }
    
`

export const DisplayF = styled.div`
width: 100%;
display: flex;
// background-color: pink;


@media screen and (max-width: 880px) {
flex-direction: column;
padding: 0rem 1rem 0rem 0.5rem;
}

@media screen and (max-width: 768px) {
    font-size: 18px;
    padding: 0rem 1rem 0rem 0.5rem;
}

@media screen and (max-width: 480px) {
    padding: 0rem 0rem 0rem 1rem;
    font-size: 16px;
}

`

export const PendingOrderTitle = styled.p`
margin-bottom: 1rem;
display: flex;
justify-content: space-between;
width: 22rem;
font-weight: 800;
font-size: 1.5rem;


@media screen and (max-width: 880px) {
    font-size: 25px;
    display: flex;
    justify-content: center;
    width: 100%;
    
}

@media screen and (max-width: 768px) {
    font-size: 25px;
}

@media screen and (max-width: 480px) {
    font-size: 20px;
}
`

export const PendingOrderIns = styled.div`
    font: normal normal bold 20px/56px Lato;
    letter-spacing: 0.4px;
    color: #000000;
    opacity: 1;
    font-size: 19px;


    @media screen and (max-width: 880px) {
        font-size: 22px;
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        font-size: 20px;
        text-align: center;
    }
    
    @media screen and (max-width: 480px) {
        font-size: 17px;
        text-align: center;
    }
    
`

export const PendingOrderDesc = styled.p`
margin-bottom: 1rem;
display: flex;
justify-content: space-between;
width: 35rem;
margin-top: -10px;
font-size: 1rem;


@media screen and (max-width: 1024px) {
    width: 20rem;
    // margin-left: 3rem;

}

@media screen and (max-width: 880px) {
    font-size: 22px;
    display: flex;
    justify-content: center;
    width: 100%;
    
}

@media screen and (max-width: 768px) {
    font-size: 18px;
}

@media screen and (max-width: 480px) {
    font-size: 15px;
    margin-top: 10px;
}
`

export const PendingOrderTime = styled.p`
margin-bottom: 1rem;
display: flex;
width: 20rem;
// background-color: yellow;
align-items: center;
margin-top: 10px;
font-size: 1.2rem;


@media screen and (max-width: 880px) {
    font-size: 22px;
    display: flex;
    justify-content: center;
    width: 100%;
    
}

@media screen and (max-width: 768px) {
    font-size: 18px;
}

@media screen and (max-width: 480px) {
    font-size: 15px;
    margin-top: 10px;
}
`



export const ContentBox = styled.div`
width: 40%;
padding: 0.5rem 1rem;
margin-bottom: 1rem;

@media screen and (max-width: 880px) {
    font-size: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 768px) {
    font-size: 18px;
}

@media screen and (max-width: 480px) {
    // font-size: 16px;
} 
`
export const ListGroup = styled.div`
display: flex;
width: 100%;
justify-content: space-around;

@media screen and (max-width: 1024px) {
    width: 40%;
    // margin-left: 3rem;

}

@media screen and (max-width: 880px) {
    font-size: 18px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    font-size: 18px;
}

@media screen and (max-width: 480px) {
    font-size: 15px;
    width: 100%;
}
@media screen and (max-width: 425px) {
    font-size: 15px;
    width: 100%;
}
@media screen and (max-width: 375px) {
    font-size: 15px;
    width: 100%;
}
@media screen and (max-width: 320px) {
    font-size: 15px;
    width: 100%;
} 
`

export const ButtonCollection = styled.div`
display: flex;
width: 100%;
// background-color: yellow;
height: 60px;
justify-content: center;

@media screen and (max-width: 880px) {
    font-size: 18px;
}

@media screen and (max-width: 768px) {
    font-size: 18px;
}

@media screen and (max-width: 480px) {
    font-size: 15px;
    width: 100%;
} 
`



export const OrderGroup = styled.div`
width: 100%;
display: flex;   
justify-content: space-around;


@media screen and (max-width: 1024px) {
    width: 18rem;
    margin-left: -2rem;

}


@media screen and (max-width: 880px) {
    width: 25rem;
    margin-left: 3rem;

}

@media screen and (max-width: 768px) {
    width: 25rem;
    margin-left: 3rem;
}

@media screen and (max-width: 480px) {
    width: 25rem;
    margin-left: 3rem;
    font-size: 13px;
} 
@media screen and (max-width: 425px) {
    width: 25rem;
    margin-left: 3rem;
    font-size: 13px;
} 
@media screen and (max-width: 375px) {
    width: 18rem;
    margin-left: 0rem;
    font-size: 13px;
} 
@media screen and (max-width: 320px) {
    width: 15rem;
    margin-left: 0rem;
    font-size: 10px;
} 
`

export const OrderGroups = styled.div`
width: 35rem;
display: flex;   
justify-content: space-around;

@media screen and (max-width: 880px) {
    width: 25rem;
    margin-left: 3rem;

}

@media screen and (max-width: 768px) {
    width: 25rem;
    margin-left: 3rem;
}

@media screen and (max-width: 480px) {
    width: 25rem;
    margin-left: 3rem;
    font-size: 13px;
} 
@media screen and (max-width: 425px) {
    width: 25rem;
    margin-left: 3rem;
    font-size: 13px;
} 
@media screen and (max-width: 375px) {
    width: 18rem;
    margin-left: 0rem;
    font-size: 13px;
} 
@media screen and (max-width: 320px) {
    width: 15rem;
    margin-left: 0rem;
    font-size: 10px;
} 
`

export const Counter = styled.div`
// width: 25rem;
height: 5rem;
// background-color: yellow;
display: flex;  
justify-content: center;
align-items: center;
`
export const InputBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgrey;
    font-size: 1.3rem;
border-radius: 5px;
padding: 0 3rem;
`

export const PopUpBox = styled.div`
padding: 45px 24px;
    // background-color: yellow;
    height: 18rem;
    width: 30rem;
    display: flex;
    line-height: 75px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`





export const CrossIcon = styled.div`
    display: flex;
    flex-direction: column;
    margin:auto;
`


export const EmptyBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: auto;
    height: 35rem;
    // background-color: yellow;
`


export const EmptyHeading = styled.div`
height: auto;
width: 100%;
text-align: center;
padding: 11px 0;

`

export const EmptyImage = styled.div`
height: auto;
width: 100%;
display: flex;
justify-content: center;

`



// SidebarHaydii


export const SideBarConBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    // background-color: red;
   
    @media screen and (max-width: 1800px) {
        height: 122%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    } 

    @media screen and (max-width: 1440px) {
        height: 116%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    } 

    @media screen and (max-width: 1024px) {
        height: 126%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    } 
    
    @media screen and (max-width: 768px) {
        height: 130%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    } 

    @media screen and (max-width: 425px) {
        height: 125%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    } 

    @media screen and (max-width: 375px) {
        height: 128%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    } 

    @media screen and (max-width: 375px) {
        height: 130%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    } 
`


export const SideBarBoxes = styled.div`
width: 75%;
height: 100%;
top: 0;
left: 0;
background-color: black;
opacity: 0.3;


@media screen and (max-width: 1440px) {
    height: 100%;
    width: 70%;
    top: 0;
    left: 0;
} 


@media screen and (max-width: 1024px) {
    height: 100%;
    width: 65%;
    top: 0;
    left: 0;
}  

@media screen and (max-width: 768px) {
    height: 100%;
    width: 55%;
    top: 0;
    left: 0;
}  

@media screen and (max-width: 425px) {
    height: 100%;
    width: 40%;
    top: 0;
    left: 0;
} 

@media screen and (max-width: 375px) {
    height: 100%;
    width: 30%;
    top: 0;
    left: 0;
} 
`

export const SidebarBox = styled.div`
background: #ffffff;
width: 25%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
background-color: white;
position: absolute;
top: 0;
right: 0;
bottom: 0;
padding: 15px 20px;
font-size: 1.1rem;
overflow: scroll;

animation: 1s ease-out 0s 1 slideInFromLeft;
    

    @keyframes slideInFromLeft {
        from {left: 2000px;}
        to {left : 1540px;}
      }

      @media screen and (max-width: 1440px) {
        height: 100%;
        width: 30%;
        top: 0;
        right: 0;
    } 

      @media screen and (max-width: 1024px) {
        height: 100%;
        width: 35%;
        top: 0;
        right: 0;
    }  

    @media screen and (max-width: 768px) {
        height: 100%;
        width: 45%;
        top: 0;
        right: 0;
    }  

    @media screen and (max-width: 425px) {
        height: 100%;
        width: 60%;
        top: 0;
        right: 0;
    } 

    @media screen and (max-width: 375px) {
        height: 100%;
        width: 70%;
        top: 0;
        right: 0;
    }  
`

export const BackLogo = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 30px;

    
    @media screen and (max-width: 425px) {
        font-size: 25px;
    }
`

export const CustName = styled.div`
    height: auto;
    width: 100%;
    background-color: 'lightpink';
    display: flex;
    color: #878997;
    justify-content: space-between;
    font-size: 1.1rem;


    @media screen and (max-width: 425px) {
        font-size: 0.9rem;
    }
`

export const CustLoc = styled.div`
    height: auto;
    width: 100%;
    background-color: 'lightpink';
    display: flex;
    font-weight: 600;
    color: black;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;

    @media screen and (max-width: 425px) {
        font-size: 0.9rem;
    }
`

export const CustLocs = styled.div`
    height: auto;
    width: 100%;
    background-color: 'lightpink';
    display: flex;
    font-weight: 600;
    color: black;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    @media screen and (max-width: 425px) {
        font-size: 0.9rem;
    }
`

export const CustLocation = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    color: #878997;
    font-size: 1.1rem;

    @media screen and (max-width: 425px) {
        font-size: 0.8rem;
    }
`
export const OrderBox = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    line-height: 30px;
    font-size: 1.1rem;
    flex-direction: column;
`
export const OrderBoxList = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 425px) {
        font-size: 0.7rem;
    }
`

export const ItemPrice = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 425px) {
        font-size: 0.8rem;
    }
`

export const OrderBoxDetails = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
`

export const OrderBoxDetailsImage = styled.div`
    height: auto;
    width: auto;
    display: flex;
    background-color: green;
`

export const OrderBoxDetailsImg = styled.img`
    height: 80px;
    width: 80px;
`


export const OrderBoxDetailsCon = styled.div`
    height: 100%;
    width: auto;
    display: flex;
    // margin-left: 2rem;
    flex-direction: column;
    line-height: 25px;
`
//Same for payemnt CustName

//Same for delNote CustLoc

// Same for Loc

// Same for Location

// Same for Loc

// Same for Location

// Same for OrderPrice as CustName

// Same for OrderPrice as CustName

export const DelInfo = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 425px) {
        flex-direction: column;
        justify-content: center;
        padding: 10px 0;
    }    
`

export const DelBoy = styled.div`
    height: auto;
    width: 80%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 425px) {
        text-align: center;
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }
`

export const DelBoyImg = styled.div`
    height: auto;
    width: auto;
    margin-left: 2rem;

    @media screen and (max-width: 425px) {
        margin-left: 0rem;
    }
`

export const DelBoyImgage = styled.img`
    height: 50px;
    width: 50px;
    background-color: pink;
`

// Same for OrderPrice as CustName





// Past Order

export const PastBox = styled.div`
    height: auto;
    widht: 100%;
    display: flex;
    justify-content: space-around;
    margin-left: 2rem;
    box-shadow: 0px 0px 20px #0000001A;
    border-radius: 5px;
    opacity: 1;
    margin: 1rem 1.5rem;


    @media screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
        margin: auto;
    }
`

export const PastFirst = styled.div`
    height: auto;
    width: 50%;
    justify-content: space-between;
    display: flex;
    // background-color: yellow;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`

export const PastSecond = styled.div`
    height: auto;
    width: 50%;
    display: flex;
    justify-content: space-around;
    
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`

export const ButtonAccept = styled.button`
box-shadow: 0px 0px 10px #00000030;
margin: 0.4em;
background: #00C77D 0% 0% no-repeat padding-box;
color: #FFFFFF;
border-radius: 2px;
opacity: 1;
border: none;
font-weight: 500;
font-size: 1.2rem;
outline: none;
text-decoration: none;

`

export const PopTitle = styled.div`
text-align: center;
font-weight: 600;
letter-spacing: 0px;
color: #000000;
opacity: 1;
font-size: 1.2rem;
`

export const PopDesc = styled.div`
text-align: center;
color: #808080;
opacity: 1;
font-size: 1.1rem;
height: auto;
width: 400px;
margin-bottom: 1rem;
`

export const DetailTitle = styled.p`
    font-size: 2rem;
    font-weight: 600;
    margin-top: -2rem;
`

export const DetailBox = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
`
export const DetailRow = styled.div`
    height: auto;
    width: 100%;
    line-height: 25px;
    display: flex;
    justify-content: space-between;
`
export const DetailPopBox = styled.div`
padding: 45px 24px;
    // background-color: yellow;
    height: 12rem;
    width: 30rem;
    border-radius: 15px;
    display: flex;
    line-height: 75px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`

