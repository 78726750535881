import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import get from "lodash/get";
import Lottie from "react-lottie";
import Overlay from "../../../components/Overlay";
import axios from "../../../axios";
import ReactHtmlParser from "react-html-parser";
import Icon1 from "../../../images/CarrotImages/aron-visuals-BXOXnQ26B7o-unsplash.jpg";

import AssociateLady from "../../../images/CarrotSvg/AssociateLady.png";
import CarrotAssociate from "../../../images/CarrotSvg/CarrotAssociate.svg";
import { PageHeadingRed } from "../../WebsitePages/AllStores/AllStoresElements";

import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  NavBtnLink,
  ChairSvg,
  SignWelcome,
  WelcomeDesign,
  CarrotButton,
  ButtonInside,
  TrapeziumContent,
  TrapeziumLeft,
  TrapeziumRight,
  SignInSection,
  WelcomeRow,
  EmailPswdSection,
  LabelInput,
  InputLabel,
  // CarrotInput,
  ChairSvgSection,
  AllPlanCards,
  PlanCard,
  CardHeadingRow,
  CardHeading,
  CardPointsSection,
  CardPointRow,
  CardPoint,
  BuySection,
  CardPrice,
  FreeImage,
  WelcomeSection,
  WrapSection,
} from "../../LoginPage/LoginElements";

import {
  AllBlogsData,
  ImgBgDot,
  WorksCard,
  IconBackground,
  WorksIcon,
  WorksH6,
  PlanCardsSection,
  DetailBlogSection,
  SingleBlog,
  OtherBlog,
  OtherSingleBlog,
  BlogImage,
  BlogData,
  OtherBlogTitle,
  OtherBlogDescription,
  BlogHeadingRow,
  BlogHeadingText,
  InnerContent,
  BlogImageBig,
  HeroContentBlog,
  WorksP,
} from "./BlogsElements";
import LexicalEditor from "../../../LexicalEditor/index";


const HeroSection = ({ defaultState, setDefaultState, setUsers }) => {
  const navigate = useNavigate();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [otherBlogs, setOtherBlogs] = useState([]);

  useEffect(() => {
    getBlogDetail();
    window.scrollTo(0, 0);
  }, [params.id]);

  const getBlogDetail = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/user/get_blog_by_id?blog_id=${params.id}`);
      setBlogData(data.data);
      setOtherBlogs(data.remaining_data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.errors) {
        toast.error(`${error.response.data.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${error?.response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const customDescription = (e) => {
    if (e.length <= 75) {
      return e;
    } else {
      let text = e.slice(0, 75) + " <b>...Read more</b> ";
      return text;
    }
  };
  console.log(blogData);

  return (
    <>
      <HeroContainer style={{ height: "100%" }}>
        <HeroBg>{/*<ImgBgDot src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />*/}</HeroBg>
        <HeroContentBlog className="container">
          <SignWelcome>
            <BlogHeadingRow >
              <BlogHeadingText style={{width: "65%"}}>{blogData?.title}</BlogHeadingText>
            </BlogHeadingRow>
            <DetailBlogSection>
              <SingleBlog>
                <BlogImageBig src={blogData.image} />
                {/* <InnerContent dangerouslySetInnerHTML={{ __html: blogData?.short_description }}></InnerContent> */}
                <LexicalEditor
                            initialEditorState={blogData?.details_description}
                            key={blogData?.details_description}
                          />
              </SingleBlog>

              <OtherBlog>
                {otherBlogs.length > 0 ? (
                  <>
                    {otherBlogs.map((item, index) => (
                      <>
                        <OtherSingleBlog
                          onClick={() => {
                            navigate(`/blog/${item._id}`);
                          }}
                        >
                          <BlogImage src={item.image}></BlogImage>
                          <BlogData>
                            <OtherBlogTitle>
                              {item.title.slice(0, 35)}
                              {item.title.length > 35 ? " ..." : ""}
                            </OtherBlogTitle>
                            <OtherBlogDescription>
                              <InnerContent
                                dangerouslySetInnerHTML={{ __html: customDescription(item?.short_description) }}
                              ></InnerContent>
                            </OtherBlogDescription>
                          </BlogData>
                        </OtherSingleBlog>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </OtherBlog>
            </DetailBlogSection>
          </SignWelcome>
        </HeroContentBlog>
      </HeroContainer>
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
