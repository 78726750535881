import React, { useState, useEffect, useContext } from 'react'
import { DashboardContainer, DashboardWrapper, DashboardHeading, DashHeading, SvgLogo, BackIcon, MenuAndBack, PreperationTime, LabelHeading, RetaurantDetailsForm, InputDivide, MiddleColumnProfile, InputPic, HeadingBlock, HeadingProfile, HeadingPara, MultipleButtons, TripleButton, MultipleButton, VoucherHeading, VoucherHeadingMain, FullWidthMobileInput, OfferRadioSection, OfferSectionLabel, MobileViewCalender, PendingOrder, RejectButton, ContentBox, AcceptButton, TelButton, PendingOrderTitle, CloseButton, OrderGroup, PendingOrderDesc, PopUpBox, CrossIcon, Counter, DisplayF, ListGroup, EmptyImage, EmptyBox, EmptyHeading, PendingOrderIns, PendingOrderTime, ButtonCollection, SidebarBox, SideBarConBox, BackLogo, CustName, CustLoc, CustLocation, OrderBox, OrderBoxList, OrderBoxDetails, OrderBoxDetailsImage, OrderBoxDetailsCon, DelBoy, DelBoyImg, DelInfo, OrderBoxDetailsImg, DelBoyImgage, SideBarBoxes, CustLocs, ItemPrice, PastBox, PastFirst, PastSecond, InputBox, OrderGroups, PopTitle, DetailTitle, DetailBox, DetailRow, ButtonAccept, DetailPopBox } from './Order_ManagementElements'
import { LoginButton } from '../LoginSection/LoginElements';
import { HeadingButton } from '../Dashboard/DashboardElements'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '../Modal/Modal'
import { Formik, Field, Form } from "formik";
import Timer from 'react-compound-timer'
import Input from "../Input";
import YearInput from '../YearInput';
import { extractDate } from "../../utils/functions";
import axios from "../../axios";
import Overlay from '../Overlay'
import { toast } from "react-toastify";
import EditIcon from "../../images/edit_profile_button_table.png"
import DeleteIcon from "../../images/delete_profile_button_table.png"
import EmptyIcon from "../../images/Mask_Group.png"
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import * as IoIcons from 'react-icons/io';
import * as HiIcons from 'react-icons/hi';
import { get, lastIndexOf } from 'lodash';
import classNames from 'classnames';
import {
    reasonDescValidatior
} from "../../utils/validators";


import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import SearchBar from '../SearchBar/Search'
import {
    offerValidator,
} from "../../utils/validators";
import { Button, Checkbox, List, ListItem, TextField, Typography } from '@mui/material';
// import './OfferStyles.css';

import closeIcon from "../../images/close_popup.png"
import pizzaIcon from "../../images/NoPathCopy(2)@2x.png"
import readyIcon from "../../images/alert.png"
import { display } from '@mui/system';
import { LanguageContext } from "../../utils/language/Language";

const useStyles = makeStyles((theme) => ({

    textMiddle: {
        verticalAlign: 'middle !important',
        textAlign: "center"
    },
    tablePadding: {
        padding: "0.5rem",
        textAlign: "center",
        fontSize: "0.8rem"
    },
    paperTableHeight: {
        height: "650px",
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },

    paperTableHeightNested: {
        height: "auto",
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        overflow: 'scroll',
        justifyContent: "space-between",
        flexDirection: "column"
    },
    "@media (max-width: 880px)": {
        paperTableHeightNested: {
            width: "89%",
        }
    },
    "@media (max-width: 780px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        },
        paperTableHeightNested: {
            width: "92%",
        }
    },
    "@media (max-width: 768px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        },
        paperTableHeightNested: {
            width: "100%",
        }
    },
    "@media (max-width: 480px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        },
        paperTableHeightNested: {
            width: "85%",
        }
    },
    tablePaginationStyle: {
        border: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden"
    },
    tableFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    // backColor: {
    //     backgroundColor: 'yellow'
    // }
}));





const OfferManagement = ({ history, setUsers, userData, }) => {
    const classes = useStyles();

    const { dictionary } = useContext(LanguageContext);

    const [defaultState, setDefaultState] = useState({ isReject: false, isAccept: false, isReason: false, isReady: false, isOrderPickUp: false, isDetails: false, isDriverDetails: false })
    const [orders, setOrders] = useState({ liveOrder: true, pastOrder: false, canceledOrder: false })
    const [ordersMenu, setOrdersMenu] = useState({ livePendingOrder: true, livePreparingOrder: false, liveReadyOrder: false, liveOngoingOrder: false, liveScheduledOrder: false })
    const [isLoading, setIsLoading] = useState(false);
    const [ordersData, setOrdersData] = useState([])
    const [orderDelivered, setOrderDelivered] = useState([])
    const [orderCancelled, setOrderCancelled] = useState([])
    const [pushMessage, setPushMessage] = useState("");
    const [userDetail, setUserDetail] = useState([]);


    useEffect(() => {
        getOrders();
        getOrdersPreparing();
        getOrdersReady();
        getOrdersWay();
        getOrderDelivered();
        getOrderCancelled();
        // getNotification();

    }, []);
    const getOrders = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_all_orders?status=PENDING");
            setOrdersData(data.data);
            console.log(data.data)
        } catch (error) {
            console.log(error);
        }
    };

    // useEffect(() => {
    //     setInterval(() => {
    //     getNotification();
    //   }, 1000);
    // })


    const getNotification = async () => {

        try {
            const { data } = await axios.get("/restaurant/getMyNotifications",
                {
                    headers: {
                        language: "en"
                    }
                });
            // setOrdersData(data.data);
            console.log(data.data)
            setPushMessage(data.data[0].body);
            toast.success(`${data.data[0].body}`, {
                position: toast.POSITION.TOP_RIGHT,
            });

            if (data.data.unread_notifications_count > 0) {
                toast.success(`${data.data[0].body}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else {
                return ""
            }
        } catch (error) {
            console.log(error);
        }
    };

    console.log(pushMessage);


    const [ordersDataPreparing, setOrdersPreparing] = useState([]);

    const getOrdersPreparing = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_all_orders?status=PREPARING");
            setOrdersPreparing(data.data);
            console.log(data.data)
        } catch (error) {
            console.log(error);
        }
    };

    const [ordersDataReady, setOrdersReady] = useState([]);

    const getOrdersReady = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_all_orders?status=READY");
            setOrdersReady(data.data);
            console.log(data.data);
        } catch (error) {
            console.log(error);
            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        // setTimeout(() => {
        //     getOrdersReady();
        // }, 3000);
    };

    const [ordersDataWay, setOrdersDataWay] = useState([]);

    const getOrdersWay = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_all_orders?status=ORDER_PICKED_UP");
            setOrdersDataWay(data.data);
            console.log(data.data)
        } catch (error) {
            console.log(error);
            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        // setTimeout(() => {
        //     getOrdersWay();
        // }, 3000);
    };

    const getOrderDelivered = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_all_orders?status=DELIVERED");
            setOrderDelivered(data.data);
            console.log(data.data)
        } catch (error) {
            console.log(error);
            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const getOrderCancelled = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_all_orders?status=REJECTEDBYRESTAURANT");
            setOrderCancelled(data.data);
            console.log(data.data)
        } catch (error) {
            console.log(error);
            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };




    console.log(ordersData);

    const [reasonFormValues, setReasonFormValues] = useState({
        reasonDesc: '',
    })

    const [getId, setGetId] = useState("")

    console.log(getId);

    const handleRejectOrders = async (values) => {

        console.log(values)

        var fromData = {
            _id: getId,
            status: "REJECTEDBYRESTAURANT",
            rejection_reason: values.reasonDesc,
        };
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isReason: false,
            };
        });

        console.log(fromData);
        try {
            if (values.reasonDesc) {
                const { data } = await axios.post("/restaurant/order_accept_reject", fromData);
                console.log(data)
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getOrders();
                getOrdersPreparing();
                getOrdersReady();
                getOrdersWay();
            }

        } catch (error) {
            setIsLoading(false);
            setDefaultState((prevState) => {
                return {
                    ...prevState,
                    isReason: false,
                };
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    const handleAcceptOrders = async () => {

        var fromData = {
            _id: getId,
            status: "ACCEPTEDBYRESTAURANT",
            preparation_time: time.toString(),
        };
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isAccept: false,
            };
        });

        // console.log(fromData);
        try {
            const { data } = await axios.post("/restaurant/order_accept_reject", fromData);
            toast.success(`${data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
            getOrders();
            getOrdersPreparing();
            getOrdersReady();
            setOrdersMenu({
                livePendingOrder: false,
                livePreparingOrder: true,
                liveReadyOrder: false,
                liveOngoingOrder: false,
            });

        } catch (error) {
            setIsLoading(false);
            setDefaultState((prevState) => {
                return {
                    ...prevState,
                    isAccept: false,
                };
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    const handleReadyOrders = async () => {

        var fromData = {
            _id: getId,
            status: "READY",
            // preparation_time: time.toString(),
        };
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isReady: false,
            };
        });

        // console.log(fromData);
        try {
            const { data } = await axios.post("/restaurant/update_order_status", fromData);
            toast.success(`${data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
            getOrders();
            getOrdersPreparing();
            getOrdersReady();
            getOrdersWay();
            setOrdersMenu({
                livePendingOrder: false,
                livePreparingOrder: false,
                liveReadyOrder: true,
                liveOngoingOrder: false,
            });
        } catch (error) {
            setIsLoading(false);
            setDefaultState((prevState) => {
                return {
                    ...prevState,
                    isReady: false,
                };
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const handleOrderPickUpOrders = async () => {

        var fromData = {
            _id: getId,
            status: "DELIVERED",
            // preparation_time: time.toString(),
        };
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isOrderPickUp: false,
            };
        });

        // console.log(fromData);
        try {
            const { data } = await axios.post("/restaurant/update_order_status", fromData);
            toast.success(`${data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setOrders({
                liveOrder: false,
                pastOrder: true,
                canceledOrder: false
            });
            setIsLoading(false);
            getOrders();
            getOrdersPreparing();
            getOrdersReady();
            getOrderDelivered();
        } catch (error) {
            setIsLoading(false);
            setDefaultState((prevState) => {
                return {
                    ...prevState,
                    isOrderPickUp: false,
                };
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    const Rejection = [
        "Shutting Time", "Delivery Guys is not available", "Your area is not around 5KM"
    ]

    const [time, setTime] = useState(15)
    const [timeError, setTimeError] = useState("")
    const [timeErrors, setTimeErrors] = useState("")


    const CounterInc = () => {
        // if (time >= 30) {
        //     setTimeErrors("Don't enter more than 30 Minutes");
        // }
        // else {
        //     setTimeError("")
        //     setTime(time + 1)
        // }
        setTime(time + 1)
        setTimeError("")
    }

    const CounterNum = () => {
        if (time <= 0) {
            setTimeError("Don't enter less than 0 Minutes");
        }
        else {
            setTime(time - 1)
        }
    }

    const [sideBar, setSideBar] = useState(false)




    const [orderDatas, setOrderDatas] = useState([])

    const open = (val) => {
        setSideBar(sideBar === true ? false : true)
    }

    console.log(orderDatas);

    const getServiceType = (e) => {
        if (e == "1") {
            return "Food Delivery"
        }
        else if (e == "2") {
            return "Self Pickup"
        }
        else {
            return ""
        }
    }

    const getPayemntType = (e) => {
        if (e == "1") {
            return "Online"
        }
        else if (e == "2") {
            return "Cash on delivery"
        }
        else {
            return ""
        }
    }

    return (
        <>
            <div>
                <DashboardContainer>
                    <DashboardWrapper>
                        <DashboardHeading>
                            <MenuAndBack>
                                <DashHeading className={classes.backColor}>
                                    {orders.liveOrder ? dictionary.orders.Order_Management: ""}
                                    {orders.pastOrder ? dictionary.orders.Order_Management : ""}
                                    {orders.canceledOrder ? dictionary.orders.Order_Management : ""}
                                </DashHeading>
                            </MenuAndBack>
                            <>
                                {/* <SearchBar /> */}
                                <div></div>
                            </>
                        </DashboardHeading>
                        {(orders.liveOrder || orders.pastOrder || orders.canceledOrder) ? (
                            <>
                                <MultipleButtons className={classes.backColor}>
                                    <TripleButton>
                                        <MultipleButton
                                            selected={orders.liveOrder}
                                            onClick={() => {
                                                setOrders({
                                                    liveOrder: true,
                                                    pastOrder: false,
                                                    canceledOrder: false
                                                });
                                                getOrders();
                                                getOrdersPreparing();
                                                getOrdersReady();
                                                getOrdersWay();
                                                getOrderDelivered();
                                                getOrderCancelled();
                                            }}
                                        >
                                            {dictionary.orders.Live_Order}
                                        </MultipleButton>
                                        <MultipleButton
                                            selected={orders.pastOrder}
                                            onClick={() => {
                                                setOrders({
                                                    liveOrder: false,
                                                    pastOrder: true,
                                                    canceledOrder: false
                                                });
                                                getOrders();
                                                getOrdersPreparing();
                                                getOrdersReady();
                                                getOrdersWay();
                                                getOrderDelivered();
                                                getOrderCancelled();
                                            }}>
                                            {dictionary.orders.Past_Order}
                                        </MultipleButton>
                                        <MultipleButton
                                            selected={orders.canceledOrder}
                                            onClick={() => {
                                                setOrders({
                                                    liveOrder: false,
                                                    pastOrder: false,
                                                    canceledOrder: true
                                                });
                                                getOrders();
                                                getOrdersPreparing();
                                                getOrdersReady();
                                                getOrdersWay();
                                                getOrderDelivered();
                                                getOrderCancelled();
                                            }}>
                                             {dictionary.orders.Cancelled_Order}
                                        </MultipleButton>
                                    </TripleButton>
                                </MultipleButtons>
                            </>
                        ) : ""}

                        {orders.liveOrder ? (<Paper className={classes.paperTableHeightNested}>

                            {(ordersMenu.livePendingOrder || ordersMenu.livePreparingOrder || ordersMenu.liveReadyOrder || ordersMenu.liveOngoingOrder || ordersMenu.liveScheduledOrder) ? (
                                <>
                                    <MultipleButtons className={classes.backColor}>
                                        <TripleButton>
                                            <MultipleButton
                                                selected={ordersMenu.livePendingOrder}
                                                onClick={() => {
                                                    setOrdersMenu({
                                                        livePendingOrder: true,
                                                        livePreparingOrder: false,
                                                        liveReadyOrder: false,
                                                        liveOngoingOrder: false,
                                                    });
                                                    getOrders();
                                                }}
                                            >
                                                 {dictionary.orders.Pending}
                                            </MultipleButton>
                                            <MultipleButton
                                                selected={ordersMenu.livePreparingOrder}
                                                onClick={() => {
                                                    setOrdersMenu({
                                                        livePendingOrder: false,
                                                        livePreparingOrder: true,
                                                        liveReadyOrder: false,
                                                        liveOngoingOrder: false,
                                                    });
                                                    getOrdersPreparing();

                                                }}>
                                                Preparing
                                            </MultipleButton>
                                            <MultipleButton
                                                selected={ordersMenu.liveReadyOrder}
                                                onClick={() => {
                                                    setOrdersMenu({
                                                        livePendingOrder: false,
                                                        livePreparingOrder: false,
                                                        liveReadyOrder: true,
                                                        liveOngoingOrder: false,
                                                    });
                                                    getOrdersReady();
                                                }}>
                                                {dictionary.orders.Ready}
                                            </MultipleButton>
                                            <MultipleButton
                                                selected={ordersMenu.liveOngoingOrder}
                                                onClick={() => {
                                                    setOrdersMenu({
                                                        livePendingOrder: false,
                                                        livePreparingOrder: false,
                                                        liveReadyOrder: false,
                                                        liveOngoingOrder: true,
                                                    });
                                                    getOrdersWay();
                                                }}>
                                                {dictionary.orders.Ongoing}
                                            </MultipleButton>
                                        </TripleButton>
                                    </MultipleButtons>
                                </>
                            ) : ""}
                            <Paper>
                                {ordersMenu.livePendingOrder ? (
                                    <>
                                        {ordersData[0] ? <>
                                            {
                                                ordersData.map((val, ind) => {
                                                    const { order_id, order_amount, item_orderd, preparation_time, _id, updatedAt, createdAt, payment_method, cooking_instructions, service_type } = val;
                                                    return (
                                                        <PendingOrder key={_id}>
                                                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.8rem' }}> */}
                                                            <DisplayF>
                                                                {/* <div style={{backgroudColor: 'red'}}> */}
                                                                <ContentBox>
                                                                    <PendingOrderTitle>You have a new order
                                                                    </PendingOrderTitle>
                                                                    <PendingOrderIns>Cooking Instructions</PendingOrderIns>
                                                                    <PendingOrderDesc>{cooking_instructions}</PendingOrderDesc>
                                                                </ContentBox>
                                                                {/* </div> */}
                                                                {/* <div style={{ width: '100%', display: 'flex' }}> */}
                                                                <ListGroup>

                                                                    <List style={{ width: "100%" }}>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order ID</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {order_id}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Time</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {moment(updatedAt).format('h:mm a')}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Item</ListItem>
                                                                            <ListItem style={{ fontWeight: '600', flexWrap: 'wrap' }} >:{item_orderd.map((val, ind) => <p key={ind}>{val.item_id.dish_name + ` (${val.price_obj.serving_size == "default_size" ? "" : `${val.price_obj.serving_size} x`} ${val.selected_quantity} )`}{ind < (item_orderd.length - 1) ? <span style={{marginRight: "5px"}}>,</span> : ''}</p>)}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Price</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: ₺{order_amount}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}> {dictionary.orders.Payment_Method}</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {getPayemntType(payment_method)}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order Type</ListItem>
                                                                            <ListItem style={{ fontWeight: '600', color: '#007AFF' }}>: {getServiceType(service_type)}</ListItem>
                                                                        </OrderGroup>
                                                                    </List>
                                                                    {/* </div> */}
                                                                </ListGroup>
                                                            </DisplayF>
                                                            {/* </div> */}
                                                            <ButtonCollection>
                                                                <RejectButton
                                                                    onClick={() => {
                                                                        setGetId(val._id);
                                                                        setDefaultState({
                                                                            isReject: true
                                                                        });
                                                                    }} >
                                                                    {"Reject"}
                                                                </RejectButton>
                                                                <AcceptButton
                                                                    onClick={() => {
                                                                        setGetId(val._id);
                                                                        setDefaultState({
                                                                            isAccept: true
                                                                        });
                                                                    }}
                                                                >
                                                                    {`Accept`}
                                                                </AcceptButton>
                                                            </ButtonCollection>
                                                        </PendingOrder>
                                                    )
                                                })
                                            }
                                        </> : <EmptyBox>
                                            <EmptyHeading>
                                                <h2 style={{ color: '#2F4858' }}>{dictionary.orders.No_Pending_Dish}!</h2>
                                            </EmptyHeading>
                                            <EmptyImage>
                                                <img width="240" height="auto" src={EmptyIcon} />
                                            </EmptyImage>

                                        </EmptyBox>
                                        }
                                    </>
                                ) : ""}
                                {ordersMenu.livePreparingOrder ? (
                                    <>
                                        {ordersDataPreparing[0] ? <>
                                            {
                                                ordersDataPreparing.map((val, ind) => {
                                                    const { order_id, order_amount, item_orderd, preparation_time, _id, updatedAt, createdAt, accepted_at, payment_method, cooking_instructions, service_type } = val;
                                                    return (
                                                        <PendingOrder key={_id}>
                                                            <DisplayF>
                                                                {/* <div style={{backgroudColor: 'red'}}> */}
                                                                <ContentBox>
                                                                    <PendingOrderTitle style={{ color: '#F5A624' }}>{dictionary.orders.Preparing}
                                                                    </PendingOrderTitle>
                                                                    <PendingOrderTime><i className="fa fa-clock-o" style={{ fontSize: '23px', marginRight: '10px' }}></i> Food Prepration Time<span style={{ marginLeft: '20px', fontWeight: '600' }}>:  {preparation_time} Min.</span></PendingOrderTime>
                                                                    {/* <PendingOrderTime><i className="fa fa-clock-o" style={{ fontSize: '23px', marginRight: '10px' }}></i> Food Prepration Time<span style={{ marginLeft: '20px', fontWeight: '600' }}>
                                                                        <Timer
                                                                            initialTime={`0${preparation_time * 10000}`}
                                                                            direction="backward"
                                                                        >
                                                                            {() => (
                                                                                <React.Fragment>
                                                                                    <Timer.Hours />:
                                                                                    <Timer.Minutes formatValue={value => `${(value < 10 ? `0${value}` : value)}`} />:
                                                                                    <Timer.Seconds formatValue={value => `${(value < 10 ? `0${value}` : value)}`}/>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </Timer>
                                                                    </span></PendingOrderTime> */}
                                                                    <PendingOrderIns>{dictionary.orders.Cooking_Instructions}</PendingOrderIns>
                                                                    <PendingOrderDesc>{cooking_instructions}</PendingOrderDesc>
                                                                </ContentBox>
                                                                {/* </div> */}
                                                                <ListGroup>

                                                                    <List style={{ width: "100%" }}>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order ID</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {order_id}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Time</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {moment(updatedAt).format('h:mm a')}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Item</ListItem>
                                                                            <ListItem style={{ fontWeight: '600', flexWrap: 'wrap' }} >:{item_orderd.map((val, ind) => <p key={ind}>{val.item_id.dish_name + ` (${val.price_obj.serving_size == "default_size" ? "" : `${val.price_obj.serving_size} x`} ${val.selected_quantity} )`}{ind < (item_orderd.length - 1) ? <span style={{marginRight: "5px"}}>,</span> : ''}</p>)}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Price</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: ₺{order_amount}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}> {dictionary.orders.Payment_Method}</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {getPayemntType(payment_method)}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order Type</ListItem>
                                                                            <ListItem style={{ fontWeight: '600', color: '#007AFF' }}>: {getServiceType(service_type)}</ListItem>
                                                                        </OrderGroup>
                                                                    </List>
                                                                </ListGroup>
                                                            </DisplayF>
                                                            <ButtonCollection>
                                                                <RejectButton
                                                                    onClick={() => {
                                                                        setGetId(_id);
                                                                        setDefaultState({
                                                                            isReady: true
                                                                        });
                                                                    }}
                                                                >
                                                                    {"Mark Ready"}
                                                                </RejectButton>
                                                                <CloseButton
                                                                    onClick={() => {
                                                                        setDefaultState({
                                                                            isDetails: true
                                                                        });
                                                                        setUserDetail(val)
                                                                    }}
                                                                >
                                                                    {`Contact User`}
                                                                </CloseButton>
                                                            </ButtonCollection>
                                                        </PendingOrder>
                                                    )
                                                })
                                            }
                                        </> : <EmptyBox>
                                            <EmptyHeading>
                                                <h2 style={{ color: '#2F4858' }}>{dictionary.orders.No_Preparing_Dish}</h2>
                                            </EmptyHeading>
                                            <EmptyImage>
                                                <img width="240" height="auto" src={EmptyIcon} />
                                            </EmptyImage>

                                        </EmptyBox>}
                                    </>

                                ) : ""}
                                {ordersMenu.liveReadyOrder ? (
                                    <>
                                        {ordersDataReady[0] ? <>
                                            {
                                                ordersDataReady.map((val, ind) => {
                                                    const { order_id, order_amount, item_orderd, preparation_time, _id, updatedAt, createdAt, payment_method, cooking_instructions, service_type } = val;
                                                    return (
                                                        <PendingOrder key={_id}>
                                                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.8rem' }}> */}
                                                            <DisplayF>
                                                                {/* <div style={{backgroudColor: 'red'}}> */}
                                                                <ContentBox>
                                                                    <PendingOrderTitle style={{ color: '#F5A624' }}>Ready
                                                                    </PendingOrderTitle>
                                                                    {
                                                                        service_type == "1" ? <PendingOrderTime><i className="fa fa-clock-o" style={{ fontSize: '23px', marginRight: '10px' }}></i> Delivery Boy Arriving<span style={{ marginLeft: '20px', fontWeight: '600' }}>:  15 Min.</span></PendingOrderTime> : ""
                                                                    }
                                                                    <PendingOrderIns>{dictionary.orders.Cooking_Instructions}</PendingOrderIns>
                                                                    <PendingOrderDesc>{cooking_instructions}</PendingOrderDesc>
                                                                </ContentBox>
                                                                {/* </div> */}
                                                                <ListGroup>

                                                                    <List style={{ width: "100%" }}>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order ID</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {order_id}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Time</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {moment(updatedAt).format('h:mm a')}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Item</ListItem>
                                                                            <ListItem style={{ fontWeight: '600', flexWrap: 'wrap' }} >:{item_orderd.map((val, ind) => <p key={ind}>{val.item_id.dish_name + ` (${val.price_obj.serving_size == "default_size" ? "" : `${val.price_obj.serving_size} x`} ${val.selected_quantity} )`}{ind < (item_orderd.length - 1) ? <span style={{marginRight: "5px"}}>,</span> : ''}</p>)}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Price</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: ₺{order_amount}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Payment Method</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {getPayemntType(payment_method)}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order Type</ListItem>
                                                                            <ListItem style={{ fontWeight: '600', color: '#007AFF' }}>: {getServiceType(service_type)}</ListItem>
                                                                        </OrderGroup>
                                                                    </List>
                                                                </ListGroup>
                                                            </DisplayF>
                                                            <ButtonCollection>
                                                                {
                                                                    service_type == "2" ? <RejectButton
                                                                        onClick={() => {
                                                                            setDefaultState({
                                                                                isOrderPickUp: true
                                                                            });
                                                                            setGetId(_id);
                                                                        }}
                                                                    >
                                                                        {"Delivered"}
                                                                    </RejectButton> : ""
                                                                }
                                                                <CloseButton
                                                                    onClick={() => {
                                                                        setDefaultState({
                                                                            isDetails: true
                                                                        });
                                                                        setUserDetail(val)
                                                                    }}
                                                                >
                                                                    {`Contact User`}
                                                                </CloseButton>
                                                            </ButtonCollection>
                                                        </PendingOrder>
                                                    )
                                                })
                                            }
                                        </> : <EmptyBox>
                                            <EmptyHeading>
                                                <h2 style={{ color: '#2F4858' }}>{dictionary.orders.No_Ready_Dish}!</h2>
                                            </EmptyHeading>
                                            <EmptyImage>
                                                <img width="240" height="auto" src={EmptyIcon} />
                                            </EmptyImage>

                                        </EmptyBox>}
                                    </>
                                ) : ""}
                                {ordersMenu.liveOngoingOrder ? (
                                    <>
                                        {ordersDataWay[0] ? <>
                                            {
                                                ordersDataWay.map((val, ind) => {
                                                    const { order_id, order_amount, item_orderd, preparation_time, _id, updatedAt, createdAt, accepted_at, payment_method, cooking_instructions, service_type } = val;
                                                    return (
                                                        <PendingOrder key={_id}>
                                                            <DisplayF>
                                                                {/* <div style={{backgroudColor: 'red'}}> */}
                                                                <ContentBox>
                                                                    <PendingOrderTitle style={{ color: '#F5A624' }}>Ongoing
                                                                    </PendingOrderTitle>
                                                                    <PendingOrderTime><i className="fa fa-clock-o" style={{ fontSize: '23px', marginRight: '10px' }}></i> Food Prepration Time<span style={{ marginLeft: '20px', fontWeight: '600' }}>:  {preparation_time} Min.</span></PendingOrderTime>
                                                                    <PendingOrderIns>Cooking Instructions</PendingOrderIns>
                                                                    <PendingOrderDesc>{cooking_instructions}</PendingOrderDesc>
                                                                </ContentBox>
                                                                {/* </div> */}
                                                                <ListGroup>

                                                                    <List style={{ width: "100%" }}>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>{dictionary.orders.Order_ID}</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {order_id}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>{dictionary.orders.Time}</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {moment(updatedAt).format('h:mm a')}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>{dictionary.orders.Item}</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }} >:{item_orderd.map((val, ind) => <p key={ind}>{val.item_id.dish_name + ` (${val.selected_quantity})`}{ind < (item_orderd.length - 1) ? <span style={{marginRight: "5px"}}>,</span> : ''}</p>)}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}> {dictionary.orders.Price}</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: ₺{order_amount}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>{dictionary.orders.Payment_Method}</ListItem>
                                                                            <ListItem style={{ fontWeight: '600' }}>: {getPayemntType(payment_method)}</ListItem>
                                                                        </OrderGroup>
                                                                        <OrderGroup>
                                                                            <ListItem style={{ fontWeight: '600', color: '#878997' }}>{dictionary.orders.Order_Type}</ListItem>
                                                                            <ListItem style={{ fontWeight: '600', color: '#007AFF' }}>: {getServiceType(service_type)}</ListItem>
                                                                        </OrderGroup>
                                                                    </List>
                                                                </ListGroup>
                                                            </DisplayF>
                                                            <ButtonCollection>
                                                                <RejectButton
                                                                    onClick={() => {
                                                                        open();
                                                                        setOrderDatas(val)
                                                                    }
                                                                    }
                                                                >
                                                                    {"View Details"}
                                                                </RejectButton>
                                                                <CloseButton
                                                                    onClick={() => {
                                                                        setDefaultState({
                                                                            isDetails: true
                                                                        });
                                                                        setUserDetail(val)
                                                                    }}
                                                                >
                                                                    {`Contact User`}
                                                                </CloseButton>
                                                            </ButtonCollection>
                                                        </PendingOrder>
                                                    )
                                                })
                                            }
                                            <SideBarConBox style={{ display: sideBar === true ? 'block' : 'none' }} animate={true}>
                                                <SideBarBoxes onClick={() => setSideBar(sideBar === true ? false : true)}></SideBarBoxes>
                                                <SidebarBox >
                                                    {/* <SideBarConBox> */}
                                                    <BackLogo className="mt-5" onClick={() => setSideBar(sideBar === true ? false : true)}><i className="fa fa-arrow-left"></i></BackLogo>
                                                    <CustName>
                                                        <p>Customer Name</p>
                                                        <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "user_id.first_name", "")} {get(orderDatas, "user_id.last_name", "")}</p>
                                                    </CustName>
                                                    <hr style={{ backgroundColor: 'lightgray' }} />
                                                    <CustLoc>{dictionary.orders.Location}</CustLoc>
                                                    <CustLocation>{get(orderDatas, "restaurant_id.restaurant_location", "")}</CustLocation>
                                                    <hr style={{ backgroundColor: 'lightgray' }} />
                                                    <OrderBox>
                                                        <OrderBoxList>
                                                            <p style={{ color: '#878997' }}>Order Id</p>
                                                            <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "order_id", "")}</p>
                                                        </OrderBoxList>
                                                        <OrderBoxList>
                                                            <p style={{ color: '#878997' }}> {dictionary.orders.Date_Time}</p>
                                                            <p style={{ fontWeight: '600', color: 'black' }}>{moment(get(orderDatas, "updatedAt", "")).format('ll')} | {moment(get(orderDatas, "updatedAt", "")).format('h:mm a')}</p>
                                                        </OrderBoxList>
                                                        <OrderBoxList>
                                                            <p style={{ color: '#878997' }}>{dictionary.orders.Order_Status}</p>
                                                            <p style={{ fontWeight: '600', color: 'black' }}>Order Picked Up</p>
                                                        </OrderBoxList>
                                                        <OrderBoxList>
                                                            <p style={{ color: '#878997' }}>Order Type</p>
                                                            <p style={{ fontWeight: '600', color: '#007AFF' }}>Food Delivery</p>
                                                        </OrderBoxList>
                                                    </OrderBox>
                                                    <hr style={{ backgroundColor: 'lightgray' }} />
                                                    <OrderBoxDetails>
                                                        {/* <OrderBoxDetailsImage>
                                                            <OrderBoxDetailsImg src={pizzaIcon} />
                                                        </OrderBoxDetailsImage> */}
                                                        <OrderBoxDetailsCon>
                                                            <CustLoc>{get(orderDatas, "item_orderd[0].item_id.dish_name", "")} {get(orderDatas, "item_orderd[0].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[0].price_obj.serving_size", "") + ")"}</CustLoc>
                                                            <ItemPrice style={{ color: '#878997' }}>₺{get(orderDatas, "item_orderd[0].item_unit_price", "")} (X{get(orderDatas, "item_orderd[0].selected_quantity", "")})</ItemPrice>
                                                        </OrderBoxDetailsCon>
                                                        {!!get(orderDatas, "item_orderd[1].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                            <CustLoc>{get(orderDatas, "item_orderd[1].item_id.dish_name", "")} {get(orderDatas, "item_orderd[1].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[1].price_obj.serving_size", "") + ")"}</CustLoc>
                                                            <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 1 && '₺' + get(orderDatas, "item_orderd[1].item_unit_price", "")} {orderDatas.item_orderd?.length > 1 && '(X' + get(orderDatas, "item_orderd[1].selected_quantity", "") + ")"}</ItemPrice>
                                                        </OrderBoxDetailsCon>}
                                                        {!!get(orderDatas, "item_orderd[2].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                            <CustLoc>{get(orderDatas, "item_orderd[2].item_id.dish_name", "")} {get(orderDatas, "item_orderd[2].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[2].price_obj.serving_size", "") + ")"}</CustLoc>
                                                            <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 2 && '₺' + get(orderDatas, "item_orderd[2].item_unit_price", "")} {orderDatas.item_orderd?.length > 2 && '(X' + get(orderDatas, "item_orderd[2].selected_quantity", "") + ")"}</ItemPrice>
                                                        </OrderBoxDetailsCon>}
                                                        {!!get(orderDatas, "item_orderd[3].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                            <CustLoc>{get(orderDatas, "item_orderd[3].item_id.dish_name", "")} {get(orderDatas, "item_orderd[3].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[3].price_obj.serving_size", "") + ")"}</CustLoc>
                                                            <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 2 && '₺' + get(orderDatas, "item_orderd[3].item_unit_price", "")} {orderDatas.item_orderd?.length > 2 && '(X' + get(orderDatas, "item_orderd[3].selected_quantity", "") + ")"}</ItemPrice>
                                                        </OrderBoxDetailsCon>}
                                                    </OrderBoxDetails>
                                                    <hr style={{ backgroundColor: 'lightgray' }} />
                                                    <CustName>
                                                        <p>Payment Mode</p>
                                                        <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "payment_method", "")}</p>
                                                    </CustName>
                                                    <CustLocs>Delivery Note</CustLocs>
                                                    <CustLocation>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</CustLocation>
                                                    <CustLocs>{dictionary.orders.Cooking_Instructions}</CustLocs>
                                                    <CustLocation>{get(orderDatas, "cooking_instructions", "")}</CustLocation>
                                                    <hr style={{ backgroundColor: 'lightgray' }} />
                                                    <CustName>
                                                        <p style={{ fontWeight: '400', color: 'black' }}>Order Price</p>
                                                        <p style={{ fontWeight: '600', color: 'black' }}>₺{get(orderDatas, "order_amount", "")}</p>
                                                    </CustName>
                                                    <hr style={{ backgroundColor: 'lightgray' }} />
                                                    <DelInfo>
                                                        <DelBoy>
                                                            <p style={{ fontWeight: '400', color: 'black' }}>{dictionary.orders.Delivery_Boy}</p>
                                                            <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "driver.first_name", "")} {get(orderDatas, "driver.last_name", "")}</p>
                                                        </DelBoy>
                                                        <DelBoyImg>
                                                            <DelBoyImgage src={pizzaIcon} />
                                                        </DelBoyImg>
                                                    </DelInfo>
                                                    <hr style={{ backgroundColor: 'lightgray' }} />
                                                    <CustName>
                                                        <p style={{ fontWeight: '400', color: 'black' }}>Status</p>
                                                        <p style={{ fontWeight: '600', color: 'green' }}>{get(orderDatas, "order_status", "")}</p>
                                                    </CustName>
                                                    <hr style={{ backgroundColor: 'lightgray' }} />
                                                    <ButtonCollection>
                                                        <RejectButton style={{ height: '3.5rem' }}
                                                            onClick={() => {
                                                                setDefaultState({
                                                                    isDriverDetails: true
                                                                });
                                                                setUserDetail(orderDatas)
                                                            }}
                                                        >
                                                            Call for Delivery Boy <i className="fa fa-phone" style={{ marginTop: '5px', marginLeft: '10px' }} aria-hidden="true"></i>
                                                        </RejectButton>

                                                    </ButtonCollection>
                                                    {/* </SideBarConBox> */}
                                                </SidebarBox>
                                            </SideBarConBox>
                                        </> : <EmptyBox>
                                            <EmptyHeading>
                                                <h2 style={{ color: '#2F4858' }}>No Preparing Dish!</h2>
                                            </EmptyHeading>
                                            <EmptyImage>
                                                <img width="240" height="auto" src={EmptyIcon} />
                                            </EmptyImage>

                                        </EmptyBox>}
                                    </>
                                ) : ""}
                            </Paper>
                        </Paper>)

                            : ""}

                        {orders.pastOrder ? (
                            <>
                                <Paper className={classes.paperTableHeightNested} style={{ flexDirection: 'column;' }}>
                                    {
                                        orderDelivered.map((val, ind) => {
                                            const { order_id, order_amount, item_orderd, preparation_time, _id, updatedAt, createdAt, accepted_at, payment_method, service_type } = val;
                                            return (
                                                <PastBox key={ind}>
                                                    <PastFirst>
                                                        {/* <ListGroup> */}

                                                        <List style={{ width: "100%" }}>
                                                            <OrderGroups>
                                                                <ListItem style={{ fontWeight: '600', color: '#878997' }}>{dictionary.orders.Order_Id}</ListItem>
                                                                <ListItem style={{ fontWeight: '600' }}>: {order_id}</ListItem>
                                                            </OrderGroups>
                                                            <OrderGroups>
                                                                <ListItem style={{ fontWeight: '600', color: '#878997' }}>Time</ListItem>
                                                                <ListItem style={{ fontWeight: '600' }}>: {moment(updatedAt).format('h:mm a')}</ListItem>
                                                            </OrderGroups>
                                                            <OrderGroups>
                                                                <ListItem style={{ fontWeight: '600', color: '#878997' }}>Item</ListItem>
                                                                <ListItem style={{ fontWeight: '600', flexWrap: 'wrap' }} >:{item_orderd.map((val, ind) => <p key={ind}>{val.item_id.dish_name + ` (${val.price_obj.serving_size == "default_size" ? "" : `${val.price_obj.serving_size} x`} ${val.selected_quantity} )`}{ind < (item_orderd.length - 1) ? ' , ' : ''}</p>)}</ListItem>
                                                            </OrderGroups>
                                                            <OrderGroups>
                                                                <ListItem style={{ fontWeight: '600', color: '#878997' }}>{dictionary.orders.Price}</ListItem>
                                                                <ListItem style={{ fontWeight: '600' }}>: ₺{order_amount}</ListItem>
                                                            </OrderGroups>
                                                            <OrderGroups>
                                                                <ListItem style={{ fontWeight: '600', color: '#878997' }}> {dictionary.orders.Payment_Method}</ListItem>
                                                                <ListItem style={{ fontWeight: '600' }}>: {getPayemntType(payment_method)}</ListItem>
                                                            </OrderGroups>
                                                            <OrderGroups>
                                                                <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order Type</ListItem>
                                                                <ListItem style={{ fontWeight: '600', color: '#007AFF' }}>: {getServiceType(service_type)}</ListItem>
                                                            </OrderGroups>
                                                        </List>
                                                        {/* </ListGroup> */}
                                                    </PastFirst>
                                                    <PastSecond>
                                                        <RejectButton style={{ fontSize: '1.1rem', margin: 'auto' }}
                                                            onClick={() => {
                                                                open();
                                                                setOrderDatas(val)
                                                            }}>

                                                       {dictionary.orders.More_Info}
                                                        </RejectButton>
                                                        {/* <CloseButton style={{ fontSize: '1.1rem', margin: 'auto', height: '3rem', width: '15rem' }}
                                                            onClick={() => {
                                                                // setDefaultState({
                                                                //     isAccept: true
                                                                // });
                                                            }}
                                                        >
                                                            {`Help`}
                                                        </CloseButton> */}
                                                    </PastSecond>
                                                </PastBox>
                                            )
                                        })
                                    }
                                    <SideBarConBox style={{ display: sideBar === true ? 'block' : 'none' }} animate={true}>
                                        <SideBarBoxes onClick={() => setSideBar(sideBar === true ? false : true)}></SideBarBoxes>
                                        <SidebarBox >
                                            {/* <SideBarConBox> */}
                                            <BackLogo className="mt-5" onClick={() => setSideBar(sideBar === true ? false : true)}><i className="fa fa-arrow-left"></i></BackLogo>
                                            <CustName>
                                                <p> {dictionary.orders.Customer_Name}</p>
                                                <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "user_id.first_name", "")} {get(orderDatas, "user_id.last_name", "")}</p>
                                            </CustName>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <CustLoc>Location</CustLoc>
                                            <CustLocation>{get(orderDatas, "restaurant_id.restaurant_location", "")}</CustLocation>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <OrderBox>
                                                <OrderBoxList>
                                                    <p style={{ color: '#878997' }}>{dictionary.orders.Order_Id}</p>
                                                    <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "order_id", "")}</p>
                                                </OrderBoxList>
                                                <OrderBoxList>
                                                    <p style={{ color: '#878997' }}>{dictionary.orders.Date_Time}</p>
                                                    <p style={{ fontWeight: '600', color: 'black' }}>{moment(get(orderDatas, "updatedAt", "")).format('ll')} | {moment(get(orderDatas, "updatedAt", "")).format('h:mm a')}</p>
                                                </OrderBoxList>
                                                <OrderBoxList>
                                                    <p style={{ color: '#878997' }}>{dictionary.orders.Order_Status}</p>
                                                    <p style={{ fontWeight: '600', color: 'black' }}>Order Picked Up</p>
                                                </OrderBoxList>
                                                <OrderBoxList>
                                                    <p style={{ color: '#878997' }}>Order Type</p>
                                                    <p style={{ fontWeight: '600', color: '#007AFF' }}>{getServiceType(get(orderDatas, "service_type", ""))}</p>
                                                </OrderBoxList>
                                            </OrderBox>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <OrderBoxDetails>
                                                {/* <OrderBoxDetailsImage>
                                                            <OrderBoxDetailsImg src={pizzaIcon} />
                                                        </OrderBoxDetailsImage> */}
                                                <OrderBoxDetailsCon>
                                                    <CustLoc>{get(orderDatas, "item_orderd[0].item_id.dish_name", "")} {get(orderDatas, "item_orderd[0].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[0].price_obj.serving_size", "") + ")"}</CustLoc>
                                                    <ItemPrice style={{ color: '#878997' }}>₺{get(orderDatas, "item_orderd[0].item_unit_price", "")} (X{get(orderDatas, "item_orderd[0].selected_quantity", "")})</ItemPrice>
                                                </OrderBoxDetailsCon>
                                                {!!get(orderDatas, "item_orderd[1].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                    <CustLoc>{get(orderDatas, "item_orderd[1].item_id.dish_name", "")} {get(orderDatas, "item_orderd[1].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[1].price_obj.serving_size", "") + ")"}</CustLoc>
                                                    <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 1 && '₺' + get(orderDatas, "item_orderd[1].item_unit_price", "")} {orderDatas.item_orderd?.length > 1 && '(X' + get(orderDatas, "item_orderd[1].selected_quantity", "") + ")"}</ItemPrice>
                                                </OrderBoxDetailsCon>}
                                                {!!get(orderDatas, "item_orderd[2].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                    <CustLoc>{get(orderDatas, "item_orderd[2].item_id.dish_name", "")} {get(orderDatas, "item_orderd[2].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[2].price_obj.serving_size", "") + ")"}</CustLoc>
                                                    <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 2 && '₺' + get(orderDatas, "item_orderd[2].item_unit_price", "")} {orderDatas.item_orderd?.length > 2 && '(X' + get(orderDatas, "item_orderd[2].selected_quantity", "") + ")"}</ItemPrice>
                                                </OrderBoxDetailsCon>}
                                                {!!get(orderDatas, "item_orderd[3].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                    <CustLoc>{get(orderDatas, "item_orderd[3].item_id.dish_name", "")} {get(orderDatas, "item_orderd[3].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[3].price_obj.serving_size", "") + ")"}</CustLoc>
                                                    <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 2 && '₺' + get(orderDatas, "item_orderd[3].item_unit_price", "")} {orderDatas.item_orderd?.length > 2 && '(X' + get(orderDatas, "item_orderd[3].selected_quantity", "") + ")"}</ItemPrice>
                                                </OrderBoxDetailsCon>}
                                            </OrderBoxDetails>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <CustName>
                                                <p>{dictionary.orders.Payment_Mode}</p>
                                                <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "payment_method", "")}</p>
                                            </CustName>
                                            {/* <CustLocs>Delivery Note</CustLocs>
                                            <CustLocation>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</CustLocation> */}
                                            <CustLocs>{dictionary.orders.Cooking_Instructions}</CustLocs>
                                            <CustLocation>{get(orderDatas, "cooking_instructions", "")}</CustLocation>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <CustName>
                                                <p style={{ fontWeight: '400', color: 'black' }}>Order Price</p>
                                                <p style={{ fontWeight: '600', color: 'black' }}>₺{get(orderDatas, "order_amount", "")}</p>
                                            </CustName>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <DelInfo>
                                                <DelBoy>
                                                    <p style={{ fontWeight: '400', color: 'black' }}>{dictionary.orders.Delivery_Boy}</p>
                                                    <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "driver.first_name", "")} {get(orderDatas, "driver.last_name", "")}</p>
                                                </DelBoy>
                                                <DelBoyImg>
                                                    <DelBoyImgage src={pizzaIcon} />
                                                </DelBoyImg>
                                            </DelInfo>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <CustName>
                                                <p style={{ fontWeight: '400', color: 'black' }}>Status</p>
                                                <p style={{ fontWeight: '600', color: 'green' }}>{get(orderDatas, "order_status", "")}</p>
                                            </CustName>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            {/* <ButtonCollection>
                                                <CloseButton style={{ height: '3.5rem' }}
                                                // onClick={() => {
                                                //     setDefaultState({
                                                //         isAccept: true
                                                //     });
                                                // }}
                                                >
                                                    {`Help`}
                                                </CloseButton>
                                                <RejectButton style={{ height: '3.5rem' }}
                                                >
                                                    Call for Delivery Boy <i className="fa fa-phone" style={{ marginTop: '5px', marginLeft: '10px' }} aria-hidden="true"></i>
                                                </RejectButton>

                                            </ButtonCollection> */}
                                            {/* </SideBarConBox> */}
                                        </SidebarBox>
                                    </SideBarConBox>
                                </Paper>
                            </>
                        ) : ""}
                        {orders.canceledOrder ? (
                            <>
                                <Paper className={classes.paperTableHeightNested} style={{ flexDirection: 'column;' }}>
                                    {
                                        orderCancelled.map((val, ind) => {
                                            const { order_id, order_amount, item_orderd, preparation_time, _id, updatedAt, createdAt, accepted_at, payment_method, service_type, rejection_reason } = val;
                                            return (
                                                <PastBox key={ind}>
                                                    <PastFirst>
                                                        <ListGroup style={{ justifyContent: "left", width: "100%" }}>

                                                            <List>
                                                                <OrderGroups>
                                                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order ID</ListItem>
                                                                    <ListItem style={{ fontWeight: '600' }}>: {order_id}</ListItem>
                                                                </OrderGroups>
                                                                <OrderGroups>
                                                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Time</ListItem>
                                                                    <ListItem style={{ fontWeight: '600' }}>: {moment(updatedAt).format('h:mm a')}</ListItem>
                                                                </OrderGroups>
                                                                <OrderGroups>
                                                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Item</ListItem>
                                                                    <ListItem style={{ fontWeight: '600', flexWrap: 'wrap' }} >:{item_orderd.map((val, ind) => <p key={ind}>{val.item_id.dish_name + ` (${val.price_obj.serving_size == "default_size" ? "" : `${val.price_obj.serving_size} x`} ${val.selected_quantity} )`}{ind < (item_orderd.length - 1) ? ' , ' : ''}</p>)}</ListItem>
                                                                </OrderGroups>
                                                                <OrderGroups>
                                                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Price</ListItem>
                                                                    <ListItem style={{ fontWeight: '600' }}>: ₺{order_amount}</ListItem>
                                                                </OrderGroups>
                                                                <OrderGroups>
                                                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}> {dictionary.orders.Payment_Method}</ListItem>
                                                                    <ListItem style={{ fontWeight: '600' }}>: {getPayemntType(payment_method)}</ListItem>
                                                                </OrderGroups>
                                                                <OrderGroups>
                                                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Order Type</ListItem>
                                                                    <ListItem style={{ fontWeight: '600', color: '#007AFF' }}>: {getServiceType(service_type)}</ListItem>
                                                                </OrderGroups>
                                                                <OrderGroups>
                                                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Cancellation Reason</ListItem>
                                                                    <ListItem style={{ fontWeight: '600' }}>: {rejection_reason}</ListItem>
                                                                </OrderGroups>
                                                            </List>
                                                        </ListGroup>
                                                    </PastFirst>
                                                    <PastSecond>
                                                        <RejectButton style={{ fontSize: '1.1rem', margin: 'auto' }}
                                                            onClick={() => {
                                                                open();
                                                                setOrderDatas(val)
                                                            }}>

                                                         {dictionary.orders.More_Info}
                                                        </RejectButton>
                                                        {/* <CloseButton style={{ fontSize: '1.1rem', margin: 'auto', height: '3rem', width: '15rem' }}
                                                            onClick={() => {
                                                                // setDefaultState({
                                                                //     isAccept: true
                                                                // });
                                                            }}
                                                        >
                                                            {`Help`}
                                                        </CloseButton> */}
                                                    </PastSecond>
                                                </PastBox>
                                            )
                                        })
                                    }
                                    <SideBarConBox style={{ display: sideBar === true ? 'block' : 'none' }} animate={true}>
                                        <SideBarBoxes onClick={() => setSideBar(sideBar === true ? false : true)}></SideBarBoxes>
                                        <SidebarBox >
                                            {/* <SideBarConBox> */}
                                            <BackLogo className="mt-5" onClick={() => setSideBar(sideBar === true ? false : true)}><i className="fa fa-arrow-left"></i></BackLogo>
                                            <CustName>
                                                <p>{dictionary.orders.Customer_Name}</p>
                                                <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "user_id.first_name", "")} {get(orderDatas, "user_id.last_name", "")}</p>
                                            </CustName>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <CustLoc>{dictionary.orders.Location}</CustLoc>
                                            <CustLocation>{get(orderDatas, "restaurant_id.restaurant_location", "")}</CustLocation>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <OrderBox>
                                                <OrderBoxList>
                                                    <p style={{ color: '#878997' }}>{dictionary.orders.Order_Id}</p>
                                                    <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "order_id", "")}</p>
                                                </OrderBoxList>
                                                <OrderBoxList>
                                                    <p style={{ color: '#878997' }}>{dictionary.orders.Date_Time}</p>
                                                    <p style={{ fontWeight: '600', color: 'black' }}>{moment(get(orderDatas, "updatedAt", "")).format('ll')} | {moment(get(orderDatas, "updatedAt", "")).format('h:mm a')}</p>
                                                </OrderBoxList>
                                                <OrderBoxList>
                                                    <p style={{ color: '#878997' }}>{dictionary.orders.Order_Status}</p>
                                                    <p style={{ fontWeight: '600', color: 'black' }}>Order Picked Up</p>
                                                </OrderBoxList>
                                                <OrderBoxList>
                                                    <p style={{ color: '#878997' }}>{dictionary.orders.Order_Type}</p>
                                                    <p style={{ fontWeight: '600', color: '#007AFF' }}>{getServiceType(get(orderDatas, "service_type", ""))}</p>
                                                </OrderBoxList>
                                            </OrderBox>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <OrderBoxDetails>
                                                {/* <OrderBoxDetailsImage>
                                                            <OrderBoxDetailsImg src={pizzaIcon} />
                                                        </OrderBoxDetailsImage> */}
                                                <OrderBoxDetailsCon>
                                                    <CustLoc>{get(orderDatas, "item_orderd[0].item_id.dish_name", "")} {get(orderDatas, "item_orderd[0].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[0].price_obj.serving_size", "") + ")"}</CustLoc>
                                                    <ItemPrice style={{ color: '#878997' }}>₺{get(orderDatas, "item_orderd[0].item_unit_price", "")} (X{get(orderDatas, "item_orderd[0].selected_quantity", "")})</ItemPrice>
                                                </OrderBoxDetailsCon>
                                                {!!get(orderDatas, "item_orderd[1].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                    <CustLoc>{get(orderDatas, "item_orderd[1].item_id.dish_name", "")} {get(orderDatas, "item_orderd[1].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[1].price_obj.serving_size", "") + ")"}</CustLoc>
                                                    <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 1 && '₺' + get(orderDatas, "item_orderd[1].item_unit_price", "")} {orderDatas.item_orderd?.length > 1 && '(X' + get(orderDatas, "item_orderd[1].selected_quantity", "") + ")"}</ItemPrice>
                                                </OrderBoxDetailsCon>}
                                                {!!get(orderDatas, "item_orderd[2].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                    <CustLoc>{get(orderDatas, "item_orderd[2].item_id.dish_name", "")} {get(orderDatas, "item_orderd[2].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[2].price_obj.serving_size", "") + ")"}</CustLoc>
                                                    <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 2 && '₺' + get(orderDatas, "item_orderd[2].item_unit_price", "")} {orderDatas.item_orderd?.length > 2 && '(X' + get(orderDatas, "item_orderd[2].selected_quantity", "") + ")"}</ItemPrice>
                                                </OrderBoxDetailsCon>}
                                                {!!get(orderDatas, "item_orderd[3].item_id.dish_name", "") && <OrderBoxDetailsCon>
                                                    <CustLoc>{get(orderDatas, "item_orderd[3].item_id.dish_name", "")} {get(orderDatas, "item_orderd[3].price_obj.serving_size", "") === "default_size" ? "" : '(' + get(orderDatas, "item_orderd[3].price_obj.serving_size", "") + ")"}</CustLoc>
                                                    <ItemPrice style={{ color: '#878997' }}>{orderDatas.item_orderd?.length > 2 && '₺' + get(orderDatas, "item_orderd[3].item_unit_price", "")} {orderDatas.item_orderd?.length > 2 && '(X' + get(orderDatas, "item_orderd[3].selected_quantity", "") + ")"}</ItemPrice>
                                                </OrderBoxDetailsCon>}
                                            </OrderBoxDetails>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <CustName>
                                                <p>{dictionary.orders.Payment_Mode}</p>
                                                <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "payment_method", "")}</p>
                                            </CustName>
                                            <CustLocs>Delivery Note</CustLocs>
                                            <CustLocation>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</CustLocation>
                                            <CustLocs>Cooking Instructions</CustLocs>
                                            <CustLocation>{get(orderDatas, "cooking_instructions", "")}</CustLocation>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <CustName>
                                                <p style={{ fontWeight: '400', color: 'black' }}>Order Price</p>
                                                <p style={{ fontWeight: '600', color: 'black' }}>₺{get(orderDatas, "order_amount", "")}</p>
                                            </CustName>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <DelInfo>
                                                <DelBoy>
                                                    <p style={{ fontWeight: '400', color: 'black' }}>{dictionary.orders.Delivery_Boy}</p>
                                                    <p style={{ fontWeight: '600', color: 'black' }}>{get(orderDatas, "driver.first_name", "")} {get(orderDatas, "driver.last_name", "")}</p>
                                                </DelBoy>
                                                <DelBoyImg>
                                                    <DelBoyImgage src={pizzaIcon} />
                                                </DelBoyImg>
                                            </DelInfo>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            <CustName>
                                                <p style={{ fontWeight: '400', color: 'black' }}>Status</p>
                                                <p style={{ fontWeight: '600', color: 'green' }}>{get(orderDatas, "order_status", "")}</p>
                                            </CustName>
                                            <hr style={{ backgroundColor: 'lightgray' }} />
                                            {/* <ButtonCollection>
                                                <CloseButton style={{ height: '3.5rem' }}
                                                // onClick={() => {
                                                //     setDefaultState({
                                                //         isAccept: true
                                                //     });
                                                // }}
                                                >
                                                    {`Help`}
                                                </CloseButton>
                                                <RejectButton style={{ height: '3.5rem' }}
                                                >
                                                    Call for Delivery Boy <i className="fa fa-phone" style={{ marginTop: '5px', marginLeft: '10px' }} aria-hidden="true"></i>
                                                </RejectButton>

                                            </ButtonCollection> */}
                                            {/* </SideBarConBox> */}
                                        </SidebarBox>
                                    </SideBarConBox>
                                </Paper>
                            </>
                        ) : ""}
                        {/* </Paper> */}
                    </DashboardWrapper>
                </DashboardContainer>
            </div>

            {/* Rejcect Order */}
            <Modal
                isOpen={defaultState.isReject}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isReject: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        {/* <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isReject: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div> */}


                    </div>
                }
                content={
                    <>
                        {/* <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', lineHeight: '75px' }}> */}
                        <PopUpBox>
                            <CrossIcon ><img style={{ height: 'auto', width: '80px' }} src={closeIcon} /></CrossIcon>
                            <div>Are you sure you want to reject this order?</div>
                            {/* <div style={{ display: 'flex', height: '50px', justifyContent: 'space-around' }}> */}
                            <ButtonCollection style={{ justifyContent: 'space-around' }}>
                                <RejectButton style={{ width: '8rem', height: '3rem', }}
                                    onClick={() => {
                                        setDefaultState({
                                            isReason: true
                                        })
                                    }
                                    }
                                >{"Yes"}</RejectButton>
                                <CloseButton style={{ width: '8rem', height: '3rem' }} onClick={() => {
                                    setDefaultState({
                                        isReject: false
                                    })
                                }}>{"No"}</CloseButton>
                            </ButtonCollection>
                            {/* </div> */}
                        </PopUpBox>
                        {/* </div> */}
                    </>}
            />

            {/* Accept Order */}
            <Modal
                isOpen={defaultState.isAccept}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isAccept: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isAccept: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div>


                    </div>
                }
                content={
                    <PopUpBox>
                        <div><h4>How long will it take to prepare the food?</h4></div>
                        {/* <div style={{display: 'flex'}}> */}
                        <Counter style={{ marginTop: '1rem' }}>
                            <Button onClick={CounterNum}><i className="fa fa-minus" style={{ marginLeft: '30px', fontSize: '1.5rem' }} aria-hidden="true"></i></Button>
                            <InputBox>{`${time} Min`}</InputBox>
                            <Button onClick={CounterInc}><i className="fa fa-plus" style={{ marginRight: '30px', fontSize: '1.5rem' }} aria-hidden="true"></i></Button>
                            {/* <p>{time}</p> */}
                            {/* <TextField variant="outlined" label="add" /> */}
                        </Counter>
                        <span style={{ color: 'red' }}>{timeError ? timeError : timeErrors}</span>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <HeadingButton style={{ height: '3rem', marginTop: '2rem', width: '7rem', justifyContent: 'center' }} type='submit' onClick={handleAcceptOrders}>{"Done"} </HeadingButton></div>
                    </PopUpBox>}
            />

            {/* Reason Order */}
            <Modal
                isOpen={defaultState.isReason}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isReason: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isReason: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div>


                    </div>
                }
                content={
                    <>
                        <div>
                            <div style={{ fontWeight: '600', fontSize: '1.2rem' }}>Choose a reason for rejection</div>
                            <Formik
                                enableReinitialize
                                initialValues={reasonFormValues}
                                validate={reasonDescValidatior}
                                validateOnChange
                                onSubmit={handleRejectOrders}
                            >
                                {(formikBag) => {
                                    return (
                                        <Form>
                                            <div className="signup-cont">

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <Field name="menu_title">
                                                            {({ field }) => (
                                                                <div className="py-2">

                                                                    <div role="group" aria-labelledby="my-radio-group">
                                                                        {
                                                                            Rejection.map((val, ind) =>
                                                                                <label key={ind}>
                                                                                    <Field type="radio" style={{ marginRight: '5px' }} name="reasonDesc" value={val}
                                                                                    />
                                                                                    {val}
                                                                                </label>
                                                                            )}
                                                                    </div>

                                                                    {/* <Input
                                                                {...field}
                                                                type="text"
                                                                value={formikBag.values.menu_title}
                                                                onChange={(e) => {
                                                                    console.log(formikBag)
                                                                    formikBag.setFieldValue(
                                                                        "menu_title",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                error={
                                                                    formikBag.touched.reasonDesc &&
                                                                        formikBag.errors.reasonDesc
                                                                        ? formikBag.errors.reasonDesc
                                                                        : null
                                                                }
                                                                className="form-control"
                                                                placeholder="Write here your reason"
                                                            /> */}
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <HeadingButton type="submit">
                                                        Done
                                                    </HeadingButton>
                                                </div>

                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </>
                }
            />

            {/* Ready Order */}
            <Modal
                isOpen={defaultState.isReady}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isReady: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        {/* <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isReject: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div> */}


                    </div>
                }
                content={
                    <>
                        {/* <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', lineHeight: '75px' }}> */}
                        <PopUpBox>
                            <CrossIcon ><img style={{ height: 'auto', width: '80px' }} src={readyIcon} /></CrossIcon>
                            <div>Confirm mark as ready?</div>
                            <ButtonCollection style={{ justifyContent: 'space-around' }}>
                                <HeadingButton style={{ width: '8rem', height: '3rem', fontSize: '1.45rem', justifyContent: 'center' }}
                                    onClick={handleReadyOrders}
                                >{"Yes"}</HeadingButton>
                                <CloseButton style={{ width: '8rem', height: '3rem' }} onClick={() => {
                                    setDefaultState({
                                        isReady: false
                                    })
                                }}>{"No"}</CloseButton>
                            </ButtonCollection>
                        </PopUpBox>
                        {/* </div> */}
                    </>}
            />

            {/* Pickup Order */}
            <Modal
                isOpen={defaultState.isOrderPickUp}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isOrderPickUp: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        {/* <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isReject: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div> */}


                    </div>
                }
                content={
                    <>
                        {/* <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', lineHeight: '75px' }}> */}
                        <PopUpBox>
                            <CrossIcon ><img style={{ height: 'auto', width: '80px' }} src={readyIcon} /></CrossIcon>
                            <div>Confirm order picked up?</div>
                            <ButtonCollection style={{ justifyContent: 'space-around' }}>
                                <HeadingButton style={{ width: '8rem', height: '3rem', fontSize: '1.45rem', justifyContent: 'center' }}
                                    onClick={handleOrderPickUpOrders}
                                >{"Yes"}</HeadingButton>
                                <CloseButton style={{ width: '8rem', height: '3rem' }} onClick={() => {
                                    setDefaultState({
                                        isOrderPickUp: false
                                    })
                                }}>{"No"}</CloseButton>
                            </ButtonCollection>
                        </PopUpBox>
                        {/* </div> */}
                    </>}
            />
            {/* User Details */}
            <Modal
                isOpen={defaultState.isDriverDetails}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isDriverDetails: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        {/* <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isReject: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div> */}


                    </div>
                }
                content={
                    <>
                        <DetailPopBox>
                            <DetailTitle>
                                Driver Details
                            </DetailTitle>
                            <DetailBox>
                                <DetailRow>
                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Name</ListItem>
                                    <ListItem style={{ fontWeight: '600' }}>: {get(userDetail, "driver.first_name")} {get(userDetail, "driver.last_name")}</ListItem>
                                </DetailRow>
                                <DetailRow>
                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Email</ListItem>
                                    <ListItem style={{ fontWeight: '600', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>: {get(userDetail, "driver.email")}</ListItem>
                                </DetailRow>
                                <DetailRow>
                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Mobile Number</ListItem>
                                    <ListItem style={{ fontWeight: '600', flexWrap: 'wrap' }} >: {get(userDetail, "driver.country_code")}{get(userDetail, "driver.mobile_number")}</ListItem>
                                </DetailRow>
                            </DetailBox>
                            {/* <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <ButtonAccept
                                onClick={() => {
                                    setDefaultState({
                                        isDetails: false
                                    });
                                }}
                            >
                                {`OK`}
                            </ButtonAccept>
                            </div> */}
                        </DetailPopBox>
                    </>}
            />
            {/* User Details */}
            <Modal
                isOpen={defaultState.isDetails}
                className="update_profile"
                onClose={() => {
                    setDefaultState({
                        isDetails: false
                    });
                }}
                maxWidth='sm'
                title={
                    <div className="modalsign">
                        {/* <div
                            className="closeicon"
                            onClick={() => {
                                setDefaultState({
                                    isReject: false
                                });
                            }}
                        >
                            <i className="fas fa-times"></i>
                        </div> */}


                    </div>
                }
                content={
                    <>
                        <DetailPopBox>
                            <DetailTitle>
                                User Details
                            </DetailTitle>
                            <DetailBox>
                                <DetailRow>
                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Name</ListItem>
                                    <ListItem style={{ fontWeight: '600' }}>: {get(userDetail, "user_id.first_name")} {get(userDetail, "user_id.last_name")}</ListItem>
                                </DetailRow>
                                <DetailRow>
                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Email</ListItem>
                                    <ListItem style={{ fontWeight: '600', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>: {get(userDetail, "user_id.email")}</ListItem>
                                </DetailRow>
                                <DetailRow>
                                    <ListItem style={{ fontWeight: '600', color: '#878997' }}>Mobile Number</ListItem>
                                    <ListItem style={{ fontWeight: '600', flexWrap: 'wrap' }} >: {get(userDetail, "user_id.country_code")}{get(userDetail, "user_id.mobile_number")}</ListItem>
                                </DetailRow>
                            </DetailBox>
                            {/* <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <ButtonAccept
                                onClick={() => {
                                    setDefaultState({
                                        isDetails: false
                                    });
                                }}
                            >
                                {`OK`}
                            </ButtonAccept>
                            </div> */}
                        </DetailPopBox>
                    </>}
            />

            {isLoading && <Overlay />}

        </>

    )
}



const mapStateToProps = (state) => {
    // console.log(state)
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(OfferManagement);

