import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import Input from '../../components/Input';
import CarrotInput from '../../components/CarrotInput';
import { Formik, Field, Form } from "formik";
import axios from "../../axios";
import { toast } from "react-toastify";
import get from "lodash/get"
import ChairGuySvg from "../../images/CarrotSvg/ChairGuy.svg"
import ChairGuyImage from "../../images/CarrotImages/ChairGuy.png";
import GreenBottomImage from "../../images/CarrotImages/GreenBottom.png";
import LottieCarrot from "../../images/CarrotJSON/carrot.json";
import DotCarrotSection from "../../images/CarrotSvg/DotCarrotSection.svg";
import DotCarrotInside from "../../images/CarrotSvg/DotCarrotInside.svg";

import { HeroContainer, HeroBg, ImgBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, NavBtnLink, ChairSvg, SignWelcome,
    WelcomeDesign,
    TrapeziumContent,
    TrapeziumLeft,
    TrapeziumRight,
    SignInSection,
    WelcomeRow,
    ButtonInside,
    EmailPswdSection,
    LabelInput,
    InputLabel,
    // CarrotInput,
    RightSectionInsideDot,
    RightSectionDotOutBig,
    LeftSectionUpperDot,
    LeftSectionOutsideDot,
    RightSectionOutsideDot,
    LeftSectionDot,
    ForgotRow,
    TermsConditionRow,
    TermsCheckbox,
    TermsLabelRow,
    LabelDull,
    LabelDark,
    CarrotButtonRow,
    CarrotButton,
    DontHaveRow,
    ChairSvgSection,
    ChairGuy,
    LottieDiv,
    ChairBottom,
    DontHave,
    SignupText,
    WelcomeSection } from './LoginElements';
import Overlay from '../../components/Overlay';
import { signUPValidator } from '../../utils/validators';




const HeroSection = ({ defaultState, setDefaultState, setUsers, deviceToken, 
    setDeviceToken, }) => {


    const [searchParams] = useSearchParams();
    let referral_id = searchParams.get('referral');

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [signUpformValues, setSignUpformValues] = useState({
        email: "",
        countryCode: "",
        mobileNumber: "",
        password: "",
        confirm_password: "",
        firstName : "",
        lastName : "",
        terms: false,
    });

    const handleSignUP = async (values) => {

        var formvalues = {
            email: values.email,
            countryCode: values.countryCode,
            mobileNumber: values.mobileNumber,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            terms: values.terms,
            is_referred: referral_id ? true : false,
            referral_link: `https://dev.smartcarrots.com/signup?referral=${referral_id}`,
            deviceToken: deviceToken || "uselessToken"
        }
        // console.log(formvalues);


        setIsLoading(true);
        try {
            const { data } = await axios.post("/user/signup", formvalues);
            console.log(data);
            setSignUpformValues({
                email: "",
                countryCode: "",
                mobileNumber: "",
                password: "",
                confirm_password: "",
                firstName : "",
                lastName : "",
                terms: false,
            })
            setIsLoading(false);
            toast.success(data.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            navigate(`/verifyOtp/${get(data,"data._id")}`);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            if (error?.response?.data?.errors) {
                toast.error(`${error.response.data.errors[0].msg}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(`${error?.response?.data?.message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };



    const defaultOptionsCarrot = {
        loop: true,
        autoplay: true,
        animationData: LottieCarrot,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    

    return (
        <>
            <HeroContainer
                IsSignup={true}
            >
                <HeroBg>
                    <ImgBg src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                </HeroBg>
                <HeroContent className="container">
                    <SignWelcome>
                        <SignInSection>
                            <HeroH1>
                                <div style={{color: "#101011", fontFamily: "Titan One"}}>Sign</div>&nbsp;<div style={{color: "#28C54E", fontFamily: "Titan One"}}>Up</div>
                            </HeroH1>
                            <HeroP>We will send you mail to verify</HeroP>
                        </SignInSection>
                        <WelcomeSection 
                            IsSignup={true}
                        >
                            <WelcomeDesign >
                                <TrapeziumLeft>
                                    <LeftSectionDot src={DotCarrotSection}/>
                                    <LeftSectionOutsideDot src={DotCarrotInside}/>
                                    <LeftSectionUpperDot src={DotCarrotInside}/>
                                </TrapeziumLeft>
                                <TrapeziumRight>
                                    <RightSectionInsideDot src={DotCarrotInside}/>
                                    <RightSectionDotOutBig src={DotCarrotSection}/>
                                    <RightSectionOutsideDot src={DotCarrotInside}/>
                                </TrapeziumRight>
                                <TrapeziumContent>
                                    <WelcomeRow>
                                        Let’s Get Started
                                    </WelcomeRow>
                                    <EmailPswdSection>
                                        <Formik
                                            enableReinitialize
                                            initialValues={signUpformValues}
                                            validate={(values) => signUPValidator(values)}
                                            validateOnChange
                                            onSubmit={(values) => {
                                                handleSignUP(values);
                                            }}
                                        >
                                            {(formikBag) => {
                                                return (
                                                    <Form className="formStyle">
                                                        
                                                        <div className='col-md-12 mdNoPaddingFlexRow'>
                                                            <div className='col-md-6 mdRightPaddingShow'>
                                                                <Field name="firstName">
                                                                    {({ field }) => (
                                                                        <LabelInput>
                                                                            <InputLabel>
                                                                                First Name
                                                                            </InputLabel>
                                                                            <CarrotInput
                                                                                {...field}
                                                                                type="text"
                                                                                placeholder="First Name"
                                                                                value={formikBag.values.firstName}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue(
                                                                                        "firstName",
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.firstName &&
                                                                                        formikBag.errors.firstName
                                                                                        ? formikBag.errors.firstName
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </LabelInput>
                                                                    )}
                                                                </Field>
                                                                
                                                            </div>
                                                            <div className='col-md-6 mdLeftPaddingShow'>
                                                                <Field name="lastName">
                                                                    {({ field }) => (
                                                                        <LabelInput>
                                                                            <InputLabel>
                                                                                Last Name
                                                                            </InputLabel>
                                                                            <CarrotInput
                                                                                {...field}
                                                                                type="text"
                                                                                placeholder="Last Name"
                                                                                value={formikBag.values.lastName}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue(
                                                                                        "lastName",
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.lastName &&
                                                                                        formikBag.errors.lastName
                                                                                        ? formikBag.errors.lastName
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </LabelInput>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12 mdNoPaddingFlexRow'>
                                                            <div className='col-md-6 mdRightPaddingShow'>
                                                                <Field name="email">
                                                                    {({ field }) => (
                                                                        <LabelInput>
                                                                            <InputLabel>
                                                                                Email Address
                                                                            </InputLabel>
                                                                            <CarrotInput
                                                                                {...field}
                                                                                type="email"
                                                                                placeholder="Email Address"
                                                                                value={formikBag.values.email}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue(
                                                                                        "email",
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.email &&
                                                                                        formikBag.errors.email
                                                                                        ? formikBag.errors.email
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </LabelInput>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='col-md-6 mdLeftPaddingShow'>
                                                                <Field name="mobileNumber">
                                                                    {({ field }) => (
                                                                        <LabelInput>
                                                                            <InputLabel>
                                                                                Mobile Number
                                                                            </InputLabel>
                                                                            <CarrotInput
                                                                                {...field}
                                                                                style={{justifyContent: "center"}}
                                                                                country="us"
                                                                                type="phone"
                                                                                enableSearch={true}
                                                                                phoneInput={true}
                                                                                countryCodeEditable={false}
                                                                                value={
                                                                                    formikBag.values.countryCode +
                                                                                    formikBag.values.mobileNumber
                                                                                }
                                                                                onChange={(phone, data) => {
                                                                                    formikBag.setFieldValue("countryCode", data.format.slice(0, 1) + data.dialCode );
                                                                                    formikBag.setFieldValue("mobileNumber", phone.slice(data.dialCode.length) );
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.mobileNumber &&
                                                                                        formikBag.errors.mobileNumber
                                                                                        ? formikBag.errors.mobileNumber
                                                                                        : null
                                                                                }
                                                                                placeholder="Phone Number"
                                                                                />
                                                                        </LabelInput>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12 mdNoPaddingFlexRow'>
                                                            <div className='col-md-6 mdRightPaddingShow'>
                                                                <Field name="password">
                                                                    {({ field }) => (
                                                                        <LabelInput>
                                                                            <InputLabel>
                                                                                Password
                                                                            </InputLabel>
                                                                            <CarrotInput
                                                                                {...field}
                                                                                type="password"
                                                                                placeholder="Password"
                                                                                value={formikBag.values.password}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue(
                                                                                        "password",
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.password &&
                                                                                        formikBag.errors.password
                                                                                        ? formikBag.errors.password
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </LabelInput>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className='col-md-6 mdLeftPaddingShow'>
                                                                <Field name="confirm_password">
                                                                    {({ field }) => (
                                                                        <LabelInput>
                                                                            <InputLabel>
                                                                                Confirm Password
                                                                            </InputLabel>
                                                                            <CarrotInput
                                                                                {...field}
                                                                                type="password"
                                                                                placeholder="Confirm Password"
                                                                                value={formikBag.values.confirm_password}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue(
                                                                                        "confirm_password",
                                                                                        e.target.value
                                                                                    );
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.confirm_password &&
                                                                                        formikBag.errors.confirm_password
                                                                                        ? formikBag.errors.confirm_password
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </LabelInput>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        <TermsConditionRow>
                                                            <TermsCheckbox>
                                                                <Field name="terms">
                                                                    {({ field }) => (
                                                                        <Input
                                                                            {...field}
                                                                            type="checkbox"
                                                                            value={formikBag.values.terms}
                                                                            onChange={(e) => {
                                                                                formikBag.setFieldValue(
                                                                                    "terms",
                                                                                    !formikBag.values.terms
                                                                                );
                                                                            }}
                                                                            noBorderBottom={true}
                                                                            width100={true}
                                                                            checkboxLabel={<TermsLabelRow>
                                                                                <LabelDull>
                                                                                    I Accept
                                                                                </LabelDull>
                                                                                <LabelDark
                                                                                    onClick={() => {
                                                                                        navigate("/terms-and-conditions")
                                                                                    }}
                                                                                >
                                                                                    &nbsp;Terms & Conditions
                                                                                </LabelDark>
                                                                                <LabelDull>
                                                                                    &nbsp;and
                                                                                </LabelDull>
                                                                                <LabelDark
                                                                                    onClick={() => {
                                                                                        navigate("/privacy-policy")
                                                                                    }}
                                                                                >
                                                                                    &nbsp;Privacy Policy.
                                                                                </LabelDark>
                                                                            </TermsLabelRow>}
                                                                            error={
                                                                                formikBag.touched.terms &&
                                                                                    formikBag.errors.terms
                                                                                    ? formikBag.errors.terms
                                                                                    : null
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                
                                                            </TermsCheckbox>
                                                        </TermsConditionRow>
                                                        <CarrotButtonRow>
                                                            <CarrotButton
                                                                type="submit"
                                                            >
                                                                <ButtonInside>
                                                                    Sign Up
                                                                </ButtonInside>
                                                            </CarrotButton>
                                                        </CarrotButtonRow>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </EmailPswdSection>
                                    <DontHaveRow style={{paddingBottom: "1rem"}}>
                                        <DontHave>
                                            Already have an account? 
                                        </DontHave>
                                        <SignupText
                                            onClick={() => {
                                                navigate("/login")
                                            }}
                                        >
                                            &nbsp;Sign In Here
                                        </SignupText>
                                    </DontHaveRow>
                                </TrapeziumContent>
                                <LottieDiv>
                                    <Lottie
                                        options={defaultOptionsCarrot}
                                        height={"100%"}
                                        width={"100%"}
                                        style={{
                                            transform: "rotate(245deg)"
                                        }}
                                    />
                                </LottieDiv>
                            </WelcomeDesign>
                        </WelcomeSection>
                    </SignWelcome>
                    <HeroBtnWrapper>
                        
                    </HeroBtnWrapper>
                </HeroContent>
                <ChairSvgSection>
                    {/*<ChairBottom src={GreenBottomImage}/>*/}
                        
                </ChairSvgSection>
            </HeroContainer>
            {isLoading && <Overlay />}
        </>

    )
}




const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        deviceToken: state.deviceToken,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
        setDeviceToken: (updatedValue) => {
            dispatch({
              type: actionTypes.DEVICE_TOKEN,
              updateDeviceToken: updatedValue,
            });
          },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
