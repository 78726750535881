import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import get from "lodash/get";
import axios from "../../axios";

import Lottie from 'react-lottie';
import Input from '../../components/Input';
import CarrotInput from '../../components/CarrotInput';

import ChairGuySvg from "../../images/CarrotSvg/ChairGuy.svg"
import LeafPlantlottie from "../../images/CarrotJSON/planta moviéndose.json";
import LockLottie from "../../images/CarrotJSON/Lock.json";
import defaultOptionsThinkingGuyLottie from "../../images/CarrotJSON/16766-forget-password-animation.json";
import GreenBottomImage from "../../images/CarrotImages/GreenBottom.png";
import LottieCarrot from "../../images/CarrotJSON/carrot.json";
import ResetHandCarrot from "../../images/CarrotSvg/ResetHandCarrot.svg";
import DotCarrotSection from "../../images/CarrotSvg/DotCarrotSection.svg";
import DotCarrotInside from "../../images/CarrotSvg/DotCarrotInside.svg";

import { HeroContainer, HeroBg, ImgBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, NavBtnLink, ChairSvg, SignWelcome,
    WelcomeDesign,
    TrapeziumContent,
    TrapeziumLeft,
    TrapeziumRight,
    SignInSection,
    WelcomeRow,
    ButtonInside,
    EmailPswdSection,
    LabelInput,
    InputLabel,
    // CarrotInput,
    RightSectionInsideDot,
    RightSectionDotOutBig,
    LeftSectionUpperDot,
    LeftSectionOutsideDot,
    RightSectionOutsideDot,
    LeftSectionDot,
    ForgotRow,
    CarrotButtonRow,
    CarrotButton,
    DontHaveRow,
    ChairSvgSection,
    LottieResetLock,
    ChairGuy,
    ChairBottom,
    DontHave,
    HandCarrotSvg,
    SignupText,
    WelcomeSection } from './LoginElements';
import Overlay from '../../components/Overlay';
import { resetPasswordValidator } from '../../utils/validators';

var CryptoJS = require("crypto-js");



const HeroSection = ({ defaultState, setDefaultState, setUsers }) => {
    
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    let searchParamData = window.location.search;
    let newParamData = searchParamData.replace("?id=", '');

    const [isLoading, setIsLoading] = useState(false);
    
    // Decrypt
    var bytes  = CryptoJS.AES.decrypt(newParamData, 'secret_key_123');
    var originalText = bytes.toString(CryptoJS.enc.Utf8);

    console.log(originalText);


    const handleReset = async (values) => {
        setIsLoading(true);
        try {
            const { data } = await axios.put("/user/resetPassword", {
                email: values.email,
                password: values.password,
            });
            toast.success(`${data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });

            navigate('/login');
            setIsLoading(false);
            localStorage.removeItem("access_token");
            localStorage.removeItem("userDataCarrot");
            setUsers("");
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.data?.errors) {
                toast.error(`${error.response.data.errors[0].msg}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(`${error?.response?.data?.message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const defaultOptionsCarrot = {
        loop: true,
        autoplay: true,
        animationData: LockLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };
    

    return (
        <>
            <HeroContainer>
                <HeroBg>
                    <ImgBg src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                </HeroBg>
                <HeroContent className="container">
                    <SignWelcome>
                        <SignInSection>
                            <HeroH1>
                                <div style={{color: "#101011", fontFamily: "Titan One"}}>Reset</div>&nbsp;<div style={{color: "#28C54E", fontFamily: "Titan One"}}>Password</div>
                            </HeroH1>
                        </SignInSection>
                        <WelcomeSection>
                            <WelcomeDesign>
                                <TrapeziumLeft>
                                    <LeftSectionDot src={DotCarrotSection}/>
                                    <LeftSectionOutsideDot src={DotCarrotInside}/>
                                    {/*<LeftSectionUpperDot src={DotCarrotInside}/>*/}
                                </TrapeziumLeft>
                                <TrapeziumRight>
                                    <RightSectionInsideDot src={DotCarrotInside}/>
                                    <RightSectionDotOutBig src={DotCarrotSection}/>
                                    <RightSectionOutsideDot src={DotCarrotInside}/>
                                    {/*<HandCarrotSvg src={ResetHandCarrot}/>*/}
                                </TrapeziumRight>
                                <TrapeziumContent>
                                    <EmailPswdSection style={{justifyContent: "space-around"}}>
                                        <Formik
                                            enableReinitialize
                                            initialValues={{
                                                email: originalText,
                                                password: "",
                                                confirm_password: ""
                                            }}
                                            validate={(values) => resetPasswordValidator(values)}
                                            validateOnChange
                                            onSubmit={(values) => handleReset(values)}
                                        >
                                            {(formikBag) => {
                                                return (
                                                    <Form className="formStyle">
                                                        <Field name="password">
                                                            {({ field }) => (
                                                                <LabelInput>
                                                                    <InputLabel>
                                                                        New Password
                                                                    </InputLabel>
                                                                    <CarrotInput
                                                                        type="password"
                                                                        placeholder="New Password"
                                                                        value={formikBag.values.password}
                                                                        onChange={(e) => {
                                                                            formikBag.setFieldValue(
                                                                                "password",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        error={
                                                                            formikBag.touched.password &&
                                                                                formikBag.errors.password
                                                                                ? formikBag.errors.password
                                                                                : null
                                                                        }
                                                                    />
                                                                </LabelInput>
                                                            )}
                                                        </Field>
                                                        <Field name="confirm_password">
                                                            {({ field }) => (
                                                                <LabelInput>
                                                                    <InputLabel>
                                                                        Confirm New Password
                                                                    </InputLabel>
                                                                    <CarrotInput
                                                                        type="password"
                                                                        placeholder="Confirm New Password"
                                                                        value={formikBag.values.confirm_password}
                                                                        onChange={(e) => {
                                                                            formikBag.setFieldValue(
                                                                                "confirm_password",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        error={
                                                                            formikBag.touched.confirm_password &&
                                                                                formikBag.errors.confirm_password
                                                                                ? formikBag.errors.confirm_password
                                                                                : null
                                                                        }
                                                                    />
                                                                </LabelInput>
                                                            )}
                                                        </Field>
                                                        
                                                        
                                                        <CarrotButtonRow style={{padding: "2rem 0rem"}}>
                                                            <CarrotButton type="submit">
                                                                <ButtonInside >
                                                                    Done
                                                                </ButtonInside>
                                                            </CarrotButton>
                                                        </CarrotButtonRow>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                        
                                    </EmailPswdSection>
                                </TrapeziumContent>
                            </WelcomeDesign>
                        </WelcomeSection>
                    </SignWelcome>
                    <HeroBtnWrapper>
                        
                    </HeroBtnWrapper>
                </HeroContent>
                <ChairSvgSection>
                    <LottieResetLock>
                        <Lottie
                            options={defaultOptionsCarrot}
                            height={"100%"}
                            width={"100%"}
                        />
                    </LottieResetLock>
                    {/*<ChairBottom src={GreenBottomImage}/>*/}
                        
                </ChairSvgSection>
            </HeroContainer>
            {isLoading && <Overlay />}
        </>

    )
}




const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
