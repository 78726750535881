import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from "../../axios";
import { toast } from "react-toastify";
import Countdown from 'react-countdown';
import { Formik, Field, Form } from "formik";
import get from "lodash/get";
import Lottie from 'react-lottie';
import Input from '../../components/Input';
import CarrotInput from '../../components/CarrotInput';
import OtpInput from "../../components/OTPInput";
import ChairGuySvg from "../../images/CarrotSvg/ChairGuy.svg"
import ChairGuyImage from "../../images/CarrotImages/ChairGuy.png";
import GreenBottomImage from "../../images/CarrotImages/GreenBottom.png";
import LottieCarrot from "../../images/CarrotJSON/carrot.json";
import OtpSecurityLottie from "../../images/CarrotJSON/OtpSecurity.json";
import DotCarrotSection from "../../images/CarrotSvg/DotCarrotSection.svg";
import DotCarrotInside from "../../images/CarrotSvg/DotCarrotInside.svg";

import { HeroContainer, HeroBg, ImgBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, NavBtnLink, ChairSvg, SignWelcome,
    WelcomeDesign,
    TrapeziumContent,
    TrapeziumLeft,
    TrapeziumRight,
    SignInSection,
    WelcomeRow,
    ButtonInside,
    EmailPswdSection,
    LabelInput,
    InputLabel,
    // CarrotInput,
    RightSectionInsideDot,
    RightSectionDotOutBig,
    LeftSectionUpperDot,
    LeftSectionOutsideDot,
    RightSectionOutsideDot,
    LeftSectionDot,
    ForgotRow,
    CarrotButtonRow,
    CarrotButton,
    DontHaveRow,
    LottieForgotSecurity,
    ChairSvgSection,
    ChairGuy,
    ChairBottom,
    ResendOtp,
    DontHave,
    ResendOtpText,
    SignupText,
    WelcomeSection } from './LoginElements';
import CountdownTimer from '../../components/CountdownTimer';
import Overlay from '../../components/Overlay';
import { otpValidator } from '../../utils/validators';




const HeroSection = ({ defaultState, setDefaultState, setUsers, setUserToken }) => {
    
    const navigate = useNavigate();
    const params = useParams();

    const [userDetails, setUserDetails] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [OTPFormValues, setOtpFormValues] = useState({
        email: "",
        verificationCode: "",
    });


    useEffect(() => {
        getUserDetails();
    },[])

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

    const getUserDetails = async (values) => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(`/user/get_user_detail?user_id=${params.id}`);
            setUserDetails(data.data);
            setOtpFormValues({
                ...OTPFormValues,
                email: get(data,"data.email","")
            })
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.data?.errors) {
                toast.error(`${error.response.data.errors[0].msg}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(`${error?.response?.data?.message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const verifyOTP = async (values) => {
        setOtpFormValues(values);
        setIsLoading(true);
        try {
            const { data } = await axios.post("/user/verifyOtp", {
                email: values.email,
                verificationCode: values.verificationCode,
            });
            setIsLoading(false);
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("userDataCarrot", JSON.stringify(data.data));
            setUsers(data.data);
            setUserToken(data.access_token);
            navigate("/");
            // toast.success(data.message, {
            //     position: toast.POSITION.TOP_RIGHT,
            // })
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.data?.errors) {
                toast.error(`${error.response.data.errors[0].msg}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(`${error?.response?.data?.message}`, {
                  position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    };

    const defaultOptionsSecurity = {
        loop: true,
        autoplay: true,
        animationData: OtpSecurityLottie,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <HeroContainer>
                <HeroBg>
                    <ImgBg src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                </HeroBg>
                <HeroContent className="container">
                    <SignWelcome>
                        <SignInSection>
                            <HeroH1>
                                <div style={{color: "#101011", fontFamily: "Titan One"}}>Enter</div>&nbsp;<div style={{color: "#28C54E", fontFamily: "Titan One"}}>OTP</div>
                            </HeroH1>
                            <HeroP>OTP has been sent to&nbsp;{OTPFormValues.email}</HeroP>
                        </SignInSection>
                        <WelcomeSection>
                            <WelcomeDesign>
                                <TrapeziumLeft>
                                    <LeftSectionDot src={DotCarrotSection}/>
                                    <LeftSectionOutsideDot src={DotCarrotInside}/>
                                    <LeftSectionUpperDot src={DotCarrotInside}/>
                                </TrapeziumLeft>
                                <TrapeziumRight>
                                    <RightSectionInsideDot src={DotCarrotInside}/>
                                    <RightSectionDotOutBig src={DotCarrotSection}/>
                                    <RightSectionOutsideDot src={DotCarrotInside}/>
                                </TrapeziumRight>
                                <TrapeziumContent>
                                    <EmailPswdSection style={{ justifyContent: "space-around"}}>
                                    <Formik
                                            enableReinitialize
                                            initialValues={OTPFormValues}
                                            validate={(values) => otpValidator(values)}
                                            validateOnChange
                                            onSubmit={verifyOTP}
                                        >
                                            {(formikBag) => {
                                                return (
                                                    <Form className="formStyle">
                                                        <OtpInput
                                                            isInputNum={true}
                                                            value={formikBag.values.verificationCode}
                                                            onChange={(e) =>
                                                                formikBag.setFieldValue("verificationCode", e)
                                                            }
                                                            numInputs={4}
                                                            error={
                                                                formikBag.touched.verificationCode &&
                                                                    formikBag.errors.verificationCode
                                                                    ? formikBag.errors.verificationCode
                                                                    : null
                                                            }
                                                        />
                                                        <ResendOtp>
                                                            <div>Didn't Receive OTP?&nbsp;</div>
                                                            <CountdownTimer
                                                                totalSec={5 * 6000}
                                                                otpData={userDetails}
                                                            />
                                                        </ResendOtp>
                                                        <CarrotButtonRow>
                                                            <CarrotButton>
                                                                <ButtonInside
                                                                    type="submit"
                                                                >
                                                                    Done
                                                                </ButtonInside>
                                                            </CarrotButton>
                                                        </CarrotButtonRow>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </EmailPswdSection>
                                </TrapeziumContent>
                            </WelcomeDesign>
                        </WelcomeSection>
                    </SignWelcome>
                    <HeroBtnWrapper>
                        
                    </HeroBtnWrapper>
                </HeroContent>
                <ChairSvgSection>
                    <LottieForgotSecurity>
                        <Lottie
                            options={defaultOptionsSecurity}
                            height={"100%"}
                            width={"100%"}
                        />
                    </LottieForgotSecurity>
                    {/* <ChairBottom src={GreenBottomImage}/> */}
                        
                </ChairSvgSection>
            </HeroContainer>
            {isLoading && <Overlay />}
        </>

    )
}




const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
        userToken: state.userToken,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setUserToken: (updatedValue) => {
            dispatch({
                type: actionTypes.USER_TOKEN,
                updatedToken: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
