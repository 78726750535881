import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import get from "lodash/get";
import Lottie from "react-lottie";
import Overlay from "../../../components/Overlay";
import axios from "../../../axios";
import ReactHtmlParser from "react-html-parser";
import Icon1 from "../../../images/CarrotImages/aron-visuals-BXOXnQ26B7o-unsplash.jpg";

import AssociateLady from "../../../images/CarrotSvg/AssociateLady.png";
import CarrotAssociate from "../../../images/CarrotSvg/CarrotAssociate.svg";
import { PageHeadingRed } from "../../WebsitePages/AllStores/AllStoresElements";

import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  NavBtnLink,
  ChairSvg,
  SignWelcome,
  WelcomeDesign,
  CarrotButton,
  ButtonInside,
  TrapeziumContent,
  TrapeziumLeft,
  TrapeziumRight,
  SignInSection,
  WelcomeRow,
  EmailPswdSection,
  LabelInput,
  InputLabel,
  // CarrotInput,
  ChairSvgSection,
  AllPlanCards,
  PlanCard,
  CardHeadingRow,
  CardHeading,
  CardPointsSection,
  CardPointRow,
  CardPoint,
  BuySection,
  CardPrice,
  FreeImage,
  WelcomeSection,
  WrapSection,
} from "../../LoginPage/LoginElements";

import {
  AllBlogsData,
  ImgBgDot,
  WorksCard,
  IconBackground,
  WorksIcon,
  WorksH6,
  PlanCardsSection,
  WorksData,
  WorksP,
} from "./BlogsElements";

const HeroSection = ({ defaultState, setDefaultState, setUsers }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [allBlogsData, setAllBlogsData] = useState([]);

  useEffect(() => {
    getAllBlogs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAllBlogs = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/user/get_all_blogs`);
      setAllBlogsData(data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.errors) {
        toast.error(`${error.response.data.errors[0].msg}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${error?.response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const customDescription = (e) => {
    if (e.length <= 100) {
      return e;
    } else {
      let text = e.slice(0, 100) + " ... ";
      return text;
    }
  };

  return (
    <>
      <HeroContainer style={{ height: "100%", background: "#f4fdf3" }}>
        <HeroBg>
          <ImgBgDot src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
        </HeroBg>
        <HeroContent className="container">
          <SignWelcome>
            <SignInSection style={{ padding: "1.5rem 0rem" }}>
              <HeroH1>
                <PageHeadingRed>Blogs</PageHeadingRed>
              </HeroH1>
            </SignInSection>
            <PlanCardsSection>
              <AllBlogsData>
                {allBlogsData.length > 0 ? (
                  <>
                    {allBlogsData.map((item, index) => (
                      <>
                        <WorksCard
                          style={{ border: "1px solid #E43F0F20" }}
                          onClick={() => {
                            navigate(`/blog/${item._id}`);
                          }}
                        >
                          <IconBackground>
                            <WorksIcon className="workedicon" src={item.image} />
                          </IconBackground>
                          <WorksData>
                            <WorksH6>{item.title.slice(0, 50)}</WorksH6>
                            <WorksP>
                              <div dangerouslySetInnerHTML={{ __html: customDescription(item?.short_description) }} />
                            </WorksP>
                          </WorksData>
                        </WorksCard>
                      </>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </AllBlogsData>
            </PlanCardsSection>
          </SignWelcome>
        </HeroContent>
      </HeroContainer>
      {isLoading && <Overlay />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    locationData: state.locations,
    defaultState: state.defaultState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsers: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_USER,
        updatedUser: updatedValue,
      });
    },
    setDefaultState: (updatedValue) => {
      dispatch({
        type: actionTypes.UPDATE_DEFAULT,
        updateDefault: updatedValue,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroSection);
