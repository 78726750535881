import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore"
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBm2qPTkAS0afynt0cO74Zg9T3-NdqJDyM",
  authDomain: "smartcarrots-e3c71.firebaseapp.com",
  projectId: "smartcarrots-e3c71",
  storageBucket: "smartcarrots-e3c71.appspot.com",
  messagingSenderId: "69250280138",
  appId: "1:69250280138:web:bf5684d3784bdfe2a16233",
  measurementId: "G-37BF0J0NGK"
};

// to access database
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();
const db = getDatabase() 

// const db = firebaseApp.firestore();

// const auth = firebase.auth()

const firebaseMessaging = getMessaging(app);
console.log(app);

const fetchToken = (setTokenFound) => {
  console.log(setTokenFound);
  console.log(firebaseMessaging);
    return getToken(firebaseMessaging, {vapidKey: 'BPopzwUy2IDGFqivNfroZUeN3S85D2K8wlbGrwhmA6QY_pNp2Gr1ER6qo8lzLzGWkuwNb-xIVDs1ouhEyvhafK0'}).then((currentToken) => {
    if (currentToken) {
        console.log('current token for client: ', currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
    } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(currentToken);
        // shows on the UI that permission is required 
    }
    }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
    });
}
// const requestForToken = () => {
//   return getToken(firebaseMessaging, { vapidKey: `BH9x5FVAN-Nev8wUZW12_GL14jGnmYBtsj-xXPPhY5X-gthO97tPvxv6_V227cBGpY6Qhj9keLij9keS7t3RiMo` })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log('current token for client: ', currentToken);
//         // Perform any other neccessary action with the token
//       } else {
//         // Show permission request UI
//         console.log('No registration token available. Request permission to generate one.');
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//     });
// };

  
const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(firebaseMessaging, (payload) => {
          // console.log(payload);
        resolve(payload);
    });
});
const FCM_KEY = "BPopzwUy2IDGFqivNfroZUeN3S85D2K8wlbGrwhmA6QY_pNp2Gr1ER6qo8lzLzGWkuwNb-xIVDs1ouhEyvhafK0";

export { firestore, db , app, fetchToken, onMessageListener, FCM_KEY }