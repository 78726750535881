import React, { useState, useEffect, useContext } from 'react'
import { DashboardContainer, DashboardWrapper, DashboardHeading, DashHeading, SvgLogo, BackIcon, MenuAndBack, PreperationTime, LabelHeading, RetaurantDetailsForm, InputDivide, MiddleColumnProfile, InputPic, HeadingBlock, HeadingProfile, HeadingPara, MultipleButtons, TripleButton, MultipleButton, VoucherHeading, VoucherHeadingMain, FullWidthMobileInput, OfferRadioSection, OfferSectionLabel, MobileViewCalender, BannerBox, BannerBoxCard, BannerBoxCardContent, BannerBoxCardContentImage, BannerBoxCardContentItems, BannerBoxCardContentItemsRowOne, BannerBoxCardContentItemsRowTwo, BannerBoxCardContentItemsRowTwoPromotional, BannerBoxCardContentItemsRowTwoActive, BannerBoxCardButton, BannerBoxCardContentImageIcon, InputDivides, BannerBoxCardContentItemsRowOneIcon } from './Offer_ManagementElements'
import { LoginButton } from '../LoginSection/LoginElements';
import { HeadingButton } from '../Dashboard/DashboardElements'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from "formik";
import Input from "../Input";
import YearInput from '../YearInput';
import { extractDate } from "../../utils/functions";
import axios from "../../axios";
import Overlay from '../Overlay'
import { toast } from "react-toastify";
import EditIcon from "../../images/edit_profile_button_table.png";
import DeleteIcon from "../../images/delete_profile_button_table.png";
import BigSale from "../../images/bigsale.png"
import DragButton from "../../images/setting_onbutton@2x.png"
import Select from '../Select'
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileInput from '../FileInputs';
import * as IoIcons from 'react-icons/io';
import * as HiIcons from 'react-icons/hi';
import { get, values } from 'lodash';
import classNames from 'classnames';
import { uploadImage } from '../../utils/functions'
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import Switch from '../Switch/Switch';
import SearchBar from '../SearchBar/Search'
import {
    offerValidator, promotionValidator
} from "../../utils/validators";
import './OfferStyles.css';
import { isEmpty } from "lodash"
import { LanguageContext } from "../../utils/language/Language";


const useStyles = makeStyles((theme) => ({

    textMiddle: {
        verticalAlign: 'middle !important',
        textAlign: "center"
    },
    tablePadding: {
        padding: "0.5rem",
        textAlign: "center",
        fontSize: "1rem"
    },
    paperTableHeight: {
        height: "800px",
        width: "95%",
        marginLeft: "2rem",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
    },
    "@media (max-width: 780px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        }
    },
    "@media (max-width: 480px)": {
        paperTableHeight: {
            marginLeft: "0.75rem"
        }
    },
    tablePaginationStyle: {
        border: "1px solid #0000001a",
        borderRadius: "0rem 0rem 0.4rem 0.4rem",
        overflowY: "hidden"
    },
    tableFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    formPaper: {
        // backgroundColor: 'yellow',
        // height: '800px',
        // width: '80%',
        // margin: 'auto',
    }
}));





const OfferManagement = ({ history, setUsers, userData, }) => {
    const classes = useStyles();

    const { dictionary } = useContext(LanguageContext);

    const [defaultState, setDefaultState] = useState({ isAddMenu: "", isRestaurantDetails: "" })
    const [menuState, setMenuState] = useState({ isOfferVoucher: true, isPromotionalBanner: false, isPromotionalCampaign: false, isAddOffer: false, isAddPromotion: false })
    const [isLoading, setIsLoading] = useState(false);
    const [offersData, setOffersData] = useState([]);
    const [promotionData, setPromotionData] = useState([]);
    const [offerRadio, setOfferRadio] = useState([
        {
            label: "spend more earn more",
            isActive: false
        },
        {
            label: "free/heavy discount",
            isActive: false
        },
    ])


    const [offerValues, setOfferValues] = useState({
        offer_name: "",
        offer_type: "",
        discount_type: "",
        discount_amount: "",
        offer_code: "",
        min_amount: "",
        max_amount: "",
        limit: "",
        offer_validity: {
            from: "",
            to: ""
        }
    });

    const [promotionValues, setPromotionValues] = useState({
        offer: "",
        promotion_title: "",
        banner_image: [],
        charges_for_month: "",
        promotion_duration: {
            start_date: "",
            end_date: ""
        }
        // charging_feature: "",
    });


    // const [isToggled, setIsToggled] = useState(get(userData, 'is_active', false))

    // console.log(isToggled);

    // console.log(promotionValues);


    useEffect(() => {
        getOffers();
        getPromotions();
    }, []);


    // For Pagination
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //  Get Offer

    const [offerName, setOfferName] = useState([])
    const [offerTitle, setOfferTitle] = useState("")


    // console.log(`Your title ${offerTitle}`);


    const getOffers = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_offers");
            console.log(data.data);
            setOffersData(data.data);
            setOfferName(data.data.map((item) => {
                return { label: item.offer_name, value: item._id };
            }));
            // console.log(data.data)
        } catch (error) {
            console.log(error);
            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    // Add Offer
    const handleOfferProfile = async (values) => {
        console.log(values);

        var fromData = {
            offer_name: values.offer_name,
            offer_type: values.offer_type,
            offer_code: values.offer_code,
            offer_validity: {
                from: values.offer_validity.from,
                to: values.offer_validity.to
            },
            min_amount: values.min_amount,
            max_amount: values.max_amount,
            limit: values.limit,
            discount_amount: values.discount_amount,
            discount_type: values.discount_type.value,

        };
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isDishAdd: false,
            };
        });

        try {
            if (values._id) {
                const { data } = await axios.post("/restaurant/update_offer", {
                    _id: values._id,
                    offer_name: values.offer_name,
                    offer_type: values.offer_type,
                    offer_code: values.offer_code,
                    offer_validity: {
                        from: values.offer_validity.from,
                        to: values.offer_validity.to
                    },
                    min_amount: values.min_amount,
                    max_amount: values.discount_type.value == "1" ? 0 : values.max_amount,
                    limit: values.limit,
                    discount_amount: values.discount_amount,
                    discount_type: values.discount_type.value,
                });
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getOffers();
            } else {
                const { data } = await axios.post("/restaurant/add_offer", fromData);
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getOffers();
            }
            setMenuState({
                isOfferVoucher: true,
                isPromotionalBanner: false,
                isPromotionalCampaign: false,
                isAddOffer: false
            });

        } catch (error) {
            setIsLoading(false);
            setMenuState({
                isOfferVoucher: true,
                isPromotionalBanner: false,
                isPromotionalCampaign: false,
                isAddOffer: false
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    // Delete Offer
    const handleDeleteOffers = async (id) => {
        if (window.confirm('Are you sure you want to delete this field ?')) {
            try {
                const { data } = await axios.post("/restaurant/delete_offer", {
                    _id: id,
                });
                getOffers();
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                console.log(error);
                toast.error(`${error.response.data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            getOffers();
        }
    };

    // Get Promotion
    const getPromotions = async () => {
        try {
            const { data } = await axios.get("/restaurant/get_promotion");
            setPromotionData(data.data);
            // setIsToggled(data.data.is_active);
            console.log(data.data);
        } catch (error) {
            console.log(error);
            toast.error(`Session expired`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };



    // const token = Cookies.get('accessToken');

    const token = localStorage.getItem("access_token");


    console.log(token);

    //Add Promotion
    const handlePromotion = async (values) => {
        // console.log(values);
        var fromData = {
            access_token: token,
            offer: values.offer_id,
            promotion_title: values.promotion_title,
            chargesForAMonth: values.charges_for_month,
            ChargingFeature: "Minute",
            bannerImage: values.banner_image[0],
            promotion_duration: {
                start_date: values.promotion_duration.start_date,
                end_date: values.promotion_duration.end_date
            },

            // promotion_duration: 
        };
        console.log(fromData)
        setIsLoading(true);
        setDefaultState((prevState) => {
            return {
                ...prevState,
                isAddPromotion: false,
            };
        });

        try {
            if (values._id) {
                const { data } = await axios.post("/restaurant/update_promotion", {
                    // access_token: token,
                    _id: values._id,
                    offer: values.offer_id,
                    promotion_title: values.promotion_title,
                    promotion_duration: {
                        start_date: values.promotion_duration.start_date,
                        end_date: values.promotion_duration.end_date
                    },
                    bannerImage: values.banner_image[0],
                    chargesForAMonth: values.charges_for_month.toString(),
                    ChargingFeature: "Minute",

                });
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getPromotions();
            } else {
                const { data } = await axios.post("/restaurant/create_promotion", fromData);
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsLoading(false);
                getPromotions();
            }
            setMenuState({
                isOfferVoucher: false,
                isPromotionalBanner: true,
                isPromotionalCampaign: false,
                isAddOffer: false
            });

        } catch (error) {
            setIsLoading(false);
            setMenuState({
                isOfferVoucher: false,
                isPromotionalBanner: true,
                isPromotionalCampaign: false,
                isAddOffer: false
            });
            toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    // Toggle Promotion

    const handleOfferOnOff = async (_id, is_active) => {
        if (is_active == false) {
            try {
                const { data } = await axios.post("/restaurant/update_offer", {
                    is_active: "1",
                    _id: _id
                });
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getOffers()
            } catch (error) {
                console.log(error);
            }
        }
        else if (is_active == true) {
            try {
                const { data } = await axios.post("/restaurant/update_offer", {
                    is_active: "0",
                    _id: _id
                });
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getOffers()
            } catch (error) {
                console.log(error);
            }
        }
        else {
            return "error"
        }

    }


    const getActive = (e) => {
        if (e === false) {
            return <span style={{ color: 'red' }}>Not Active</span>
        }
        else if (e === true) {
            return <span style={{ color: '#00A516' }}>Active</span>
        }
    }

    const deleteBanner = async (id) => {
        if (window.confirm('Are you sure you want to delete this promotion ?')) {
            try {
                const { data } = await axios.post("/restaurant/delete_promotion", {
                    _id: id,
                });
                getPromotions();
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } catch (error) {
                console.log(error);
                toast.error(`${error.response.data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } else {
            getPromotions();
        }
    };


    //Toggle Banner 

    const handlePromtionOnOff = async (_id, is_active) => {
        if (is_active == false) {
            try {
                const { data } = await axios.post("/restaurant/update_promotion", {
                    is_active: "1",
                    _id: _id
                });
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getPromotions()
            } catch (error) {
                console.log(error);
            }
        }
        else if (is_active == true) {
            try {
                const { data } = await axios.post("/restaurant/update_promotion", {
                    is_active: "0",
                    _id: _id
                });
                toast.success(`${data.message}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                getPromotions()
            } catch (error) {
                console.log(error);
            }
        }
        else {
            return "error"
        }

    }


    const showUnitOptions = [
        {
            label: "First User",
            value: "1"
        },
        {
            label: "Normal User",
            value: "2"
        }
    ]


    const unitFun = (e) => {
        if (e === "First User") {
            return "1"
        } else if (e === "Normal User") {
            return "2"
        }
    }
    console.log(promotionData);


    let dataDiscountType = [{
        label: dictionary.offers.Flat,
        value: "1"
    },
    {
        label: dictionary.offers.Percentage,
        value: "2"
    }
    ];

    const discountType = (e) => {

        console.log(e);
        if (e === "1") {
            return "Flat"
        } else if (e === "2") {
            return "Percentage"
        }
    }

    const showDiscountOptions = [
        {
            label: "Flat",
            value: "1"
        },
        {
            label: "Percentage",
            value: "2"
        }
    ]

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    return (
        <>
            <div>
                <DashboardContainer>
                    <DashboardWrapper>
                        <DashboardHeading>
                            <MenuAndBack>
                                <BackIcon>
                                    {(menuState.isAddOffer) ? (
                                        <>
                                            <HeadingButton
                                                style={{ fontSize: "1.5rem", padding: "0.2em 0.2em", borderRadius: "32px", justifyContent: "center", marginBottom: "0.4em" }}
                                                onClick={() => {
                                                    setMenuState({
                                                        isOfferVoucher: true,
                                                        isPromotionalBanner: false,
                                                        isPromotionalCampaign: false,
                                                        isAddOffer: false,
                                                    });
                                                }}
                                            >
                                                <IoIcons.IoIosArrowRoundBack />
                                            </HeadingButton>
                                        </>
                                    ) : ""}
                                    {(menuState.isAddPromotion) ? (
                                        <>
                                            <HeadingButton
                                                style={{ fontSize: "1.5rem", padding: "0.2em 0.2em", borderRadius: "32px", justifyContent: "center", marginBottom: "0.4em" }}
                                                onClick={() => {
                                                    setMenuState({
                                                        isOfferVoucher: false,
                                                        isPromotionalBanner: true,
                                                        isPromotionalCampaign: false,
                                                        isAddOffer: false,
                                                    });
                                                }}
                                            >
                                                <IoIcons.IoIosArrowRoundBack />
                                            </HeadingButton>
                                        </>
                                    ) : ""}
                                </BackIcon>
                                <DashHeading>
                                    {menuState.isOfferVoucher ? dictionary.offers.Offer_Deal_Management : ""}
                                    {menuState.isPromotionalBanner ? dictionary.offers.Offer_Deal_Management : ""}
                                    {menuState.isPromotionalCampaign ? dictionary.offers.Add_Offer_Deal : ""}
                                    {menuState.isAddOffer ? dictionary.offers.Add_Offer_Deal : ""}
                                    {menuState.isAddPromotion ? `Add Promotion` : ""}
                                </DashHeading>
                            </MenuAndBack>
                            {(menuState.isOfferVoucher || menuState.isPromotionalBanner || menuState.isPromotionalCampaign) ? (
                                <>
                                    {/* <SearchBar /> */}
                                    <div></div>
                                </>
                            ) : ""}
                        </DashboardHeading>
                        {(menuState.isOfferVoucher || menuState.isPromotionalBanner || menuState.isPromotionalCampaign) ? (
                            <>
                                {/* <MultipleButtons>
                                    <TripleButton>
                                        <MultipleButton
                                            selected={menuState.isOfferVoucher}
                                            onClick={() => {
                                                setMenuState({
                                                    isOfferVoucher: true,
                                                    isPromotionalBanner: false,
                                                    isPromotionalCampaign: false,
                                                    isAddOffer: false,
                                                });
                                            }}
                                        >
                                            Offer Vouchers
                                        </MultipleButton>
                                        <MultipleButton
                                            selected={menuState.isPromotionalBanner}
                                            onClick={() => {
                                                setMenuState({
                                                    isOfferVoucher: false,
                                                    isPromotionalBanner: true,
                                                    isPromotionalCampaign: false,
                                                    isAddOffer: false,
                                                });
                                            }}>
                                            Promotion Banner
                                        </MultipleButton>
                                    </TripleButton>
                                </MultipleButtons> */}
                            </>
                        ) : ""}

                        {menuState.isOfferVoucher ? (
                            <VoucherHeading>
                                <MenuAndBack>
                                    {/* <VoucherHeadingMain>
                                        {"All Offers & Vouchers created by you will be shown here"}
                                    </VoucherHeadingMain> */}
                                </MenuAndBack>

                                <HeadingButton onClick={() => {
                                    setMenuState({
                                        isOfferVoucher: false,
                                        isPromotionalBanner: false,
                                        isPromotionalCampaign: false,
                                        isAddOffer: true,
                                    });
                                    setOfferValues({
                                        offer_name: "",
                                        offer_type: "",
                                        discount_type: "",
                                        discount_amount: "",
                                        offer_code: "",
                                        min_amount: "",
                                        max_amount: "",
                                        limit: "",
                                        offer_validity: {
                                            from: "",
                                            to: ""
                                        }
                                    })
                                }}>
                                   {dictionary.offers.Add_Offer_Voucher}
                                </HeadingButton>
                            </VoucherHeading>
                        ) : ""}
                        {menuState.isPromotionalBanner ? (
                            <VoucherHeading>
                                <MenuAndBack>
                                    {/* <VoucherHeadingMain>
                                        {"All Promotions created by you will be shown here"}
                                    </VoucherHeadingMain> */}
                                </MenuAndBack>

                                <HeadingButton onClick={() => {
                                    setMenuState({
                                        isOfferVoucher: false,
                                        isPromotionalBanner: false,
                                        isPromotionalCampaign: false,
                                        isAddOffer: false,
                                        isAddPromotion: true
                                    });
                                }}>
                                    {`Add Promotion`}
                                </HeadingButton>
                            </VoucherHeading>
                        ) : ""}

                        <Paper className={classes.paperTableHeight} style={{ overflow: "hidden" }}>
                            {menuState.isOfferVoucher ? (
                                <>
                                    <TableContainer className={classes.tableContainerHeight}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={classes.tablePadding}>{dictionary.size_master_commom.SNO}</TableCell>
                                                    <TableCell className={classes.tablePadding}>
                                                        Offer&nbsp;Name
                                                    </TableCell>
                                                    <TableCell className={classes.tablePadding}>Offer&nbsp;Code</TableCell>
                                                    <TableCell className={classes.tablePadding}>Offer&nbsp;Type</TableCell>
                                                    <TableCell className={classes.tablePadding}>Min&nbsp;Amount</TableCell>
                                                    {/* <TableCell className={classes.tablePadding}>Max&nbsp;Amount</TableCell> */}
                                                    <TableCell className={classes.tablePadding}>{dictionary.offers.From}</TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.offers.To}</TableCell>
                                                    {/* <TableCell className={classes.tablePadding}>Status</TableCell> */}
                                                    <TableCell className={classes.tablePadding}>{dictionary.size_master_commom.Actions}</TableCell>
                                                    <TableCell className={classes.tablePadding}>{dictionary.size_master_commom.Status}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {offersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((category, index) => (
                                                    <TableRow key={category._id}>
                                                        <TableCell component="th" scope="row" className={classes.textMiddle}>
                                                            {index + 1 + (page) * rowsPerPage}
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.offer_name}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.offer_code}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.offer_type}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.min_amount}
                                                            </div>
                                                        </TableCell>
                                                        {/* <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {category.max_amount}
                                                            </div>
                                                        </TableCell> */}
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {extractDate(get(category, "offer_validity.from", ""))}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {extractDate(get(category, "offer_validity.to", ""))}
                                                            </div>
                                                        </TableCell>
                                                        {/* <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {get(category, "status", "")}
                                                            </div>
                                                        </TableCell> */}
                                                        <TableCell className={classes.textMiddle}>
                                                            <div className={classes.tableFlex}>
                                                                <SvgLogo
                                                                    style={{ padding: "0rem 0.2rem" }}
                                                                    className="logoImage"
                                                                    src={EditIcon}
                                                                    onClick={() => {
                                                                        setOfferValues({
                                                                            _id: category._id,
                                                                            offer_name: category.offer_name,
                                                                            offer_type: category.offer_type,
                                                                            offer_code: category.offer_code,
                                                                            offer_validity: {
                                                                                from: get(category, "offer_validity.from", ""),
                                                                                to: get(category, "offer_validity.to", "")
                                                                            },
                                                                            min_amount: category.min_amount,
                                                                            max_amount: category.max_amount == 0 ? "" : category.max_amount,
                                                                            limit: category.limit,
                                                                            discount_amount: category.discount_amount,
                                                                            discount_type: get(category, "discount_type", ""),

                                                                        });
                                                                        // setOfferRadio([
                                                                        //     {
                                                                        //         label: offerRadio[0].label,
                                                                        //         isActive: category.offer_type === offerRadio[0].label ? true : false
                                                                        //     },
                                                                        //     {
                                                                        //         label: offerRadio[1].label,
                                                                        //         isActive: category.offer_type === offerRadio[1].label ? true : false
                                                                        //     },
                                                                        // ]);
                                                                        setMenuState({
                                                                            isOfferVoucher: false,
                                                                            isPromotionalBanner: false,
                                                                            isPromotionalCampaign: false,
                                                                            isAddOffer: true
                                                                        });
                                                                    }}
                                                                />
                                                                <SvgLogo
                                                                    style={{ padding: "0rem 0.2rem" }}
                                                                    className="logoImage"
                                                                    src={DeleteIcon}
                                                                    onClick={() => {
                                                                        handleDeleteOffers(category._id)
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell className={classes.textMiddle}>
                                                            <div>
                                                                {/* {get(category, "status", "")} */}
                                                                <Switch
                                                                    rounded={true}
                                                                    isToggled={get(category, "is_active", "")}
                                                                    bgColorChecked="#ffffff"
                                                                    roundColorChecked="#A91F6E"
                                                                    border="1px solid #A91F6E"
                                                                    bgColorUnChecked="#A91F6E"
                                                                    roundColorUnChecked="#ffffff"
                                                                    onToggle={() => {
                                                                        handleOfferOnOff((category._id), (category.is_active))
                                                                    }}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className={classes.tablePaginationStyle}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={offersData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                            ) : ""}
                            {menuState.isAddOffer ? (
                                <>
                                    <RetaurantDetailsForm>
                                        <Formik
                                            enableReinitialize
                                            initialValues={offerValues}
                                            validate={offerValidator}
                                            validateOnChange
                                            onSubmit={handleOfferProfile}
                                        >
                                            {(formikBag) => {
                                                return (
                                                    <Form className={classNames("mobileViewPadding", "designScrollbar")} style={{ width: "100%", padding: "2rem", height: "80vh", overflow: "scroll" }}>

                                                        <InputDivide className="col-md-12">
                                                            <div className="col-md-6" style={{ padding: "1rem" }}>
                                                                <label>{dictionary.offers.Offer_Name}</label>
                                                                <Field name="offer_name">
                                                                    {({ field }) => (
                                                                        <div className="py-2">
                                                                            <Input
                                                                                {...field}
                                                                                type="text"
                                                                                value={formikBag.values.offer_name}
                                                                                // icon={RestaurantIcon}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue("offer_name", e.target.value);
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.offer_name && formikBag.errors.offer_name
                                                                                        ? formikBag.errors.offer_name
                                                                                        : null
                                                                                }
                                                                                className="form-control"
                                                                                placeholder="Offer Name"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>

                                                            <div className="col-md-6" style={{ marginTop: "1.8rem" }}>
                                                                <label>{dictionary.offers.Offer_Type}</label>
                                                                <Field name="offer_type">
                                                                    {({ field }) => (
                                                                        <div className="py-2">
                                                                            <Select
                                                                                defaultValue={!isEmpty(formikBag.values.offer_type) ? { label: formikBag.values.offer_type, value: unitFun(formikBag.values.offer_type) } : ""}
                                                                                options={showUnitOptions}
                                                                                placeholder="Select Offer Type"
                                                                                onChange={(option) => {
                                                                                    formikBag.setFieldValue(
                                                                                        "offer_type",
                                                                                        option.label
                                                                                    );
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.offer_type &&
                                                                                        formikBag.errors.offer_type
                                                                                        ? formikBag.errors.offer_type
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </InputDivide>

                                                        <InputDivide className="col-md-12">
                                                            <div className="col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                                                <label style={{ paddingLeft: "1rem" }}>{dictionary.offers.Duration}</label>
                                                                <MobileViewCalender>
                                                                    <FullWidthMobileInput className="col-md-6">
                                                                        <Field name="annual_revenue">
                                                                            {({ field }) => (
                                                                                <div className="py-2, borderBottom">
                                                                                    <YearInput
                                                                                        // selected={formikBag.values.offer_validity.from || null}
                                                                                        value={extractDate(formikBag.values.offer_validity.from) || null}
                                                                                        onChange={(date) => {
                                                                                            const event = new Date(date).toLocaleDateString('en-US');
                                                                                            formikBag.setFieldValue(
                                                                                                "offer_validity.from",
                                                                                                event
                                                                                            );

                                                                                            setStartDate(date)
                                                                                        }}
                                                                                        selectsStart
                                                                                        startDate={startDate}
                                                                                        endDate={endDate}
                                                                                        minDate={new Date()}
                                                                                        dateFormat="dd-MM-yyyy"
                                                                                        showDatePicker
                                                                                        showYearDropdown
                                                                                        showMonthDropdown
                                                                                        className="form-control"
                                                                                        placeholderText={dictionary.offers.From}
                                                                                        error={
                                                                                            formikBag.touched.offer_validityfrom &&
                                                                                                formikBag.errors.offer_validityfrom
                                                                                                ? formikBag.errors.offer_validityfrom
                                                                                                : null
                                                                                        }
                                                                                    />


                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                        {formikBag.errors.offer_validityfrom && (
                                                                            <p
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    fontSize: 13,
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {formikBag.errors.offer_validityfrom}
                                                                            </p>
                                                                        )}
                                                                    </FullWidthMobileInput>
                                                                    <FullWidthMobileInput className="col-md-6">
                                                                        <Field name="annual_revenue">
                                                                            {({ field }) => (
                                                                                <div className="py-2, borderBottom">
                                                                                    <YearInput
                                                                                        // selected={formikBag.values.offer_validity.to || null}
                                                                                        value={extractDate(formikBag.values.offer_validity.to) || null}
                                                                                        onChange={(date) => {
                                                                                            const event = new Date(date).toLocaleDateString('en-US');
                                                                                            formikBag.setFieldValue(
                                                                                                "offer_validity.to",
                                                                                                event
                                                                                            );
                                                                                            console.log(event)
                                                                                            setEndDate(date)

                                                                                        }}
                                                                                        selectsStart
                                                                                        minDate={startDate}
                                                                                        // startDate={startDate}
                                                                                        endDate={endDate}
                                                                                        dateFormat="dd-MM-yyyy"
                                                                                        showDatePicker
                                                                                        showYearDropdown
                                                                                        showMonthDropdown
                                                                                        className="form-control"
                                                                                        placeholderText={dictionary.offers.To}
                                                                                        error={
                                                                                            formikBag.touched.offer_validityto &&
                                                                                                formikBag.errors.offer_validityto
                                                                                                ? formikBag.errors.offer_validityto
                                                                                                : null
                                                                                        }
                                                                                    />

                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                        {formikBag.errors.offer_validityto && (
                                                                            <p
                                                                                style={{
                                                                                    paddingTop: 5,
                                                                                    fontSize: 13,
                                                                                    color: "red",
                                                                                }}
                                                                            >
                                                                                {formikBag.errors.offer_validityto}
                                                                            </p>
                                                                        )}
                                                                    </FullWidthMobileInput>
                                                                </MobileViewCalender>
                                                            </div>
                                                            <div className="col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                                                <label style={{ paddingLeft: "1rem" }}>{dictionary.offers.Order_Amount_Limit}</label>
                                                                <MobileViewCalender>
                                                                    <FullWidthMobileInput className={classNames("col-md-6")}>
                                                                        <Field name="min_amount">
                                                                            {({ field }) => (
                                                                                <div className="py-2">
                                                                                    <Input
                                                                                        {...field}
                                                                                        type="number"
                                                                                        value={formikBag.values.min_amount}
                                                                                        min="0"
                                                                                        // icon={LocationIcon}
                                                                                        onChange={(e) => {
                                                                                            formikBag.setFieldValue("min_amount", e.target.value);
                                                                                        }}
                                                                                        error={
                                                                                            formikBag.touched.min_amount && formikBag.errors.min_amount
                                                                                                ? formikBag.errors.min_amount
                                                                                                : null
                                                                                        }
                                                                                        className="form-control"
                                                                                        placeholder={dictionary.offers.Min_Amount}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </FullWidthMobileInput>

                                                                </MobileViewCalender>
                                                            </div>
                                                        </InputDivide>
                                                        <div className="col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                                            <label style={{ paddingLeft: "1rem" }}>{dictionary.offers.Discount_Limit}</label>
                                                            <MobileViewCalender>
                                                                <FullWidthMobileInput className={classNames("col-md-6")} style={{ marginTop: "1.2rem" }}>
                                                                    <Select
                                                                        isSearchable={false}
                                                                        isClearable={false}
                                                                        // value={
                                                                        //     discountType(formikBag.values.discount_type)
                                                                        //     // selectUnit.find(item=> item.value == formikBag.values.unit)
                                                                        // }
                                                                        defaultValue={!isEmpty(formikBag.values.discount_type) ? { label: discountType(formikBag.values.discount_type), value: formikBag.values.discount_type } : ""}
                                                                        options={dataDiscountType}
                                                                        onChange={(option) => {
                                                                            // console.log(option);
                                                                            formikBag.setFieldValue(
                                                                                "discount_type", option
                                                                            );
                                                                        }}
                                                                        error={
                                                                            formikBag.touched.discount_type &&
                                                                                formikBag.errors.discount_type
                                                                                ? formikBag.errors.discount_type
                                                                                : null
                                                                        }
                                                                    >
                                                                    </Select>
                                                                </FullWidthMobileInput>
                                                                <FullWidthMobileInput className="col-md-6">
                                                                    <Field name="discount_amount">
                                                                        {({ field }) => (
                                                                            <div className="py-2">
                                                                                <Input
                                                                                    {...field}
                                                                                    type="number"
                                                                                    min="0"
                                                                                    value={formikBag.values.discount_amount}
                                                                                    // icon={LocationIcon}
                                                                                    onChange={(e) => {
                                                                                        formikBag.setFieldValue("discount_amount", e.target.value);
                                                                                    }}
                                                                                    error={
                                                                                        formikBag.touched.discount_amount && formikBag.errors.discount_amount
                                                                                            ? formikBag.errors.discount_amount
                                                                                            : null
                                                                                    }
                                                                                    className="form-control"
                                                                                    placeholder={dictionary.offers.Discount}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </FullWidthMobileInput>
                                                                {
                                                                    formikBag.values.discount_type.value == "2" && formikBag.values.max_amount == "" || formikBag.values.max_amount > 0 ? <FullWidthMobileInput className="col-md-6">
                                                                        <Field name="max_amount">
                                                                            {({ field }) => (
                                                                                <div className="py-2">
                                                                                    <Input
                                                                                        {...field}
                                                                                        type="number"
                                                                                        value={formikBag.values.max_amount}
                                                                                        // icon={LocationIcon}
                                                                                        onChange={(e) => {
                                                                                            formikBag.setFieldValue("max_amount", e.target.value);
                                                                                        }}
                                                                                        className="form-control"
                                                                                        placeholder="Max Discount Limit"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </FullWidthMobileInput>
                                                                        : formikBag.values.discount_type.value == "1" || formikBag.values.discount_type == "1" || formikBag.values.discount_type == "" ? "" : <FullWidthMobileInput className="col-md-6">
                                                                            <Field name="max_amount">
                                                                                {({ field }) => (
                                                                                    <div className="py-2">
                                                                                        <Input
                                                                                            {...field}
                                                                                            type="number"
                                                                                            value={formikBag.values.max_amount}
                                                                                            // icon={LocationIcon}
                                                                                            onChange={(e) => {
                                                                                                formikBag.setFieldValue("max_amount", e.target.value);
                                                                                            }}
                                                                                            className="form-control"
                                                                                            placeholder="Max Discount Limit"
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Field>
                                                                        </FullWidthMobileInput>
                                                                }
                                                            </MobileViewCalender>
                                                        </div>
                                                        <InputDivide className="col-md-12">
                                                            <div className="col-md-6" style={{ padding: "1rem" }}>
                                                                <label>{dictionary.offers.Usage_Limit}</label>
                                                                <Field name="limit">
                                                                    {({ field }) => (
                                                                        <div className="py-2">
                                                                            <Input
                                                                                {...field}
                                                                                type="text"
                                                                                value={formikBag.values.limit}
                                                                                // icon={RestaurantIcon}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue("limit", e.target.value);
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.limit && formikBag.errors.limit
                                                                                        ? formikBag.errors.limit
                                                                                        : null
                                                                                }
                                                                                className="form-control"
                                                                                placeholder="Usage Limit"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>

                                                            <div className="col-md-6" style={{ padding: "1rem" }}>
                                                                <label>{dictionary.offers.Enter_Code}</label>
                                                                <Field name="offer_code">
                                                                    {({ field }) => (
                                                                        <div className="py-2">
                                                                            <Input
                                                                                {...field}
                                                                                type="text"
                                                                                value={formikBag.values.offer_code}
                                                                                // icon={RestaurantIcon}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue("offer_code", e.target.value);
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.offer_code && formikBag.errors.offer_code
                                                                                        ? formikBag.errors.offer_code
                                                                                        : null
                                                                                }
                                                                                className="form-control"
                                                                                placeholder= {dictionary.offers.Offer_Code}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </InputDivide>
                                                        <div className="text-center login_btn_group" style={{ justifyContent: "center" }}>
                                                            <LoginButton
                                                                type="submit"
                                                                className="buttonWidthResponsive"
                                                            >
                                                                {dictionary.offers.Save_Add}
                                                                {/* <HiIcons.HiOutlineArrowNarrowRight style={{ fontSize: "1.7rem" }} /> */}
                                                            </LoginButton>
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </RetaurantDetailsForm>
                                </>
                            ) : ""}
                            {menuState.isPromotionalBanner ? (
                                <>
                                    <BannerBox>
                                        {
                                            promotionData.map((val, ind) => {

                                                const { bannerImage, promotion_title, is_active, _id, offer, promotion_duration, chargesForAMonth, ChargingFeature } = val;

                                                return (
                                                    <BannerBoxCard key={ind}>
                                                        <BannerBoxCardContent>
                                                            <BannerBoxCardContentImage>
                                                                <BannerBoxCardContentImageIcon src={bannerImage} />
                                                            </BannerBoxCardContentImage>
                                                            <BannerBoxCardContentItems>
                                                                <BannerBoxCardContentItemsRowOne>
                                                                    {/* <i className="ph-pencil-line" style={{ fontSize: '30px', color: '#7D7D7D' }}></i> */}
                                                                    <i className="ph-trash" onClick={() => deleteBanner(_id)} style={{
                                                                        fontSize: '30px', color: '#7D7D7D', cursor
                                                                            : 'pointer', marginRight: '10px'
                                                                    }}></i>
                                                                    <BannerBoxCardContentItemsRowOneIcon>
                                                                        <Switch
                                                                            rounded={true}
                                                                            isToggled={is_active}
                                                                            bgColorChecked="#ffffff"
                                                                            roundColorChecked="#A91F6E"
                                                                            border="1px solid #A91F6E"
                                                                            bgColorUnChecked="#A91F6E"
                                                                            roundColorUnChecked="#ffffff"
                                                                            onToggle={() => {
                                                                                handlePromtionOnOff((_id), (is_active))
                                                                            }}
                                                                        />
                                                                        {/* <img src={DragButton} style={{ height: 'auto', width: '13%', marginBottom: '8px' }} /> */}
                                                                    </BannerBoxCardContentItemsRowOneIcon>
                                                                </BannerBoxCardContentItemsRowOne>
                                                                <BannerBoxCardContentItemsRowTwo>
                                                                    <BannerBoxCardContentItemsRowTwoPromotional>{promotion_title}</BannerBoxCardContentItemsRowTwoPromotional>
                                                                    <BannerBoxCardContentItemsRowTwoActive>{getActive(is_active)}</BannerBoxCardContentItemsRowTwoActive>
                                                                </BannerBoxCardContentItemsRowTwo>
                                                            </BannerBoxCardContentItems>
                                                        </BannerBoxCardContent>
                                                        <BannerBoxCardButton onClick={() => {
                                                            setPromotionValues({
                                                                _id: _id,
                                                                offer_name: offer.offer_name,
                                                                offer_id: offer._id,
                                                                promotion_title: promotion_title,
                                                                banner_image: [bannerImage],
                                                                charges_for_month: chargesForAMonth,
                                                                ChargingFeature: "Minute",
                                                                // promotion_duration: {
                                                                //     start_date: extractDate(promotion_duration.start_date),
                                                                //     end_date: extractDate(promotion_duration.end_date)
                                                                // },
                                                                promotion_duration: {
                                                                    start_date: promotion_duration.start_date,
                                                                    end_date: promotion_duration.end_date
                                                                },
                                                            });

                                                            setMenuState({
                                                                isOfferVoucher: false,
                                                                isPromotionalBanner: false,
                                                                isPromotionalCampaign: false,
                                                                isAddOffer: false,
                                                                isAddPromotion: true,
                                                            });
                                                        }}>View Details
                                                        </BannerBoxCardButton>
                                                    </BannerBoxCard>
                                                )
                                            })
                                        }
                                    </BannerBox>
                                </>
                            ) : ""}
                            {menuState.isAddPromotion ? (
                                <>
                                    <RetaurantDetailsForm className={classes.formPaper}>
                                        <Formik
                                            enableReinitialize
                                            initialValues={promotionValues}
                                            validate={promotionValidator}
                                            validateOnChange
                                            onSubmit={handlePromotion}
                                        >
                                            {(formikBag) => {
                                                return (
                                                    <Form className={classNames("mobileViewPadding", "designScrollbar")} style={{ width: "100%", padding: "2rem", height: "80vh", overflow: "scroll" }}>
                                                        <InputDivides className="col-md-12">

                                                            <div className="form-group">
                                                                <label>Offer Name</label>
                                                                <Field name="offer_id">
                                                                    {({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            value={!isEmpty(formikBag.values.offer_name) ? { label: formikBag.values.offer_name, value: formikBag.values.offer_id } : ""}
                                                                            options={offerName}
                                                                            // defaultValue= {!isEmpty(formikBag.values.offer_name) ?  { label: formikBag.values.offer_name, value: formikBag.values.offer_id} : "" }
                                                                            onChange={(option) => {

                                                                                console.log()

                                                                                if (option === null) {
                                                                                    formikBag.setFieldValue([])
                                                                                }
                                                                                else {
                                                                                    formikBag.setFieldValue(
                                                                                        "offer_id",
                                                                                        option.value
                                                                                    );
                                                                                    formikBag.setFieldValue(
                                                                                        "offer_name",
                                                                                        option.label
                                                                                    );
                                                                                }


                                                                            }}
                                                                            error={
                                                                                formikBag.touched.offer_id &&
                                                                                    formikBag.errors.offer_id
                                                                                    ? formikBag.errors.offer_id
                                                                                    : null
                                                                            }

                                                                        >
                                                                        </Select>
                                                                    )}
                                                                </Field>
                                                            </div>


                                                            <div className="col-md-12">
                                                                <label>Banner Image</label>
                                                                <Field name="banner_image">
                                                                    {({ field }) => (
                                                                        <div className="py-2">

                                                                            <FileInput
                                                                                id="banner_image"
                                                                                limit="1"
                                                                                dictionary="dictionary"
                                                                                images={formikBag.values.banner_image}
                                                                                onDelete={(image) => {
                                                                                    var images = [...formikBag.values.banner_image];
                                                                                    images.splice(images.indexOf(image), 1);
                                                                                    formikBag.setFieldValue('banner_image', images)

                                                                                }}
                                                                                type="text"
                                                                                label="upload_products_facility_photos"
                                                                                info="eg_img"
                                                                                onChange={async (e) => {
                                                                                    const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
                                                                                    if (fileSize > 2) {
                                                                                        alert("ex_2mb");
                                                                                        // $(file).val(''); //for clearing with Jquery
                                                                                    } else {
                                                                                        setIsLoading(true);
                                                                                        var image = await uploadImage(e.target.files[0]);
                                                                                        var images = [...formikBag.values.banner_image];
                                                                                        images.push(image.path);
                                                                                        formikBag.setFieldValue('banner_image', images)

                                                                                        setIsLoading(false);
                                                                                    }
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.banner_image &&
                                                                                        formikBag.errors.banner_image
                                                                                        ? formikBag.errors.banner_image
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="col-md-6" style={{ padding: "1rem" }}>
                                                                <label>Promotion Title</label>
                                                                <Field name="promotion_title">
                                                                    {({ field }) => (
                                                                        <div className="py-2">
                                                                            <Input
                                                                                {...field}
                                                                                type="text"
                                                                                value={formikBag.values.promotion_title}
                                                                                // icon={LocationIcon}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue("promotion_title", e.target.value);
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.promotion_title && formikBag.errors.promotion_title
                                                                                        ? formikBag.errors.promotion_title
                                                                                        : null
                                                                                }
                                                                                className="form-control"
                                                                                placeholder="Enter Promotion Title"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <MobileViewCalender>
                                                                <FullWidthMobileInput className="col-md-6">
                                                                    <Field name="annual_revenue">
                                                                        {({ field }) => (
                                                                            <div className="py-2, borderBottom">
                                                                                <YearInput
                                                                                    value={extractDate(formikBag.values.promotion_duration.start_date) || null}
                                                                                    onChange={(date) => {
                                                                                        const event = new Date(date).toLocaleDateString('en-US');
                                                                                        formikBag.setFieldValue(
                                                                                            "promotion_duration.start_date",
                                                                                            event
                                                                                        );
                                                                                    }}
                                                                                    selectsStart
                                                                                    minDate={new Date()}

                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    showDatePicker
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="form-control"
                                                                                    placeholderText={dictionary.offers.From}
                                                                                    error={
                                                                                        formikBag.touched.promotion_durationstart_date &&
                                                                                            formikBag.errors.promotion_durationstart_date
                                                                                            ? formikBag.errors.promotion_durationstart_date
                                                                                            : null
                                                                                    }
                                                                                />


                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                    {formikBag.errors.offer_validityfrom && (
                                                                        <p
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                fontSize: 13,
                                                                                color: "red",
                                                                            }}
                                                                        >
                                                                            {formikBag.errors.offer_validityfrom}
                                                                        </p>
                                                                    )}
                                                                </FullWidthMobileInput>
                                                                <FullWidthMobileInput className="col-md-6">
                                                                    <Field name="annual_revenue">
                                                                        {({ field }) => (
                                                                            <div className="py-2, borderBottom">
                                                                                <YearInput
                                                                                    value={extractDate(formikBag.values.promotion_duration.end_date) || null}
                                                                                    onChange={(date) => {
                                                                                        const event = new Date(date).toLocaleDateString('en-US');
                                                                                        formikBag.setFieldValue(
                                                                                            "promotion_duration.end_date",
                                                                                            event
                                                                                        );
                                                                                        console.log(event)

                                                                                    }}
                                                                                    selectsStart

                                                                                    minDate={new Date()}
                                                                                    dateFormat="dd-MM-yyyy"
                                                                                    showDatePicker
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    className="form-control"
                                                                                    placeholderText={dictionary.offers.To}
                                                                                    error={
                                                                                        formikBag.touched.promotion_durationend_date &&
                                                                                            formikBag.errors.promotion_durationend_date
                                                                                            ? formikBag.errors.promotion_durationend_date
                                                                                            : null
                                                                                    }
                                                                                />

                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                    {formikBag.errors.offer_validityto && (
                                                                        <p
                                                                            style={{
                                                                                paddingTop: 5,
                                                                                fontSize: 13,
                                                                                color: "red",
                                                                            }}
                                                                        >
                                                                            {formikBag.errors.offer_validityto}
                                                                        </p>
                                                                    )}
                                                                </FullWidthMobileInput>
                                                            </MobileViewCalender>
                                                            <div className="col-md-6" style={{ padding: "1rem" }}>
                                                                <label>Charges for a month</label>
                                                                <Field name="charges_for_month">
                                                                    {({ field }) => (
                                                                        <div className="py-2">
                                                                            <Input
                                                                                {...field}
                                                                                type="number"
                                                                                value={formikBag.values.charges_for_month}
                                                                                // icon={LocationIcon}
                                                                                onChange={(e) => {
                                                                                    formikBag.setFieldValue("charges_for_month", e.target.value);
                                                                                }}
                                                                                error={
                                                                                    formikBag.touched.charges_for_month && formikBag.errors.charges_for_month
                                                                                        ? formikBag.errors.charges_for_month
                                                                                        : null
                                                                                }
                                                                                className="form-control"
                                                                                placeholder="Charges for a month"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </InputDivides>

                                                        <div className="text-center login_btn_group" style={{ justifyContent: "center" }}>
                                                            <LoginButton
                                                                type="submit"
                                                                className="buttonWidthResponsive"
                                                            >
                                                                Post Now
                                                                <HiIcons.HiOutlineArrowNarrowRight style={{ fontSize: "1.7rem" }} />
                                                            </LoginButton>
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </RetaurantDetailsForm>
                                </>
                            ) : ""}
                        </Paper>
                    </DashboardWrapper>
                </DashboardContainer>
            </div>


            {isLoading && <Overlay />}

        </>

    )
}



const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        locationData: state.locations,
        defaultState: state.defaultState,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        setUsers: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_USER,
                updatedUser: updatedValue,
            });
        },
        setDefaultState: (updatedValue) => {
            dispatch({
                type: actionTypes.UPDATE_DEFAULT,
                updateDefault: updatedValue,
            });
        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(OfferManagement);
